const { PI } = Math;

function vector(x, y) {
  return { x, y };
}
function delta(a, b) {
  return vector(a.x - b.x, a.y - b.y);
}

function len(v) {
  return Math.sqrt(v.x * v.x + v.y * v.y);
}

function angleBetween(a, b) {
  return Math.acos((a.x * b.x + a.y * b.y) / (len(a) * len(b)));
}

function unit(c) {
  const l = len(c);
  return vector(c.x / l, c.y / l);
}

function scale(c, f) {
  return vector(c.x * f, c.y * f);
}

function add(a, b) {
  return vector(a.x + b.x, a.y + b.y);
}

function average(l) {
  let x = 0;
  let y = 0;
  for (let i = 0; i < l.length; i++) {
    x += l[i].x;
    y += l[i].y;
  }
  const len = l.length;
  return vector(x / len, y / len);
}

export function checkForShape(line) {
  let corners = [line[0]];
  let n = 0;
  let lastCorner = line[0];

  for (let i = 1; i < line.length - 2; i++) {
    let pt = line[i + 1];
    let d = delta(lastCorner, line[i - 1]);

    if (len(d) > 20 && n > 2) {
      const ac = delta(line[i - 1], pt);
      if (Math.abs(angleBetween(ac, d)) > PI / 4) {
        pt.index = i;
        corners.push(pt);
        lastCorner = pt;
        n = 0;
      }
    }
    n++;
  }

  if (len(delta(line[line.length - 1], line[0])) < 25) {
    corners.push(line[0]);

    if (corners.length === 5) {
      // check for square
      let p1 = corners[0];
      let p2 = corners[1];
      let p3 = corners[2];
      let p4 = corners[3];
      let p1p2 = delta(p1, p2);
      let p2p3 = delta(p2, p3);
      let p3p4 = delta(p3, p4);
      let p4p1 = delta(p4, p1);
      if (
        Math.abs(angleBetween(p1p2, p2p3) - PI / 2) < PI / 6 &&
        Math.abs(angleBetween(p2p3, p3p4) - PI / 2) < PI / 6 &&
        Math.abs(angleBetween(p3p4, p4p1) - PI / 2) < PI / 6 &&
        Math.abs(angleBetween(p4p1, p1p2) - PI / 2) < PI / 6
      ) {
        let p1p3 = delta(p1, p3);
        let p2p4 = delta(p2, p4);

        let diag = (len(p1p3) + len(p2p4)) / 4.0;

        let tocenter1 = scale(unit(p1p3), -diag);
        let tocenter2 = scale(unit(p2p4), -diag);

        let center = average([p1, p2, p3, p4]);

        corners = [
          add(center, tocenter1),
          add(center, tocenter2),
          add(center, scale(tocenter1, -1)),
          add(center, scale(tocenter2, -1)),
          add(center, tocenter1),
        ];
      }
    }
  } else {
    corners.push(line[line.length - 1]);
  }

  return corners;
}

export function createCircle(xPos, yPos, radius, numOfPoints) {
  let i;
  let result = [];
  let x, y;
  let angleIncrement = (2 * PI) / numOfPoints;
  for (i = 0; i < numOfPoints; i++) {
    x = xPos + Math.cos(angleIncrement * i) * radius;
    y = yPos - Math.sin(angleIncrement * i) * radius;
    result.push({ x, y });
  }
  result.push(result[0]);
  return result;
}

export function checkForCircle(line) {
  let sumAngleDeviation = 0;
  let lastPoint = line[0];
  let lastAngle;

  if (len(delta(line[line.length - 1], line[0])) > 25) {
    return null;
  }

  const maxAngleDeviation = (PI * 2) / line.length;

  for (let i = 1; i < line.length - 2; i++) {
    let pt = line[i + 1];
    let d = delta(lastPoint, line[i - 1]);
    const ac = delta(line[i - 1], pt);
    let angle = Math.abs(angleBetween(ac, d));
    if (lastAngle === null) lastAngle = angle;
    let angleDeviation = Math.abs(lastAngle - angle);
    if (isNaN(angleDeviation)) angleDeviation = 0;
    sumAngleDeviation += Math.abs(maxAngleDeviation - angleDeviation);
    lastPoint = pt;
    lastAngle = angle;
  }
  
  // circle detected
  if (sumAngleDeviation < 4.2) {
    let x = 0;
    let y = 0;
    let rad = 0;
    let dx, dy, i;

    // find centre of mass
    for (i = 0; i < line.length; i++) {
      x += line[i].x;
      y += line[i].y;
    }
    x = x / line.length;
    y = y / line.length;

    // find radius
    for (i = 0; i < line.length; i++) {
      dx = line[i].x - x;
      dy = line[i].y - y;
      rad += Math.sqrt(dx*dx + dy*dy);
    }
    rad = rad / line.length;

    // create circle
    return createCircle(x, y, rad, 48);
  }

  return null;
}