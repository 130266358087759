import { isNil, cloneDeep } from 'lodash';
import moment from 'moment';

const TIMESTAMP_REGEX = /\d{1,4}-\d{1,2}-\d{1,2}T\d{1,2}:\d{1,2}:\d{1,2}.\d+Z/;
const TIMESTAMP_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSSZ';

export function deserialize(data, clone = true) {
  if (isNil(data)) return data;
  const dataType = typeof data;

  if (dataType === 'object') {
    const clonedData = clone ? cloneDeep(data) : data;
    if (Array.isArray(data)) {
      return clonedData.map(i => deserialize(i, false));
    } else {
      Object.keys(clonedData).forEach(key => {
        clonedData[key] = deserialize(clonedData[key], false);
      });
      return clonedData;
    }
  } else if (TIMESTAMP_REGEX.test(data) && !isNaN(Date.parse(data))) {
    return moment(data, TIMESTAMP_FORMAT);
  }

  return data;
}

export function serialize(data, clone = true) {
  if (isNil(data)) return data;
  const dataType = typeof data;

  if (moment.isMoment(data)) {
    return data.toISOString();
  } else if (dataType === 'object') {
    const clonedData = clone ? cloneDeep(data) : data;
    if (Array.isArray(data)) {
      return clonedData.map(i => serialize(i, false));
    } else {
      Object.keys(clonedData).forEach(key => {
        clonedData[key] = serialize(clonedData[key], false);
      });
      return clonedData;
    }
  }

  return data;
}