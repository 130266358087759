import { isNil } from 'lodash';

export const STATUS_UNKNOWN = 'unknown';
export const STATUS_CREATED = 'created';
export const STATUS_SENT = 'sent';
export const STATUS_RECEIVED = 'received';
export const STATUS_SEEN = 'seen';
export const STATUS_REPLY_RECEIVED = 'replyReceived';
export const STATUS_REPLY_SENT = 'replySent';
export const STATUS_REVIEWED = 'reviewed';

export function getStatus(video, currentUserId) {
  let status = STATUS_UNKNOWN;
  if (video.senderId === currentUserId) {
    if (!isNil(video.createdAt)) status = STATUS_CREATED;
    if (!isNil(video.sentAt)) status = STATUS_SENT;
    if (!isNil(video.seenAt)) status = STATUS_SEEN;
    if (!isNil(video.repliedAt)) status = STATUS_REPLY_RECEIVED;
  } else {
    if (!isNil(video.createdAt)) status = STATUS_CREATED;
    if (!isNil(video.sentAt)) status = STATUS_RECEIVED;
    if (!isNil(video.seenAt)) status = STATUS_SEEN;
    if (!isNil(video.repliedAt)) status = STATUS_REPLY_SENT;
    if (!isNil(video.reviewedAt)) status = STATUS_REVIEWED;
  }
  return status;
}

export function getIsAttentionNeeded(video, currentUserId) {
  let attentionNeeded = false;
  if (video.senderId === currentUserId) {
    if (!isNil(video.repliedAt) && isNil(video.reviewedAt)) {
      attentionNeeded = true;
    }
  } else {
    if (isNil(video.seenAt)) {
      attentionNeeded = true;
    }
  }
  return attentionNeeded;
}

export function getVideoUpdatedAt(video) {
  let date = video.createdAt;
  if (!isNil(video.sentAt)) date = video.sentAt;
  if (!isNil(video.repliedAt)) date = video.repliedAt;
  if (!isNil(video.reviewedAt)) date = video.reviewedAt;
  return date;
};

export function sortVideosByDate(a, b) {
  let aUpdatedAt = getVideoUpdatedAt(a);
  let bUpdatedAt = getVideoUpdatedAt(b);
  return aUpdatedAt.isBefore(bUpdatedAt) ? -1 : 1;
};

export function sortVideosByDateDesc(a, b) {
  let aUpdatedAt = getVideoUpdatedAt(a);
  let bUpdatedAt = getVideoUpdatedAt(b);
  return aUpdatedAt.isAfter(bUpdatedAt) ? -1 : 1;
};

export function sortVideosByAttention(a, b) {
  let aAttention = getIsAttentionNeeded(a);
  let bAttention = getIsAttentionNeeded(b);
  if (aAttention && !bAttention) {
    return -1;
  } else if (bAttention && !aAttention) {
    return 1;
  } else {
    return sortVideosByDateDesc(a, b);
  }
};