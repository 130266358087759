import React from 'react';
import cx from 'classnames';
import firebase from 'firebase';
import moment from 'moment';
import { isNil, isEmpty } from 'lodash';
import { withTranslation, Trans } from 'react-i18next';

import './style.scss';

import { ReactComponent as Logo } from '../../images/logo-full.svg';

import { withApi } from '../../services/Api';
import { trackPage } from '../../services/Analytics';
import { cropAndResizeImage } from '../../utils/media';
import AvatarIcon from '../AvatarIcon';

const STEPS = [
  { id: 'intro', path: '/onboarding', title: 'Onboarding' },
  { id: 'firstName', path: '/onboarding/firstName', title: 'Onboarding - First Name' },
  { id: 'lastName', path: '/onboarding/lastName', title: 'Onboarding - Last Name' },
  // { id: 'description', path: '/onboarding/description', title: 'Onboarding - Description' },
  { id: 'photoUrl', path: '/onboarding/photo', title: 'Onboarding - Profile Photo' },
  // { id: 'notification', path: '/onboarding/notification', title: 'Onboarding - Notification' },
  { id: 'outro', path: '/onboarding/complete', title: 'Onboarding - Complete' },
];

const MAX_STEPS = STEPS.length;

function getStepIndexById(stepId) {
  return STEPS.findIndex(step => step.id === stepId);
}

class OnboardingScreen extends React.Component {
  state = {
    step: 0,
    uploadProgress: 0,
    videoFile: null,
    data: {
      firstName: '',
      lastName: '',
      description: '',
      photoUrl: null,
    },
  };

  componentDidUpdate(prevProps) {
    const { visible: prevVisible } = prevProps;
    const { visible } = this.props;
    if (visible !== prevVisible) {
      if (!visible) {
        setTimeout(() => {
          this.setState({
            sectionIndex: 0,
            uploadProgress: 0,
            videoFile: null,
            data: {
              firstName: '',
              lastName: '',
              description: '',
              photoUrl: null,
            },
          });
        }, 1000);
      }
    }

    const prevProfile = JSON.stringify(prevProps.profile || {});
    const profile = JSON.stringify(this.props.profile || {});
    if (prevProfile !== profile && visible) {
      const { step } = this.state;
      const { firstName } = this.props.profile || {};
      if (step === 0 && !!firstName) {
        this.handleClose();
      }
    }
  }

  saveProfile() {
    const { api, profile, currentUserId } = this.props;
    const { data } = this.state;

    let promise;

    if (profile.isNew) {
      promise = api.user.create({
        ...profile,
        ...data,
      }, currentUserId);
    } else {
      promise = api.user.update({
        ...profile,
        ...data,
        updatedAt: moment(),
      });
    }

    return promise;
  }

  handleClose = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  };

  handleNextClick = (step) => {
    const nextStep = Math.min(step + 1, MAX_STEPS);
    trackPage(STEPS[nextStep].path, STEPS[nextStep].title);
    this.setState({ step: nextStep });
  };

  handleAskPermission = (step) => {
    const messaging = firebase.messaging();
    messaging
      .requestPermission()
      .then(() => {
        return messaging.getToken();
      })
      .then((token) => {
        window.localStorage.setItem('messagingToken', token);
      })
      .catch((error) => {
        console.log('[ERROR] Unable to get permission to notify', error);
      })
      .finally(() => {
        this.handleNextClick(step);
      });
  };

  handleInputChange = event => {
    const { name, value } = event.target;
    this.setState(state => {
      const { data } = state;
      data[name] = value;
      return { data };
    });
  };

  handleInputKeyDown = event => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      event.preventDefault();
      event.target.blur();
    }
  };

  handleUploadClick = () => {
    this.setState({ isUploadVisible: true });
  };

  handleFirstNameInputBlur = event => {
    this.setState({ step: !!event.target.value ? 2 : 1 }, () => this.saveProfile());
  };

  handleLastNameInputBlur = event => {
    this.setState({ step: !!event.target.value ? 3 : 2 }, () => this.saveProfile());
  };

  handleDescriptionInputBlur = event => {
    this.setState({ step: !!event.target.value ? 4 : 3 }, () => this.saveProfile());
  };

  handleUploadInputChange = event => {
    const { api, currentUserId } = this.props;
    const { files } = event.target;

    if (isEmpty(files)) {
      return;
    }

    cropAndResizeImage(files[0]).then(photoFile => {
      const photoUrl = URL.createObjectURL(photoFile);

      this.handleInputChange({
        target: {
          name: 'photoUrl',
          value: photoUrl,
        },
      });
      
      this.setState({ photoFile, photoUrl }, () => {
        api.user
          .uploadPhoto(
            photoFile,
            currentUserId,
            this.handleUploadProgress,
          )
          .then(uploadedPhotoUrl => {
            this.setState({ uploadProgress: 0.99 });
            this.handleInputChange({
              target: {
                name: 'photoUrl',
                value: uploadedPhotoUrl,
              },
            });
            return this.saveProfile();
          })
          .catch(error => {
            console.error('Error uploading photo!', error);
          })
          .finally(() => {
            this.setState({
              isEditing: false,
              uploadProgress: 1,
              videoFile: null,
              step: 5,
            });
          });
      });
    });
  };

  handleUploadProgress = ({ progress }) => {
    this.setState(state => {
      let { uploadProgress } = state;
      uploadProgress = Math.max(uploadProgress, progress);
      uploadProgress = Math.min(uploadProgress, 0.99);
      return { uploadProgress };
    });
  };

  render() {
    const { /*t, */className, visible } = this.props;
    const { step, data, photoUrl, uploadProgress, photoFile } = this.state;
    // const { firstName, lastName } = data;
    const steps = [];
    for (let i = 0; i < MAX_STEPS; i++) {
      steps.push(i);
    }

    // const sampleDescription = t('I work with students of all ages and levels and love incorporating fun games and activities into lessons.');

    const footerImage = (
      <footer>
        <nav>
          <ol>
            <li>
              <i className="fal fa-inbox-in" />
              <span className="text"><Trans>Inbox</Trans></span>
            </li>
            <li>
              <i className="fal fa-address-book" />
              <span className="text"><Trans>Contacts</Trans></span>
            </li>
            <li>
              <i className="fal fa-video-plus color-default" />
              <span className="text color-default"><Trans>Send</Trans></span>
            </li>
            <li>
              <i className="fal fa-user-circle" />
              <span className="text"><Trans>Profile</Trans></span>
            </li>
            <li>
              <i className="fal fa-cog" />
              <span className="text"><Trans>Settings</Trans></span>
            </li>
          </ol>
        </nav>
        <legend>
          <ol>
            <li><Trans>Find videos you've sent and received</Trans></li>
            <li><Trans>Find your friends and friend requests</Trans></li>
            <li><Trans>Send a video to a friend</Trans></li>
            <li><Trans>Find your friend code and edit your profile</Trans></li>
            <li><Trans>Change theme and language</Trans></li>
          </ol>
        </legend>
      </footer>
    );

    return (
      <div className={cx('OnboardingScreen', className, { visible })}>
        <button type="button" className="btn btn-icon-only closeButton" onClick={this.handleClose}>
          <i className="fal fa-times icon-big" />
        </button>
        <div className="navigation" data-active-section={step}>
          {steps.map(index => (
            <div key={index} className={cx({ active: step === index })} />
          ))}
        </div>
        <div className="sections">
          <section data-step="intro" className={cx({ in: visible && step === 0, out: step > 0 })}>
            <div className="logo"><Logo /></div>
            <div className="welcomeMessage">
              <h1><Trans>Welcome!</Trans></h1>
              <p><Trans>You're only a few steps away from sending your first video to someone else.</Trans></p>
              <button className="btn btn-primary btn-rounded" onClick={() => this.handleNextClick(step)}>
                <Trans>Let's get started!</Trans>
              </button>
            </div>
          </section>
          <section data-step="firstName" className={cx({ in: step >= 1 && step <= 3, out: step > 3, [`step-${step}`]: true })}>
            <label htmlFor="firstName"><Trans>First, what's your first name?</Trans></label>
            <input
              type="text" 
              name="firstName"
              className="form-control"
              value={data.firstName}
              onKeyDown={this.handleInputKeyDown}
              onChange={this.handleInputChange}
              onBlur={this.handleFirstNameInputBlur}
              />
          </section>
          <section data-step="lastName" className={cx({ in: step >= 2 && step <= 3, out: step > 3, [`step-${step}`]: true })}>
            <label htmlFor="lastName"><Trans>Awesome! Any last name?</Trans></label>
            <input
              type="text"
              name="lastName"
              className="form-control"
              value={data.lastName}
              onKeyDown={this.handleInputKeyDown}
              onChange={this.handleInputChange}
              onBlur={this.handleLastNameInputBlur}
              />
          </section>
          {/* <section data-step="description" className={cx({ in: step === getStepIndexById('description'), out: step > getStepIndexById('description'), [`step-${step}`]: true })}>
            <p>
              <Trans firstName={firstName} lastName={lastName}>
                Great to meet you,
                <br />
                <span className="name">{{firstName}} {{lastName}}</span>!
              </Trans>
            </p>
            <label htmlFor="description">
              <Trans>Please tell us a bit about you</Trans>
            </label>
            <textarea placeholder={sampleDescription} data-gramm_editor="false" name="description" rows="5" className="form-control" onChange={this.handleInputChange} onBlur={this.handleDescriptionInputBlur} value={data.description} />
          </section> */}
          <section data-step="photoUrl" className={cx({ in: step === getStepIndexById('photoUrl'), out: step > getStepIndexById('photoUrl') })}>
            <label htmlFor="photoUrl"><Trans>What about uploading a photo for your profile?</Trans></label>
            <AvatarIcon name="photoUrl" {...{ ...data, photoUrl }} onClick={this.handleUploadClick} />
            <button className="btn btn-primary btn-rounded" onClick={this.handleUploadClick}>
              <div 
                className={cx('progressBar', { visible: uploadProgress > 0 && uploadProgress < 1 })} 
                style={{ width: `${(1 - uploadProgress) * 100}%` }}
              />
              {uploadProgress <= 0 ? <Trans>Let's do it!</Trans> : <Trans>Uploading...</Trans>}
            </button>
            <button
              className={cx('btn btn-default btn-rounded', { hidden: !isNil(photoFile) })}
              onClick={() => this.handleNextClick(step)}
            >
              <Trans>Maybe later</Trans>
            </button>
            <div className="uploadInput">
              <input
                disabled={uploadProgress > 0}
                type="file"
                name="photoUrl"
                accept="image/*"
                onChange={this.handleUploadInputChange}
              />
            </div>
          </section>
          {/* <section data-step="notification" className={cx({ in: step === getStepIndexById('outro'), out: step > getStepIndexById('outro') })}>
            <label htmlFor="notification"><Trans>Lastly, do you want us to notify you when your friend sent you a video?</Trans></label>
            <button className="btn btn-primary btn-rounded" onClick={() => this.handleAskPermission(step)}><Trans>Yes please!</Trans></button>
            <button className="btn btn-default btn-rounded" onClick={() => this.handleNextClick(step)}><Trans>I'll decide later</Trans></button>
          </section> */}
          <section data-step="outro" className={cx({ in: step >= getStepIndexById('outro') })}>
            <label htmlFor="outro"><Trans>That's it! Have fun sending and replying videos!</Trans></label>
            <p><Trans>Just a few tips about where things are.</Trans></p>
            {footerImage}
            <button className="btn btn-primary btn-rounded" onClick={this.handleClose}><Trans>Got it!</Trans></button>
          </section>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withApi(OnboardingScreen));