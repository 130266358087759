import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import Rating from 'react-rating';
import { isNil, isEmpty, cloneDeep, isEqual } from 'lodash';
import { withTranslation, Trans } from 'react-i18next';

import './style.scss';

import { withApi } from '../../services/Api';
import { withGlobalState } from '../../services/GlobalState';
import { goToRoute } from '../../utils/router';
import {
  formatDate,
  formatFilesize,
  formatDuration,
  formatTime,
  formatDecimal,
  joinNames,
} from '../../utils';
import {
  TYPE_DRAWING,
  TYPE_IMAGE,
  TYPE_TEXT,
  TYPE_VIDEO,
  getAnnotationType,
} from '../../utils/annotation';

import ProgressBar from '../ProgressBar';
import LoadingIndicator from '../LoadingIndicator';
import VideoPlayer from '../VideoPlayer';
import VideoEditor from '../VideoEditor';
import SimpleVideoEditor from '../SimpleVideoEditor';
import AvatarIcon from '../AvatarIcon';
import UploadProgress from '../UploadProgress';
import AnnotationViewerScreen from '../AnnotationViewerScreen';
import VideoThumbnail from '../VideoThumbnail';
import AnimatedPlayButton from '../AnimatedPlayButton';

const sortByTime = (a, b) => a.time - b.time;

class VideoScreen extends React.Component {
  static defaultProps = {
    annotationAutoPlayGapFactor: 0.002,
    useSimpleEditor: true,
    videoSkipDuration: 2,
    reviewStartOffsetDuration: 3000,
    reviewEndOffsetDuration: 5000,
    reviewDuration: 5000,
  };

  state = {
    isEditing: false,
    isLoading: false,
    isReviewing: true,
    reviewResumeTimeout: null,
    originalData: null,
    data: null,
    annotationToView: null,
  };

  playedAnnotationHelper = {};
  videoPlayerContainerWidth = 0;
  videoPlayerContainerHeight = 0;

  componentDidMount() {
    const isEditing = !!new URLSearchParams(window.location.search).get('edit');
    this.setState({ isEditing });
    this.fetchData();

    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props;
    if (id !== prevProps.id) {
      this.player = null;

      this.fetchData();

      if (isNil(id)) {
        if (this.player) {
          try {
            this.player.pause();
          } catch (e) {}
        }
        this.setState({
          uploadProgress: 0,
          annotationToView: null,
        });
      } else {
        this.isDestroyed = false;
      }
    }
  }

  componentWillUnmount() {
    this.destroy();
    window.removeEventListener('resize', this.handleWindowResize);

    this.player = null;

    if (this.unsubscribeData) {
      this.unsubscribeData();
    }
  }

  destroy() {
    if (this.destroyEditor) {
      this.destroyEditor();
    }
    this.isDestroyed = true;
    if (this.player) {
      this.player.pause();
    }
  }

  handleWindowResize = () => {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });

    if (!isNil(this.$videoPlayerContainer)) {
      this.videoPlayerContainerRect =
        this.$videoPlayerContainer.getBoundingClientRect();
      this.videoPlayerContainerWidth = this.videoPlayerContainerRect.width;
      this.videoPlayerContainerHeight = this.videoPlayerContainerRect.height;
    }
  };

  fetchData() {
    const { id, api, globalState } = this.props;
    if (!isNil(id)) {
      if (this.unsubscribeData) {
        this.unsubscribeData();
      }

      this.setState({ isLoading: true });
      this.unsubscribeData = api.video.get(
        id,
        (data) => {
          if (isNil(data) || data.isSample) {
            this.setState({
              isLoading: false,
              originalData: cloneDeep(data),
              data,
            });
          } else {
            let isEditing = !!new URLSearchParams(window.location.search).get(
              'edit'
            );
            const isCurrentUserSender = this.getIsCurrentUserSender(data);
            if (
              !isNil(this.props.currentUser) &&
              !isCurrentUserSender &&
              !isEditing
            ) {
              isEditing = true;
              this.handleEdit();
            }

            const userIds = [];
            if (data.senderId) userIds.push(data.senderId);
            if (data.recipientId) userIds.push(data.recipientId);

            api.user.getByIds(userIds, (users) => {
              users.forEach((user) => {
                globalState.setUser(user);
              });

              if (!isNil(data.senderId)) {
                data.sender = globalState.getUserById(data.senderId);
              }
              if (!isNil(data.recipientId)) {
                data.recipient = globalState.getUserById(data.recipientId);
              }

              this.setState({
                isEditing,
                isLoading: false,
                originalData: cloneDeep(data),
                data,
              });
            });

            const currentUser = this.props.currentUser || {};
            if (
              isNil(data.seenAt) &&
              (data.recipientId === currentUser.id ||
                (!isNil(currentUser.companyId) &&
                  data.recipientId === currentUser.companyId))
            ) {
              data.seenAt = moment();
              api.video.update(data);
            }
            if (
              !isNil(data.repliedAt) &&
              isNil(data.reviewedAt) &&
              data.senderId === currentUser.id
            ) {
              data.reviewedAt = moment();
              api.video.update(data);
            }
          }
        },
        { subscribe: true }
      );
    }
  }

  getAnnotationAt(time, gapFactor = this.props.annotationAutoPlayGapFactor) {
    const annotations = (this.state.data || {}).annotations || [];
    const duration = this.player.duration();
    const timeGap = gapFactor * duration;
    const minTime = time - timeGap;
    const maxTime = time + timeGap;
    return annotations.find((a) => a.time >= minTime && a.time <= maxTime);
  }

  updatePlayedAnnotationHelper = (currentTime) => {
    const annotations = ((this.state.data || {}).annotations || []).sort(
      sortByTime
    );
    annotations.forEach((annotation) => {
      this.playedAnnotationHelper[annotation.time] =
        annotation.time <= currentTime;
    });
  };

  getPreviousAnnotation() {
    let { currentTime } = this;
    if (!currentTime && this.player) {
      currentTime = this.player.currentTime();
    }
    const { annotations } = this.state.data;
    const len = annotations.length;
    if (!len) return null;

    let i = -1;
    let annotation;
    let foundAnnotation;
    while (++i < len) {
      annotation = annotations[i];
      if (annotation.time >= currentTime) {
        break;
      }
      foundAnnotation = annotation;
    }
    return foundAnnotation;
  }

  getNextAnnotation() {
    let { currentTime } = this;
    if (!currentTime && this.player) {
      currentTime = this.player.currentTime();
    }
    const { annotations } = this.state.data;
    const len = annotations.length;
    if (!len) return null;

    let i = len;
    let annotation;
    let foundAnnotation;
    while (--i >= 0) {
      annotation = annotations[i];
      if (annotation.time <= currentTime) {
        break;
      }
      foundAnnotation = annotation;
    }
    return foundAnnotation;
  }

  getLastUnplayedAnnotationHelper = (currentTime) => {
    const annotations = ((this.state.data || {}).annotations || []).sort(
      sortByTime
    );
    let annotation = null;
    let unplayedAnnotation = null;
    let i = -1;
    let len = annotations.length;
    let time = 0;

    while (++i < len) {
      annotation = annotations[i];
      time = annotation.time;
      if (time > currentTime) {
        break;
      }
      if (!this.playedAnnotationHelper[time]) {
        unplayedAnnotation = annotation;
      }
    }

    return unplayedAnnotation;
  };

  updateScrubber = (stopOnPause) => {
    if (this.isDestroyed) {
      return;
    }

    let currentTime = 0;
    if (this.player) {
      currentTime = this.player.currentTime();
    }

    if (stopOnPause && this.player.paused()) {
      this.setState({ isPlaying: false });
      return;
    }

    window.requestAnimationFrame(this.updateScrubber);

    const { annotationToView } = this.state;
    const { lastPlayedAnnotation } = this;
    const annotation = this.getLastUnplayedAnnotationHelper(currentTime);
    if (
      annotation &&
      (!annotationToView || annotation.time !== annotationToView.time) &&
      (!lastPlayedAnnotation || annotation.time !== lastPlayedAnnotation.time)
    ) {
      this.handleAnnotationView(annotation);
      this.lastPlayedAnnotation = annotation;
    }

    this.updatePlayedAnnotationHelper(currentTime);
  };

  checkReview = () => {
    const { isLandscape, reviewDuration } = this.props;
    const { isReviewing } = this.state;
    if (isReviewing && isLandscape) {
      const now = Date.now();
      this.setState(
        {
          reviewStartTime: now,
          reviewEndTime: now + reviewDuration,
        },
        this.updateReviewTime
      );
    }
  };

  updateReviewTime = () => {
    if (this.isDestroyed) {
      return;
    }

    const { reviewEndOffsetDuration } = this.props;
    const { reviewEndTime, isPlayerPlaying } = this.state;
    if (isPlayerPlaying) {
      return;
    }

    const now = Date.now();
    if (now > reviewEndTime) {
      if (!isPlayerPlaying) {
        this.handleVideoPlayerPlay();
      }

      const reviewResumeTimeout = setTimeout(() => {
        this.handleVideoPlayerPlay(true);
      }, reviewEndOffsetDuration);
      this.setState({ reviewResumeTimeout });

      return;
    }

    window.requestAnimationFrame(this.updateReviewTime);

    this.setState({
      now,
    });
  };

  getIsCurrentUserSender = (fetchedData) => {
    const currentUser = this.props.currentUser || {};
    const data = fetchedData || this.state.data || {};
    const { senderId, isSample } = data;
    return !isSample && !isNil(currentUser.id) && currentUser.id === senderId;
  };

  handleVideoPlayerReady = (player) => {
    this.player = player;
    this.setState({ player });
  };

  handleVideoPlayerPlayed = () => {
    this.setState({ isPlayerPlaying: true }, this.handleAnnotationViewClose);
    this.updateScrubber();
  };

  handleVideoPlayerPaused = () => {
    this.setState({ isPlayerPlaying: false });
    this.updateScrubber();
  };

  handleVideoPlayerSeeked = () => {
    this.lastPlayedAnnotation = null;
  };

  handleHidePlayingControls = () => {
    try {
      this.setState({ isPlayingControlsHidden: false });
      if (this.hidePlayingControlsTimeout) {
        clearTimeout(this.hidePlayingControlsTimeout);
      }
      this.hidePlayingControlsTimeout = setTimeout(() => {
        this.setState({ isPlayingControlsHidden: true });
      }, 2000);
    } catch (error) {}
  };

  handleVideoPlayerPlay = (resumeReview) => {
    const { reviewStartOffsetDuration, isLandscape } = this.props;
    const { isReviewing } = this.state;

    let nextAnnotation;
    if (resumeReview && isReviewing && isLandscape) {
      nextAnnotation = this.getNextAnnotation();
    }

    if (nextAnnotation) {
      let { currentTime } = this;
      if (!currentTime && this.player) {
        currentTime = this.player.currentTime();
      }
      const time = Math.max(
        currentTime,
        nextAnnotation.time - reviewStartOffsetDuration / 1000
      );
      this.updatePlayedAnnotationHelper(time);
      if (this.player) {
        this.player.currentTime(time);
        this.player.play();
      }
    } else {
      if (this.player && this.player.paused()) {
        this.updatePlayedAnnotationHelper(this.player.currentTime);
        this.player.play();
      }
      this.handleHidePlayingControls();
    }
  };

  handleVideoPlayerPause = () => {
    if (this.player && !this.player.paused()) {
      this.player.pause();
    }
    this.handleHidePlayingControls();

    const { reviewResumeTimeout } = this.state;
    if (reviewResumeTimeout) {
      clearTimeout(reviewResumeTimeout);
    }
    this.setState({
      reviewStartTime: null,
      reviewEndTime: null,
      reviewResumeTimeout: null,
    });
  };

  handleTimelineStepBackward = () => {
    const { annotations } = this.state.data;
    if (annotations.length > 0) {
      this.handleAnnotationView(this.getPreviousAnnotation(), true);
    }
  };

  handleTimelineStepForward = () => {
    const { annotations } = this.state.data;
    if (annotations.length > 0) {
      this.handleAnnotationView(this.getNextAnnotation(), true);
    }
  };

  handleVideoPlayerReviewToggle = () => {
    const { isReviewing } = this.state;
    this.setState({
      isReviewing: !isReviewing,
    });
  };

  handleVideoPlayerSkipBackward = () => {
    const { videoSkipDuration } = this.props;
    let { currentTime } = this;
    if (!currentTime && this.player) {
      currentTime = this.player.currentTime();
    }
    const time = Math.max(0, currentTime - videoSkipDuration);
    this.updatePlayedAnnotationHelper(time);
    if (this.player) {
      this.player.currentTime(time);
    }
    this.handleHidePlayingControls();
  };

  handleVideoPlayerSkipForward = () => {
    const { videoSkipDuration } = this.props;
    let { currentTime } = this;
    if (!currentTime && this.player) {
      currentTime = this.player.currentTime();
    }
    const time = Math.min(
      this.state.data.duration,
      currentTime + videoSkipDuration
    );
    this.updatePlayedAnnotationHelper(time);
    if (this.player) {
      this.player.currentTime(time);
    }
    this.handleHidePlayingControls();
  };

  handleVideoEditorInit = ({ destroy }) => {
    this.destroyEditor = destroy;
  };

  handleVideoEditorChange = ({ annotations }) => {
    this.setState((state) => {
      const { data } = state;
      data.annotations = annotations;
      return { data };
    });
  };

  handleAnnotationTextInputVisibilityChange = (
    isAnnotationTextInputVisible
  ) => {
    this.setState({ isAnnotationTextInputVisible });
  };

  handleInfoClick = () => {
    this.setState({ isShowingInfo: true });
  };

  handleInfoClose = () => {
    this.setState({ isShowingInfo: false });
  };

  handleEdit = () => {
    goToRoute(`/video/${this.props.id}?edit=true`, 'Video Editor');
    this.setState({
      isEditing: true,
    });
  };

  handleSave = () => {
    this.setState({ isSaving: true });

    const { t, api, id, onReplied } = this.props;
    const { showAlert } = this.props.globalState;
    const currentUser = this.props.currentUser || {};
    const { data } = this.state;

    const uploadProgresses = {};

    const sumUploadProgress = () => {
      const len = Object.keys(uploadProgresses).length;
      return (
        Object.keys(uploadProgresses).reduce((sum, key) => {
          let progress = uploadProgresses[key];
          if (isNaN(progress)) progress = 0;
          return sum + progress;
        }, 0) / len
      );
    };

    this.setState({ uploadProgress: 0.01 }, () => {
      const uploadTasks = [];
      data.annotations
        .filter((a) => !isNil(a.file))
        .forEach((a) => {
          let annotationUploadTasks = [];
          annotationUploadTasks.push(
            api.video.upload(
              a.file,
              a.id,
              ({ progress }) => {
                uploadProgresses[a.id] = progress;
                let uploadProgress = sumUploadProgress();
                if (uploadProgress < 0.01) uploadProgress = 0.01;
                else if (uploadProgress > 0.99) uploadProgress = 0.99;
                this.setState({ uploadProgress });
              },
              `videos/${id}/`,
              {
                senderId: currentUser.id || data.recipientId,
                recipientId: data.senderId,
              }
            )
          );

          if (!isNil(a.audio) && !isNil(a.audio.file)) {
            annotationUploadTasks.push(
              api.video.upload(
                a.audio.file,
                `${a.id}_audio`,
                ({ progress }) => {
                  uploadProgresses[a.id] = progress;
                  let uploadProgress = sumUploadProgress();
                  if (uploadProgress < 0.01) uploadProgress = 0.01;
                  else if (uploadProgress > 0.99) uploadProgress = 0.99;
                  this.setState({ uploadProgress });
                },
                `videos/${id}/`,
                {
                  senderId: currentUser.id || data.recipientId,
                  recipientId: data.senderId,
                }
              )
            );
          }

          uploadTasks.push({
            annotation: a,
            promise: Promise.all(annotationUploadTasks),
          });
        });

      Promise.all(uploadTasks.map((t) => t.promise))
        .then((uploadResults) => {
          const annotations = data.annotations || [];
          uploadResults.forEach((srcs, index) => {
            const annotation = annotations.find(
              (a) => a.id === uploadTasks[index].annotation.id
            );
            if (annotation) {
              annotation.src = srcs[0];
              delete annotation.file;
              if (annotation.audio) {
                annotation.audio.src = srcs[1];
                delete annotation.audio.file;
              }
            }
          });
          return api.video.update(data);
        })
        .then(() => {
          this.setState({ uploadProgress: 1 }, () => {
            showAlert({
              type: 'info',
              title: t('Feedback Saved!'),
              message: t(
                'Are you done with your feedback and ready to send your feedback?'
              ),
              cancel: t('No, keep editing'),
              ok: t('Yes, send it off'),
              onOk: () => {
                data.repliedAt = moment();
                api.video.update(data).then(() => {
                  if (onReplied) {
                    onReplied(data);
                  }
                });
              },
            });
            setTimeout(() => {
              try {
                this.setState({ uploadProgress: 0 });
              } catch (e) {}
            }, 500);
          });
        })
        .catch((error) => {
          console.error('Error replying video!', error);
        });
    });
  };

  handleCancelClick = () => {
    const { t } = this.props;
    const { showAlert } = this.props.globalState;
    const { data, originalData } = this.state;
    const annotations = data.annotations || [];
    const originalAnnotations = originalData.annotations || [];

    if (isEmpty(annotations) || isEqual(originalAnnotations, annotations)) {
      this.handleClose();
    } else {
      showAlert({
        type: 'warning',
        title: t('Discard Feedback'),
        message: t(
          'Are you sure you want to go back and discard your feedback?'
        ),
        cancel: t('No'),
        ok: t('Yes'),
        onOk: this.handleClose,
      });
    }
  };

  handleCancel = () => {
    goToRoute(`/video/${this.props.id}`, 'Video');
    this.setState((state) => ({
      isEditing: false,
      data: cloneDeep(state.originalData),
    }));
  };

  handleShowFeedbackRating = (_, close) => {
    const data = this.state.data || {};
    const annotations = data.annotations || [];
    const isCurrentUserSender = this.getIsCurrentUserSender();
    if (isCurrentUserSender && !isEmpty(annotations)) {
      const { t } = this.props;
      const { showAlert } = this.props.globalState;
      const recipient = data.recipient || {};
      const recipientFirstName = recipient.firstName;
      const message = (
        <React.Fragment>
          <p>
            <Trans recipientFirstName={recipientFirstName}>
              How happy are you with the feedback that {{ recipientFirstName }}{' '}
              gave you?
            </Trans>
          </p>
          <Rating
            className="feedbackRating"
            initialRating={data.feedbackRating}
            emptySymbol={[
              <span
                role="img"
                aria-label="1 star rating"
                className="rating-score rating-score-empty rating-1"
              >
                😕
              </span>,
              <span
                role="img"
                aria-label="2 star rating"
                className="rating-score rating-score-empty rating-2"
              >
                😐
              </span>,
              <span
                role="img"
                aria-label="3 star rating"
                className="rating-score rating-score-empty rating-3"
              >
                🙂
              </span>,
              <span
                role="img"
                aria-label="4 star rating"
                className="rating-score rating-score-empty rating-4"
              >
                😀
              </span>,
              <span
                role="img"
                aria-label="5 star rating"
                className="rating-score rating-score-empty rating-5"
              >
                😁
              </span>,
            ]}
            fullSymbol={[
              <span
                role="img"
                aria-label="1 star rating"
                className="rating-score rating-score-full rating-1"
              >
                😕
              </span>,
              <span
                role="img"
                aria-label="2 star rating"
                className="rating-score rating-score-full rating-2"
              >
                😐
              </span>,
              <span
                role="img"
                aria-label="3 star rating"
                className="rating-score rating-score-full rating-3"
              >
                🙂
              </span>,
              <span
                role="img"
                aria-label="4 star rating"
                className="rating-score rating-score-full rating-4"
              >
                😀
              </span>,
              <span
                role="img"
                aria-label="5 star rating"
                className="rating-score rating-score-full rating-5"
              >
                😁
              </span>,
            ]}
            onChange={(value) => {
              data.feedbackRating = value;
              this.setState({ data });
            }}
          />
        </React.Fragment>
      );
      showAlert({
        type: 'warning',
        title: t('Acknowledge Feedback'),
        message,
        cancel: t('Not done yet'),
        onCancel: () => {
          if (close) this.handleClose();
        },
        ok: t('Rate'),
        onOk: () => {
          this.props.api.video.update(data).then(() => {
            if (close) this.handleClose();
          });
        },
      });
    } else {
      if (close) this.handleClose();
    }
  };

  handleClose = () => {
    this.destroy();

    const { onClose } = this.props;
    const { data } = this.state;
    this.handleVideoPlayerPaused();

    if (onClose) {
      onClose(data);
    }
  };

  handleDeleteClick = () => {
    const { t } = this.props;
    const { showAlert } = this.props.globalState;
    showAlert({
      type: 'warning',
      title: t('Delete Video'),
      message: t('Are you sure you want to delete this video?'),
      cancel: t('No'),
      ok: t('Yes'),
      onOk: this.handleDelete,
    });
  };

  handleDelete = () => {
    const { id, api, onDelete } = this.props;
    const isCurrentUserSender = this.getIsCurrentUserSender();

    this.setState({ isDeleting: true });
    api.video.remove(id).then(() => {
      this.setState({ isDeleting: false }, () => {
        if (onDelete) {
          onDelete({ id, isCurrentUserSender });
        }
      });
    });
  };

  handleAnnotationView = (annotationToView, jumpToTime) => {
    const isPlayerPlayingPreviously = this.player && !this.player.paused();
    this.player && this.player.pause();
    if (jumpToTime) {
      this.player.currentTime(annotationToView.time);
    }
    const { reviewResumeTimeout } = this.state;
    if (reviewResumeTimeout) {
      clearTimeout(reviewResumeTimeout);
    }
    this.setState(
      {
        isPlayerPlayingPreviously,
        isPlayerPlaying: false,
        isShowingInfo: false,
        annotationToView,
        reviewResumeTimeout: null,
      },
      this.checkReview
    );
  };

  handleAnnotationViewClose = () => {
    this.setState({ annotationToView: null }, () => {
      if (
        this.player &&
        this.player.paused() &&
        this.state.isPlayerPlayingPreviously
      ) {
        setTimeout(() => {
          this.player.play();
        }, 750);
      }
    });
  };

  handleToggleFullscreen = (value) => {
    let naturalVideoWidth = 0;
    let naturalVideoHeight = 0;

    try {
      if (this.player) {
        naturalVideoWidth = this.player.videoWidth();
        naturalVideoHeight = this.player.videoHeight();
      }
    } catch (error) {}

    const { onLandscapeToggle } = this.props;
    if (onLandscapeToggle) {
      onLandscapeToggle(value && naturalVideoWidth > naturalVideoHeight);
    }
  };

  renderInfo({ dateInfo, avatar }) {
    const data = this.state.data || {};
    const { title, description, size, width, height, duration } = data;

    return (
      <div className="info">
        <div className="primary">
          <div>
            <AvatarIcon {...avatar} />
          </div>
          <div>
            <h2>{title}</h2>
            <div className="dateInfo color-dimmed">{dateInfo}</div>
            <dl className="color-dimmed">
              <dt className="duration">Length</dt>
              <dd className="duration">{formatDuration(duration)}</dd>
              <dt className="size">Size</dt>
              <dd className="size">{formatFilesize(size)}</dd>
              <dt className="dimension">Dimension</dt>
              <dd className="dimension">
                {formatDecimal(width)} x {formatDecimal(height)}
              </dd>
            </dl>
          </div>
        </div>
        <p className="description">
          {description || (
            <em className="empty">
              <Trans>No description yet.</Trans>
            </em>
          )}
        </p>
      </div>
    );
  }

  renderVideoInfo() {
    const { id, t } = this.props;
    const { isShowingInfo, annotationToView, isEditing } = this.state;
    const data = this.state.data || {};

    const { recipient = {}, sender = {} } = data;

    let { createdAt, sentAt, seenAt, repliedAt, isSample, originalId } = data;

    const videoId = isSample ? originalId : id;

    const annotations = data.annotations || [];
    const isCurrentUserSender = this.getIsCurrentUserSender();
    const avatar = isCurrentUserSender ? recipient : sender;
    const name = joinNames(avatar.firstName, avatar.lastName);

    if (!isCurrentUserSender && !isSample) {
      return null;
    }

    let dateInfo;

    if (!isSample) {
      if (isCurrentUserSender) {
        createdAt = t(formatDate(createdAt));
        dateInfo = <Trans createdAt={createdAt}>Created {{ createdAt }}</Trans>;
        if (!isNil(sentAt)) {
          sentAt = t(formatDate(sentAt));
          dateInfo = (
            <Trans sentAt={sentAt} name={name}>
              Sent {{ sentAt }} to {{ name }}
            </Trans>
          );
        }
        if (!isNil(repliedAt)) {
          repliedAt = t(formatDate(repliedAt));
          dateInfo = (
            <Trans repliedAt={repliedAt} name={name}>
              Replied {{ repliedAt }} by {{ name }}
            </Trans>
          );
        }
      } else {
        createdAt = t(formatDate(createdAt));
        dateInfo = (
          <Trans createdAt={createdAt} name={name}>
            Created {{ createdAt }} by {{ name }}
          </Trans>
        );
        if (!isNil(sentAt)) {
          sentAt = t(formatDate(sentAt));
          dateInfo = (
            <Trans sentAt={sentAt} name={name}>
              Received {{ sentAt }} from {{ name }}
            </Trans>
          );
        }
        if (!isNil(seenAt)) {
          seenAt = t(formatDate(seenAt));
          dateInfo = (
            <Trans seenAt={seenAt} name={name}>
              Seen {{ seenAt }} by {{ name }}
            </Trans>
          );
        }
        if (!isNil(repliedAt)) {
          repliedAt = t(formatDate(repliedAt));
          dateInfo = (
            <Trans repliedAt={repliedAt} name={name}>
              Replied {{ repliedAt }} to {{ name }}
            </Trans>
          );
        }
      }
    }

    let naturalVideoWidth = 0;
    let naturalVideoHeight = 0;
    let videoWidth = 0;
    let videoHeight = 0;

    try {
      if (this.player) {
        naturalVideoWidth = this.player.videoWidth();
        naturalVideoHeight = this.player.videoHeight();
      }
      if (!isNil(naturalVideoWidth) && !isNil(naturalVideoHeight)) {
        const widthScale = this.videoPlayerContainerWidth / naturalVideoWidth;
        const heightScale =
          this.videoPlayerContainerHeight / naturalVideoHeight;
        const scale = Math.min(widthScale, heightScale);
        videoWidth = naturalVideoWidth * scale;
        videoHeight = naturalVideoHeight * scale;
      }
      if (isNaN(videoWidth)) videoWidth = 0;
      if (isNaN(videoHeight)) videoHeight = 0;
    } catch (error) {}

    return (
      <div
        className={cx('VideoScreen infoContainer', { visible: isShowingInfo })}
      >
        <div className="blocker" onClick={this.handleInfoClose} />
        <div className="infoContent">
          {this.renderInfo({ dateInfo, avatar })}
          {!isEditing && !isEmpty(annotations) && (
            <div className="annotationInfos">
              <h2>
                <Trans>Feedback</Trans>
              </h2>
              <span className="badge">{annotations.length}</span>
              <ul>
                {annotations.map((annotation, index) => {
                  const {
                    src,
                    message,
                    messages,
                    time,
                    size,
                    width,
                    height,
                    duration,
                  } = annotation;
                  const type = getAnnotationType(annotation.type);
                  return (
                    <li
                      key={annotation.id}
                      className={cx({
                        selected:
                          !isNil(annotationToView) &&
                          annotationToView.time === time,
                      })}
                      onClick={() =>
                        this.handleAnnotationView(annotation, true)
                      }
                    >
                      {type === TYPE_VIDEO && (
                        <div className="annotationThumbnail">
                          <VideoThumbnail autoGenerate src={src} />
                          <div className="iconOverlay">
                            <AnimatedPlayButton />
                          </div>
                        </div>
                      )}
                      {type === TYPE_IMAGE && (
                        <div className="annotationThumbnail">
                          <div
                            className="imageAnnotation"
                            style={{ backgroundImage: `url(${src})` }}
                          />
                          {!isNil(annotation.audio) && (
                            <div className="iconOverlay">
                              <AnimatedPlayButton icon="fa-microphone-alt" />
                            </div>
                          )}
                        </div>
                      )}
                      {type === TYPE_TEXT && (
                        <div className="annotationThumbnail">
                          <i className="fas fa-comment-alt-dots" />
                        </div>
                      )}
                      {type === TYPE_DRAWING && (
                        <div className="annotationThumbnail">
                          <VideoThumbnail
                            path={`videos/${videoId}/${annotation.id}_bg.jpg`}
                          />
                          <div
                            className="imageAnnotation"
                            style={{ backgroundImage: `url(${src})` }}
                          />
                          {!isNil(annotation.audio) && (
                            <div className="iconOverlay">
                              <AnimatedPlayButton icon="fa-microphone-alt" />
                            </div>
                          )}
                        </div>
                      )}
                      <div className="annotationInfo">
                        <h2>
                          <span className="index">
                            <i className="fas fa-map-marker" />
                            <span className="text">{index + 1}</span>
                          </span>
                          <span className="time">{formatTime(time)}</span>
                        </h2>
                        {type === TYPE_VIDEO && (
                          <dl className="color-dimmed">
                            <dt className="duration">
                              <Trans>Length</Trans>
                            </dt>
                            <dd className="duration">
                              {formatDuration(duration)}
                            </dd>
                            <dt className="size">
                              <Trans>Size</Trans>
                            </dt>
                            <dd className="size">{formatFilesize(size)}</dd>
                            <dt className="dimension">
                              <Trans>Dimension</Trans>
                            </dt>
                            <dd className="dimension">
                              {formatDecimal(width)} x {formatDecimal(height)}
                            </dd>
                          </dl>
                        )}
                        {type === TYPE_IMAGE && (
                          <dl className="color-dimmed">
                            <dt className="size">
                              <Trans>Size</Trans>
                            </dt>
                            <dd className="size">{formatFilesize(size)}</dd>
                            <dt className="dimension">
                              <Trans>Dimension</Trans>
                            </dt>
                            <dd className="dimension">
                              {formatDecimal(width)} x {formatDecimal(height)}
                            </dd>
                          </dl>
                        )}
                        {type === TYPE_TEXT && isEmpty(messages) && (
                          <p className="message">{message}</p>
                        )}
                        {type === TYPE_TEXT && !isEmpty(messages) && (
                          <p className="message">{messages[0].text}</p>
                        )}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        {isCurrentUserSender && (
          <div className="infoActions">
            {!isEmpty(annotations) && (
              <button
                type="button"
                className="btn btn-default rateFeedbackButton"
                onClick={this.handleShowFeedbackRating}
              >
                {!data.feedbackRating && <i className="fal fa-smile" />}
                {data.feedbackRating === 1 && (
                  <span
                    role="img"
                    aria-label="1 star rating"
                    className="rating-score rating-1"
                  >
                    😕
                  </span>
                )}
                {data.feedbackRating === 2 && (
                  <span
                    role="img"
                    aria-label="2 star rating"
                    className="rating-score rating-2"
                  >
                    😐
                  </span>
                )}
                {data.feedbackRating === 3 && (
                  <span
                    role="img"
                    aria-label="3 star rating"
                    className="rating-score rating-3"
                  >
                    🙂
                  </span>
                )}
                {data.feedbackRating === 4 && (
                  <span
                    role="img"
                    aria-label="4 star rating"
                    className="rating-score rating-4"
                  >
                    😀
                  </span>
                )}
                {data.feedbackRating === 5 && (
                  <span
                    role="img"
                    aria-label="5 star rating"
                    className="rating-score rating-5"
                  >
                    😁
                  </span>
                )}
                <span className="text">
                  <Trans>Rate feedback</Trans>
                </span>
              </button>
            )}
            <button
              type="button"
              className="btn btn-danger deleteButton"
              onClick={this.handleDeleteClick}
            >
              <i className="fal fa-trash-alt" />
              <span className="text">
                <Trans>Delete video</Trans>
              </span>
            </button>
          </div>
        )}
        <button
          type="button"
          className="btn btn-icon-only closeInfoButton"
          onClick={this.handleInfoClose}
        >
          <i className="fal fa-times icon-big color-primary" />
          <div className="sr-only">
            <Trans>Close</Trans>
          </div>
        </button>
      </div>
    );
  }

  render() {
    const {
      className,
      id,
      t,
      useSimpleEditor,
      videoSkipDuration,
      isLandscape,
    } = this.props;
    const {
      isLoading,
      isDeleting,
      isPlayerPlaying,
      isReviewing,
      reviewStartTime,
      reviewEndTime,
      uploadProgress,
      annotationToView,
      isAnnotationTextInputVisible,
      isPlayingControlsHidden,
    } = this.state;
    const data = this.state.data || {};
    const { width, height, duration, videoUrl } = data;
    let { sentAt, seenAt, repliedAt, isSample } = data;

    let reviewProgress = 0;
    if (reviewStartTime > 0 && reviewEndTime > 0) {
      reviewProgress =
        (Date.now() - reviewStartTime) / (reviewEndTime - reviewStartTime);
      if (reviewProgress < 0) {
        reviewProgress = 0;
      } else if (reviewProgress > 1) {
        reviewProgress = 1;
      }
    }

    const annotations = data.annotations || [];
    const isCurrentUserSender = this.getIsCurrentUserSender();
    let header = isCurrentUserSender ? t('Sent Video') : t('Received Video');
    if (isSample) {
      header = t('Example Video');
    }
    const isVideoPortrait = width < height;
    const Editor = useSimpleEditor ? SimpleVideoEditor : VideoEditor;

    if (!isSample) {
      if (isCurrentUserSender) {
        if (!isNil(sentAt)) {
          sentAt = t(formatDate(sentAt));
        }
        if (!isNil(repliedAt)) {
          repliedAt = t(formatDate(repliedAt));
        }
      } else {
        if (!isNil(sentAt)) {
          sentAt = t(formatDate(sentAt));
        }
        if (!isNil(seenAt)) {
          seenAt = t(formatDate(seenAt));
        }
        if (!isNil(repliedAt)) {
          repliedAt = t(formatDate(repliedAt));
        }
      }
    }

    const isEditing = this.state.isEditing && !isSample; // && isNil(repliedAt);
    const annotationToViewType = getAnnotationType(
      (annotationToView || {}).type
    );

    let naturalVideoWidth = 0;
    let naturalVideoHeight = 0;
    let videoWidth = 0;
    let videoHeight = 0;

    try {
      if (this.player) {
        naturalVideoWidth = this.player.videoWidth();
        naturalVideoHeight = this.player.videoHeight();
      }
      if (!isNil(naturalVideoWidth) && !isNil(naturalVideoHeight)) {
        const widthScale = this.videoPlayerContainerWidth / naturalVideoWidth;
        const heightScale =
          this.videoPlayerContainerHeight / naturalVideoHeight;
        const scale = Math.min(widthScale, heightScale);
        videoWidth = naturalVideoWidth * scale;
        videoHeight = naturalVideoHeight * scale;
      }
      if (isNaN(videoWidth)) videoWidth = 0;
      if (isNaN(videoHeight)) videoHeight = 0;
    } catch (error) {}

    return (
      <React.Fragment>
        <div
          className={cx('Screen VideoScreen', className, {
            visible: !isNil(id),
            replied: !isNil(repliedAt),
          })}
        >
          <header>
            <nav>
              {isAnnotationTextInputVisible && (
                <ul>
                  <li></li>
                  <li>
                    <Trans>Annotate</Trans>
                  </li>
                  <li></li>
                </ul>
              )}
              {!isAnnotationTextInputVisible && (
                <ul>
                  <li>
                    {!isEditing && (
                      <button
                        type="button"
                        className="btn btn-icon-only"
                        onClick={(event) => {
                          if (!data.feedbackRating) {
                            this.handleShowFeedbackRating(event, true);
                          } else {
                            this.handleClose();
                          }
                        }}
                      >
                        <i className="fal fa-chevron-left color-default" />
                        <div className="sr-only">
                          <Trans>Back</Trans>
                        </div>
                      </button>
                    )}
                    {isEditing && (
                      <button
                        type="button"
                        className="btn btn-icon-only"
                        onClick={this.handleCancelClick}
                      >
                        <i className="fal fa-chevron-left color-default" />
                        <div className="sr-only">
                          <Trans>Back</Trans>
                        </div>
                      </button>
                    )}
                  </li>
                  <li>{header}</li>
                  <li>
                    {!isLoading && !isCurrentUserSender && (
                      <React.Fragment>
                        {!isEditing && (
                          <button
                            type="button"
                            className="btn btn-icon-only"
                            onClick={this.handleEdit}
                          >
                            <i className="fas fa-reply color-primary" />
                            <div className="sr-only">
                              <Trans>Edit video</Trans>
                            </div>
                          </button>
                        )}
                        {isEditing && (
                          <button
                            type="button"
                            className="btn btn-icon-only"
                            disabled={isEmpty(annotations)}
                            onClick={this.handleSave}
                          >
                            <div className="text color-primary">
                              <Trans>Save</Trans>
                            </div>
                          </button>
                        )}
                      </React.Fragment>
                    )}
                    {!isLoading &&
                      ((!isNil(repliedAt) && isCurrentUserSender) ||
                        isSample) && (
                        <button
                          type="button"
                          className="btn btn-icon-only"
                          onClick={this.handleInfoClick}
                        >
                          <i className="fal fa-info-circle color-primary" />
                          <div className="sr-only">
                            <Trans>Info</Trans>
                          </div>
                        </button>
                      )}
                  </li>
                </ul>
              )}
            </nav>
          </header>
          <section>
            {(isLoading || isDeleting) && <LoadingIndicator light />}
            {!isLoading && !isDeleting && isNil(this.state.data) && (
              <div className="emptyData">
                <h2>
                  <Trans>Oops! There is something wrong with this video.</Trans>
                </h2>
                <p>
                  <Trans>
                    Please contact us at{' '}
                    <a href="mailto:hello@vidbax.com">hello@vidbax.com</a>
                  </Trans>
                </p>
              </div>
            )}
            {!isLoading && !isDeleting && !isNil(this.state.data) && (
              <React.Fragment>
                {(!isEditing || isCurrentUserSender) && (
                  <React.Fragment>
                    <div
                      className="videoPlayerContainer"
                      ref={(ref) => (this.$videoPlayerContainer = ref)}
                    >
                      {videoUrl && (
                        <React.Fragment>
                          <VideoPlayer
                            type="mp4"
                            controls
                            src={videoUrl}
                            onReady={this.handleVideoPlayerReady}
                            onPlay={this.handleVideoPlayerPlayed}
                            onPause={this.handleVideoPlayerPaused}
                            onSeeked={this.handleVideoPlayerSeeked}
                          />
                          {!isPlayerPlaying && (
                            <AnimatedPlayButton
                              className={cx('playButton', {
                                hidden: !isNil(annotationToView),
                              })}
                              onClick={this.handleVideoPlayerPlay}
                            />
                          )}
                          {(isPlayerPlaying || isLandscape) && (
                            <div
                              className={cx('playingControls', {
                                hidden:
                                  (!isLandscape || isPlayerPlaying) &&
                                  isPlayingControlsHidden,
                              })}
                            >
                              <div
                                className="pauseBlocker"
                                onClick={this.handleVideoPlayerPause}
                              />
                              <div className="buttons">
                                <button
                                  type="button"
                                  className="btn btn-icon-only stepBackwardButton"
                                  onClick={this.handleTimelineStepBackward}
                                >
                                  <i className="fas fa-step-backward" />
                                </button>
                                <button
                                  type="button"
                                  className="skipBackwardButton"
                                  onClick={this.handleVideoPlayerSkipBackward}
                                >
                                  <i className="fal fa-undo" />
                                  <span className="text">
                                    {videoSkipDuration}
                                  </span>
                                </button>
                                {isLandscape && (
                                  <button
                                    type="button"
                                    className="pauseButton"
                                    onClick={
                                      isPlayerPlaying
                                        ? this.handleVideoPlayerPause
                                        : this.handleVideoPlayerPlay
                                    }
                                  >
                                    {isReviewing &&
                                      !isPlayerPlaying &&
                                      reviewProgress > 0 && (
                                        <ProgressBar
                                          percentage={reviewProgress}
                                          animate={false}
                                        />
                                      )}
                                    <div className="iconContainer">
                                      <i
                                        className={cx('fas', {
                                          'fa-pause': isPlayerPlaying,
                                          'fa-play': !isPlayerPlaying,
                                        })}
                                      />
                                    </div>
                                  </button>
                                )}
                                {!isLandscape && (
                                  <button
                                    type="button"
                                    className={cx('pauseButton', {
                                      hidden: !isNil(annotationToView),
                                    })}
                                    onClick={this.handleVideoPlayerPause}
                                  >
                                    <div className="iconContainer">
                                      <i className="fas fa-pause" />
                                    </div>
                                  </button>
                                )}
                                <button
                                  type="button"
                                  className="skipForwardButton"
                                  onClick={this.handleVideoPlayerSkipForward}
                                >
                                  <i className="fal fa-redo" />
                                  <span className="text">
                                    {videoSkipDuration}
                                  </span>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-icon-only stepForwardButton"
                                  onClick={this.handleTimelineStepForward}
                                >
                                  <i className="fas fa-step-forward" />
                                </button>
                                {isLandscape && (
                                  <button
                                    type="button"
                                    className="reviewButton"
                                    onClick={this.handleVideoPlayerReviewToggle}
                                  >
                                    <div className="iconContainer">
                                      <i
                                        className={cx(
                                          'fal fa-search-location',
                                          { 'color-primary': isReviewing }
                                        )}
                                      />
                                    </div>
                                  </button>
                                )}
                              </div>
                            </div>
                          )}
                          <div className="annotationIndicators">
                            {annotations.map((annotation, index) => {
                              const { time } = annotation;
                              return (
                                <div
                                  ref={(ref) =>
                                    (this[`annotationButton${time}`] = ref)
                                  }
                                  key={time}
                                  className={cx('annotationIndicator', {
                                    selected:
                                      !isNil(annotationToView) &&
                                      annotationToView.time === time,
                                  })}
                                  style={{
                                    left: `${(time / duration) * 100}%`,
                                  }}
                                >
                                  <button
                                    type="button"
                                    className="playButton"
                                    onClick={() =>
                                      this.handleAnnotationView(
                                        annotation,
                                        true
                                      )
                                    }
                                  >
                                    <i className="fas fa-map-marker" />
                                    <span className="text">{index + 1}</span>
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                    {/* {(isNil(repliedAt) || isSample) && this.renderInfo({ dateInfo, avatar })} */}
                    <button
                      type="button"
                      className={cx('toggleFullscreenButton', {
                        on: !isLandscape,
                      })}
                      onClick={() => this.handleToggleFullscreen(!isLandscape)}
                    >
                      <i
                        className={cx('fas', {
                          'fa-expand-arrows-alt': !isLandscape,
                          'fa-compress-arrows-alt': isLandscape,
                        })}
                      />
                    </button>
                  </React.Fragment>
                )}
                {isEditing && !isCurrentUserSender && (
                  <Editor
                    videoSkipDuration={videoSkipDuration}
                    isVideoPortrait={isVideoPortrait}
                    videoId={id}
                    videoUrl={videoUrl}
                    duration={duration}
                    annotations={annotations}
                    onInit={this.handleVideoEditorInit}
                    onChange={this.handleVideoEditorChange}
                    onAnnotationTextInputVisibilityChange={
                      this.handleAnnotationTextInputVisibilityChange
                    }
                  />
                )}
              </React.Fragment>
            )}
          </section>
        </div>
        <AnnotationViewerScreen
          data={
            ![TYPE_DRAWING, TYPE_TEXT].includes(annotationToViewType)
              ? annotationToView
              : null
          }
          videoId={id}
          naturalVideoWidth={naturalVideoWidth}
          naturalVideoHeight={naturalVideoHeight}
          onClose={this.handleAnnotationViewClose}
        />
        <AnnotationViewerScreen
          className={cx({ notYetReplied: isNil(repliedAt) })}
          data={
            [TYPE_DRAWING, TYPE_TEXT].includes(annotationToViewType)
              ? annotationToView
              : null
          }
          isLandscape={isLandscape}
          overlay
          videoId={id}
          naturalVideoWidth={naturalVideoWidth}
          naturalVideoHeight={naturalVideoHeight}
          onClose={this.handleAnnotationViewClose}
        />
        {this.renderVideoInfo()}
        <UploadProgress title={t('Saving Feedback')} percent={uploadProgress}>
          <p>
            <Trans>
              Please <strong className="color-primary">DO NOT CLOSE</strong> the
              app until your feedback finishes saving.
            </Trans>
          </p>
        </UploadProgress>
      </React.Fragment>
    );
  }
}

export default withTranslation()(withGlobalState(withApi(VideoScreen)));
