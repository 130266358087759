import React from 'react';
import cx from 'classnames';
import { isNil, isEmpty } from 'lodash';
import { withTranslation, Trans } from 'react-i18next';
import CanvasDraw from './CanvasDraw';
import smooth from 'chaikin-smooth';
import simplify from 'simplify-js';

import './style.scss';

import { checkForShape, checkForCircle } from '../../utils/shape';

function smoothLine(line, useSmooth = true, steps = 2) {
  if (isNil(line) || isEmpty(line.points)) {
    return line;
  }

  let points = line.points || [];

  const circle = checkForCircle(points);
  if (!isEmpty(circle)) {
    return {
      ...line,
      curve: true,
      points: circle,
    };
  }

  const shape = checkForShape(points);

  // check for lines
  if (shape.length === 2) {
    return {
      ...line,
      curve: false,
      points: shape,
    };
  } else {
    // check for polygons
    if (shape.length > 3 && shape.length <= 5) {
      const first = shape[0];
      const last = shape[shape.length - 1];
      if (first.x === last.x && first.y === last.y) {
        return {
          ...line,
          curve: false,
          points: shape,
        };
      }
    }
  }

  if (points.length > 20) {
    for (let i = 0; i < steps; i++) {
      points = simplify(points, 5, true);
    }
  }

  if (useSmooth) {
    points = smooth(points.map((i) => [i.x, i.y])).map((i) => ({
      x: i[0],
      y: i[1],
    }));
  }

  return {
    ...line,
    curve: true,
    points,
  };
}

class DrawingScreen extends React.Component {
  static defaultProps = {
    colors: [
      'black',
      'gray',
      'maroon',
      'red',
      'green',
      'lime',
      'olive',
      'yellow',
      'navy',
      'blue',
      'purple',
      'fuchsia',
      'teal',
      'aqua',
      'silver',
      'white',
    ],
    minBrushRadius: 1,
    maxBrushRadius: 20,
    defaultBrushRadius: 5,
  };

  state = {
    loadTimeOffset: 5,
    lazyRadius: 0,
    brushRadius: this.props.defaultBrushRadius,
    brushColor: this.props.colors[0],
    catenaryColor: '#0a0302',
    gridColor: 'rgba(150, 150, 150, 0.2)',
    hideGrid: false,
    canvasWidth: 400,
    canvasHeight: 400,
    disabled: false,
    imgSrc: '',
    immediateLoading: false,
    hideInterface: false,
    pointerScale: 1,
    canvasScale: 1,
  };

  componentDidMount() {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
    window.addEventListener('wheel', this.handleWindowWheel);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.addEventListener('wheel', this.handleWindowWheel);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.naturalWidth !== this.props.naturalWidth ||
      prevProps.naturalHeight !== this.props.naturalHeight
    ) {
      this.handleWindowResize();
    }
  }

  getTouchesDist(touches) {
    if (touches && touches.length === 2) {
      const { pageX: x0, pageY: y0 } = touches[0];
      const { pageX: x1, pageY: y1 } = touches[1];
      const dx = x1 - x0;
      const dy = y1 - y0;
      return Math.sqrt(dx * dx + dy * dy);
    }
    return 0;
  }

  updateDrawingData = (data) => {
    if (this.canvasRef) {
      this.canvasRef.loadSaveData(JSON.stringify(data), true);
    }
  };

  handleWindowResize = () => {
    if (this.canvasContainerRef) {
      const { offsetWidth, offsetHeight } = this.canvasContainerRef;
      this.canvasContainerWidth = offsetWidth;
      this.canvasContainerHeight = offsetHeight;
      let canvasScale = 1;
      let canvasOffsetX = 0;
      let canvasOffsetY = 0;

      const { naturalWidth, naturalHeight } = this.props;
      if (!isNil(naturalWidth) && !isNil(naturalHeight)) {
        const widthScale = this.canvasContainerWidth / naturalWidth;
        const heightScale = this.canvasContainerHeight / naturalHeight;
        canvasScale = Math.min(widthScale, heightScale);
      }

      this.setState({
        canvasWidth: naturalWidth,
        canvasHeight: naturalHeight,
        canvasScale,
        pointerScale: 1 / canvasScale,
        canvasOffsetX,
        canvasOffsetY,
      });
    }
  };

  handleWindowWheel = (event) => {
    const { minBrushRadius, maxBrushRadius } = this.props;
    const { deltaY } = event;
    let { brushRadius } = this.state;
    brushRadius -= deltaY * 0.1;
    if (brushRadius < minBrushRadius) brushRadius = minBrushRadius;
    if (brushRadius > maxBrushRadius) brushRadius = maxBrushRadius;
    this.setState({ brushRadius });
  };

  handleTouchStart = (event) => {
    const { touches } = event;
    this.isPinching = touches.length === 2;
    if (this.isPinching) {
      this.dist0 = this.getTouchesDist(touches);
    }
    this.setState({ disabled: this.isPinching });
  };

  handleTouchEnd = (event) => {
    this.isPinching = false;
    this.setState({ disabled: true });
  };

  handleTouchMove = (event) => {
    if (this.isPinching) {
      const { touches } = event;
      const dist = this.getTouchesDist(touches);
      const deltaY = this.dist0 - dist;
      this.dist0 = dist;
      this.handleWindowWheel({ deltaY });
    }
  };

  handleColorClick = (brushColor) => {
    this.setState({ brushColor });
  };

  handleClearClick = () => {
    if (this.canvasRef) {
      this.canvasRef.clear();
    }
  };

  handleUndoClick = () => {
    if (this.canvasRef) {
      const saveData = this.saveData || { lines: [] };
      if (saveData.lines.length > 0) {
        saveData.lines = saveData.lines.slice(0, -1);
      }
      this.saveData = saveData;
      this.updateDrawingData(saveData);
    }
  };

  handleSave = () => {
    const { onSave } = this.props;
    if (onSave) {
      let data = '';
      if (this.canvasRef) {
        data = this.canvasRef.getSaveData();
      }
      onSave({
        data,
        width: this.props.naturalWidth,
        height: this.props.naturalHeight,
        dataUrl: this.canvasRef.canvasContainer.children[1].toDataURL(),
      });
    }
  };

  handleCanvasDrawChange = () => {
    if (this.canvasRef) {
      const data = JSON.parse(this.canvasRef.getSaveData());
      const lastLine = data.lines[data.lines.length - 1];
      const saveData = this.saveData || { points: [] };
      const prevLastLine = this.lastLine || {};

      if (
        JSON.stringify(prevLastLine.points) !== JSON.stringify(lastLine.points)
      ) {
        this.lastLine = smoothLine(lastLine);
        const updatedSaveData = {
          ...data,
          lines: [...(saveData.lines || []), this.lastLine],
        };
        this.saveData = updatedSaveData;
        this.updateDrawingData(updatedSaveData);
      }
    }
  };

  render() {
    const { className, colors, readOnly, data, onClose } = this.props;
    const { brushRadius, canvasWidth, canvasHeight } = this.state;
    const {
      saveData,
      canvasScale,
      disabled,
      hideGrid,
      brushColor,
      catenaryColor,
      ...options
    } = this.state;

    return (
      <div className={cx('Screen DrawingScreen', className, { readOnly })}>
        <header>
          <nav>
            <ul>
              <li></li>
              <li>
                <Trans>Annotate</Trans>
              </li>
              <li></li>
            </ul>
          </nav>
        </header>
        <section
          className="canvasContainer"
          ref={(ref) => (this.canvasContainerRef = ref)}
          onTouchStart={this.handleTouchStart}
          onTouchMove={this.handleTouchMove}
          onTouchEnd={this.handleTouchEnd}
        >
          <div
            className="canvasWrapper"
            style={{
              width: canvasWidth,
              height: canvasHeight,
              transform: `scale(${canvasScale})`,
            }}
          >
            <CanvasDraw
              {...options}
              saveData={readOnly ? data : saveData}
              disabled={readOnly || disabled}
              hideGrid={readOnly || hideGrid}
              brushColor={readOnly ? 'rgba(0, 0, 0, 0)' : brushColor}
              catenaryColor={readOnly ? 'rgba(0, 0, 0, 0)' : catenaryColor}
              ref={(ref) => (this.canvasRef = ref)}
              onChange={this.handleCanvasDrawChange}
            />
          </div>
        </section>
        <footer>
          <div className="legend">
            <i className="fas fa-paint-brush-alt" />
            <span className="text"><Trans>Drawing</Trans></span>
          </div>
          <div className="tools">
            <div className="brush">
              <div
                className="brushRadius"
                style={{
                  transform: `scale(${brushRadius / 20})`,
                  background: brushColor,
                }}
              />
            </div>
            <div className="colors">
              <ul className="palette">
                {colors.map((color) => (
                  <li
                    className={cx({ selected: brushColor === color })}
                    key={color}
                    style={{ background: color }}
                    onClick={() => this.handleColorClick(color)}
                  />
                ))}
              </ul>
            </div>
            <div className="actions">
              <button
                className="btn btn-default clearButton"
                onClick={this.handleClearClick}
              >
                <i className="fas fa-trash-alt" />
                <span className="text">
                  <Trans>Clear</Trans>
                </span>
              </button>
              <button
                className="btn btn-default undoButton"
                onClick={this.handleUndoClick}
              >
                <i className="fas fa-undo" />
                <span className="text">
                  <Trans>Undo</Trans>
                </span>
              </button>
            </div>
          </div>
          <div className="footerActions">
            <button
              type="button"
              className="btn btn-default"
              onClick={onClose}
            >
              <Trans>Cancel</Trans>
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleSave}
            >
              <Trans>Done</Trans>
            </button>
          </div>
        </footer>
      </div>
    );
  }
}

export default withTranslation()(DrawingScreen);
