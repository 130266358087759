import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import { isNil, isEmpty, isEqual, cloneDeep } from 'lodash';
import { v4 as uuid } from 'uuid';
import Select, { components } from 'react-select';
import { withTranslation, Trans } from 'react-i18next';
import TextareaAutosize from 'react-autosize-textarea';

import './style.scss';

import { withApi } from '../../services/Api';
import { isSlowConnection } from '../../utils';
import { getVideoMetadata } from '../../utils/media';
import VideoPlayer from '../VideoPlayer';
import AvatarIcon from '../AvatarIcon';
import UploadProgress from '../UploadProgress';

const SingleValue = ({ children, ...props }) => {
  const { data } = props;
  return (
    <components.SingleValue {...props}>
      <AvatarIcon {...data} />
      <span className="name">{children}</span>
    </components.SingleValue>
  );
};

const Option = ({ children, ...props }) => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <AvatarIcon {...data} />
      <span className="name">{children}</span>
    </components.Option>
  );
};

const recipientColorStyles = {
  option: (styles) => {
    return {
      ...styles,
      backgroundColor: '#333',
    };
  },
};

const recipientGroupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const formatRecipientGroupLabel = data => (
  <div style={recipientGroupStyles}>
    <span>{data.label}</span>
    <span className="badge">{data.options.length}</span>
  </div>
);

class SendVideoScreen extends React.Component {
  static defaultProps = {
    data: {
      title: '',
      description: '',
      videoUrl: null,
    },
  };

  state = {
    updatedData: cloneDeep(this.props.data),
    uploadProgress: 0,
    videoFile: null,
    errors: {},
    recipients: [],
  };

  componentDidUpdate(prevProps) {
    const { api, currentUser, company, companies } = this.props;
    const students = ((company || {}).students || []).map(s => s.id);
    const prevStudents = ((prevProps.company || {}).students || []).map(s => s.id);
    const friends = ((currentUser || {}).friends || []).concat(students);
    const prevFriends = ((prevProps.currentUser || {}).friends || []).concat(prevStudents);
    const prevCompanies = prevProps.companies || [];

    if ((!isEqual(prevFriends, friends) && !isEmpty(friends)) || 
        (!isEqual(prevCompanies, companies) && !isEmpty(companies))) {
      api.user.getByIds(friends, (result) => {
        const friends = result.map(recipient => ({
          ...recipient,
          label: `${recipient.firstName} ${recipient.lastName}`,
          value: recipient.id,
        }));
        const recipients = this.generateRecipients(friends, companies);
        const recipientsById = {};
        recipients.forEach(recipientGroup => {
          recipientGroup.options.forEach(recipient => {
            recipientsById[recipient.id] = recipient;
          });
        });
        this.setState({ recipients, recipientsById });
      });
    }

    const { visible } = this.props;
    if (prevProps.visible !== visible) {
      if (visible) {
        const recipientId = new URLSearchParams(window.location.search).get('id');
        this.setState(() => {
          const updatedData = cloneDeep(this.props.data);
          if (!isNil(recipientId)) {
            updatedData.recipientId = recipientId;
          }
          return {
            updatedData,
            uploadProgress: 0,
            videoFile: null,
            errors: {},
          };
        });
      } else {
        setTimeout(() => {
          this.setState({ uploadProgress: 0 });
        }, 1000);
      }
    }
  }

  getRecipientById(id) {
    const { recipientsById } = this.state;
    if (!isNil(recipientsById) && !isNil(id)) {
      return recipientsById[id];
    }
    return null;
  }

  generateRecipients(friends, companies) {
    const { t } = this.props;

    const recipients = [
      {
        label: t('Companies'),
        options: companies.map(company => ({
          ...company,
          label: company.name,
          value: company.id,
        })).sort((a, b) => a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1),
      },
      {
        label: t('Friends'),
        options: friends.sort((a, b) => a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1),
      },
    ];

    return recipients;
  }

  handleInputChange = event => {
    const { name, value } = event.target;
    this.setState(state => {
      const { updatedData } = state;
      updatedData[name] = value;
      return { updatedData };
    });
  };

  handleVideoInputChange = (event) => {
    const { files } = event.target;
    let videoFile;
    let videoUrl;

    if (!isEmpty(files)) {
      videoFile = files[0];
      videoUrl = URL.createObjectURL(videoFile);
    } else {
      return;
    }
    
    this.setState({ videoFile }, () => {
      this.handleInputChange({
        target: {
          name: 'videoUrl',
          value: videoUrl,
        },
      });
    });
  };

  handleRecipientChange = (newValue) => {
    this.setState(state => {
      const { updatedData } = state;
      updatedData.recipientId = (newValue || {}).id;
      return { updatedData };
    });
  };

  handleUploadProgress = ({ progress }) => {
    this.setState(state => {
      let { uploadProgress } = state;
      uploadProgress = Math.max(uploadProgress, progress);
      uploadProgress = Math.min(uploadProgress, 0.99);
      return { uploadProgress };
    });
  };

  handleSend = () => {
    const { currentUser, api, onSent } = this.props;
    const { videoFile, updatedData } = this.state;
    const { title, description, recipientId, videoUrl } = updatedData;
    const errors = {};

    if (isNil(videoFile) || isNil(videoUrl)) {
      errors.videoUrl = true;
    }

    if (!(recipientId || '').trim()) {
      errors.recipientId = true;
    }

    if (!(title || '').trim()) {
      errors.title = true;
    }

    this.setState({ errors }, () => {
      if (isEmpty(Object.keys(errors))) {
        getVideoMetadata(videoFile).then(({ width, height, duration }) => {
          const { size, type } = videoFile;
          const id = uuid();
          const senderId = currentUser.id;
          const videoData = {
            id,
            size,
            type,
            width,
            height,
            duration,
            title,
            description,
            senderId,
            recipientId,
            status: 'sent',
            sentAt: moment(),
          };

          this.setState({ uploadProgress: 0.01 }, () => {
            api.video
              .upload(
                videoFile,
                'original',
                this.handleUploadProgress,
                `videos/${id}/`,
                {
                  senderId,
                  recipientId,
                },
              )
              .then(videoUrl => {
                this.setState({ uploadProgress: 0.99 });
                videoData.videoUrl = videoUrl;
                return api.video.create(videoData, id);
              })
              .then(() => {
                console.log('Video successfully sent!');
                this.setState({ uploadProgress: 1 }, onSent);
              })
              .catch(error => {
                console.error('Error sending video!', error);
              });
          });
        });
      }
    });
  };

  handleRemoveError = (fieldName) => {
    const { errors } = this.state;
    delete errors[fieldName];
    this.setState({ errors });
  };

  render() {
    const { className, visible, onClose } = this.props;
    const { uploadProgress, updatedData, recipients, errors } = this.state;

    return (
      <div className={cx('Screen SendVideoScreen', className, { visible })}>
        <header>
          <nav>
            <ul>
              <li>
                <button type="button" className="btn btn-icon-only" onClick={onClose}>
                  <i className="fal fa-times icon-big color-default" />
                  <div className="sr-only"><Trans>Close</Trans></div>
                </button>
              </li>
              <li><Trans>Send Video</Trans></li>
              <li>
                <button type="button" className="btn btn-icon-only" onClick={this.handleSend}>
                  <div className="text color-primary"><Trans>Send</Trans></div>
                </button>
              </li>
            </ul>
          </nav>
        </header>
        <section>
          {isSlowConnection() && <p className="wifiWarning"><Trans>Make sure you upload videos while connected to Wi-Fi to avoid data charges.</Trans></p>}
          <div className="wrapper">
            <form>
              <fieldset className={cx('inline', { error: errors.recipientId })} onAnimationEnd={() => this.handleRemoveError('recipientId')}>
                <label htmlFor="recipientId"><Trans>Recipient</Trans></label>
                <Select
                  name="recipientId"
                  className="form-control SelectControl"
                  isSearchable={false}
                  isCreatable={false}
                  isClearable
                  styles={recipientColorStyles}
                  components={{ SingleValue, Option }}
                  options={recipients}
                  formatGroupLabel={formatRecipientGroupLabel}
                  onChange={this.handleRecipientChange}
                  value={this.getRecipientById(updatedData.recipientId)}
                />
              </fieldset>
              <fieldset className={cx('inline', { error: errors.title })} onAnimationEnd={() => this.handleRemoveError('title')}>
                <label htmlFor="title">Title</label>
                <input type="text" name="title" className="form-control" onChange={this.handleInputChange} value={updatedData.title} />
              </fieldset>
              <fieldset className="inline">
                <label htmlFor="description">Description</label>
                <TextareaAutosize
                  name="description"
                  className="form-control"
                  onChange={this.handleInputChange}
                  value={updatedData.description}
                  maxRows={4}
                  maxLength={512}
                />
              </fieldset>
              <fieldset className={cx('video', { error: errors.videoUrl })} onAnimationEnd={() => this.handleRemoveError('videoUrl')}>
                <label htmlFor="videoUrl"><Trans>Video</Trans></label>
                <div className="videoInputContainer">
                  <div className="videoInput">
                    {visible && (
                      <input
                        type="file"
                        name="videoUrl"
                        accept="video/mp4,video/x-m4v,video/*"
                        onChange={this.handleVideoInputChange}
                      />
                    )}
                    <i className="fal fa-video-plus" />
                  </div>
                  {updatedData.videoUrl && (
                    <VideoPlayer
                      className="videoPlayer"
                      type="mp4"
                      controls
                      src={updatedData.videoUrl}
                    />
                  )}
                </div>
              </fieldset>
            </form>
            <UploadProgress title={<Trans>Uploading Video</Trans>} percent={uploadProgress}>
              <p><Trans>Please <strong className="color-primary">DO NOT CLOSE</strong> the app until your video finishes uploading.</Trans></p>
            </UploadProgress>
          </div>
        </section>
      </div>
    );
  }
}

export default withTranslation()(withApi(SendVideoScreen));