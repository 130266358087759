import { match } from 'path-to-regexp';
import { trackPage } from '../services/Analytics';

let lastRoute;

const HM_MEMORY = 'memory';
const HM_HASH = 'hash';
const HM_BROWSER = 'browser';

export function getRouteFromPath(routes, path) {
  for (let i = 0; i < routes.length; i++) {
    const route = routes[i];
    const matcher = match(route.path, { decode: decodeURIComponent });
    const result = matcher(path);
    if (result) {
      return {
        ...route,
        currentPath: path,
        params: result.params,
      };
    }
  }

  return null;
}

export function goToRoute(route, name = 'Unknown', historyMode = window.historyMode) {
  if (lastRoute !== route) {
    trackPage(route, name);
  }

  lastRoute = route;

  switch (historyMode) {
    case HM_HASH:
      window.history.pushState(null, '', `#${route}`);
      break;

    case HM_BROWSER:
      window.history.pushState(null, '', route);
      break;

    case HM_MEMORY:
    default:
      break;
  }
}

export function getWindowLocation(historyMode = window.historyMode) {
  switch (historyMode) {
    case HM_HASH:
      return {
        ...window.location,
        originalPathname: window.location.pathname,
        pathname: window.location.hash.replace('#', ''),
      };

    case HM_BROWSER:
      return window.location

    case HM_MEMORY:
    default:
      return { pathname: lastRoute || '/' };
  }
}
