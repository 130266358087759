import React from 'react';
import { isNil, isEmpty, difference, uniq } from 'lodash';
import { withTranslation, Trans } from 'react-i18next';

import './style.scss';

import { withApi } from '../../services/Api';
import { formatDecimal } from '../../utils';
import LoadingIndicator from '../LoadingIndicator';
import ContactList from '../ContactList';

class ContactsScreen extends React.Component {
  state = {
    isLoading: false,
    usersById: {},
  };

  isInit = false;

  componentDidMount() {
    this.checkFetchFriends({});
  }
  
  componentDidUpdate(prevProps) {
    this.checkFetchFriends(prevProps);
  }

  checkFetchFriends(prevProps) {
    const company = this.props.company || {};
    const prevCompany = prevProps.company || {};
    const currentUser = this.props.currentUser || {};
    const prevCurrentUser = prevProps.currentUser || {};

    const friends = currentUser.friends || [];
    const friendRequestsSent = currentUser.friendRequestsSent || [];
    const friendRequestsReceived = currentUser.friendRequestsReceived || [];

    const prevFriends = prevCurrentUser.friends || [];
    const prevFriendRequestsSent = prevCurrentUser.friendRequestsSent || [];
    const prevFriendRequestsReceived = prevCurrentUser.friendRequestsReceived || [];

    const students = (company.students || []).map(s => s.id);
    const prevStudents = (prevCompany.students || []).map(s => s.id);

    let diffIds = [];
    diffIds = [...diffIds, ...difference(friends, prevFriends)];
    diffIds = [...diffIds, ...difference(friendRequestsSent, prevFriendRequestsSent)];
    diffIds = [...diffIds, ...difference(friendRequestsReceived, prevFriendRequestsReceived)];
    diffIds = [...diffIds, ...difference(students, prevStudents)];
    diffIds = uniq(diffIds);

    if (!isEmpty(diffIds)) {
      if (!this.isInit) {
        this.setState({ isLoading: true });
      }
      this.fetchFriends(diffIds);
    } else {
      if (!isNil(this.props.currentUser) && this.state.isLoading) {
        if (!this.isInit && students.length === 0 && friends.length === 0) {
          this.setState({ isLoading: false });
        }
        this.isInit = true;
      }
    }
  }

  fetchFriends(ids) {
    const { api } = this.props;
    const company = this.props.company || {};
    const currentUser = this.props.currentUser || {};
    const friends = currentUser.friends || [];
    const friendRequestsSent = currentUser.friendRequestsSent || [];
    const friendRequestsReceived = currentUser.friendRequestsReceived || [];
    const students = (company.students || []).map(s => s.id);
    const allFriends = ids || friends.concat(friendRequestsSent).concat(friendRequestsReceived).concat(students);

    if (!isEmpty(allFriends)) {
      api.user.getByIds(allFriends, (result) => {
        const { usersById } = this.state;
        result.forEach(friend => {
          let status = 'approved';
          if (friendRequestsSent.includes(friend.id)) {
            status = 'sent';
          } else if (friendRequestsReceived.includes(friend.id)) {
            status = 'received';
          }
          usersById[friend.id] = {
            ...friend,
            status,
          };
        });
        this.isInit = true;
        this.setState({
          usersById,
          isLoading: false,
        });
      }, { refreshCache: true });
    }
  }

  handleAddClick = () => {
    const { onAddClick } = this.props;
    if (onAddClick) {
      onAddClick();
    }
  };

  handleVideoSendClick = (friend) => {
    const { onVideoSendClick } = this.props;
    if (onVideoSendClick) {
      onVideoSendClick(friend);
    }
  };

  handleRequestCancel = (friend) => {
    const { api, currentUser } = this.props;
    if (!isNil(friend) && !isNil(friend.id)) {
      api.user.cancelFriendRequest(currentUser.id, friend.id);
    }
  };

  handleRequestApprove = (friend) => {
    const { api, currentUser } = this.props;
    if (!isNil(friend) && !isNil(friend.id)) {
      api.user.approveFriendRequest(currentUser.id, friend.id);
    }
  };

  handleRequestReject = (friend) => {
    const { api, currentUser } = this.props;
    if (!isNil(friend) && !isNil(friend.id)) {
      api.user.rejectFriendRequest(currentUser.id, friend.id);
    }
  };

  render() {
    const { isLoading, usersById } = this.state;
    const { companies, onMenuClick } = this.props;

    const company = this.props.company || {};
    const currentUser = this.props.currentUser || {};
    const friends = (currentUser.friends || []).concat(currentUser.friendRequestsSent || []).map(f => usersById[f]).filter(f => !!f);
    const friendRequestsReceived = (currentUser.friendRequestsReceived || []).map(f => usersById[f]).filter(f => !!f);
    const students = (company.students || []).map(f => usersById[f.id]).filter(f => !!f);

    return (
      <div className="Screen ContactsScreen">
        <header>
          <nav>
            <ul>
              <li>
                <button type="button" className="btn btn-icon-only" onClick={onMenuClick}>
                  <i className="fal fa-bars color-primary icon-big" />
                  <div className="sr-only"><Trans>Menu</Trans></div>
                </button>
              </li>
              <li><Trans>Contacts</Trans></li>
              <li>
                <button type="button" className="btn btn-icon-only" onClick={this.handleAddClick}>
                  <i className="fal fa-plus color-primary icon-big" />
                  <div className="sr-only"><Trans>Add</Trans></div>
                </button>
              </li>
            </ul>
          </nav>
        </header>
        <section>
          {isLoading && <LoadingIndicator light />}
          {!isLoading && isNil(currentUser.companyId) && !isEmpty(companies) && (
            <div className="contactListContainer companies">
              <h2><Trans>Companies</Trans></h2>
              <span className="badge">{formatDecimal(companies.length)}</span>
              <ContactList
                data={companies.map(company => {
                  company.status = 'approved';
                  return company;
                })}
                onVideoSendClick={this.handleVideoSendClick}
              />
            </div>
          )}
          {!isLoading && !isNil(currentUser.companyId) && !isEmpty(students) && (
            <div className="contactListContainer friendRequestsReceived">
              <h2><Trans>Students</Trans></h2>
              <span className="badge">{formatDecimal(students.length)}</span>
              <ContactList
                data={students}
                onVideoSendClick={this.handleVideoSendClick}
              />
            </div>
          )}
          {!isLoading && !isNil(currentUser.companyId) && !isEmpty(friendRequestsReceived) && (
            <div className="contactListContainer friendRequestsReceived">
              <h2><Trans>Friend Requests</Trans></h2>
              <span className="badge">{formatDecimal(friendRequestsReceived.length)}</span>
              <ContactList
                data={friendRequestsReceived}
                onRequestApprove={this.handleRequestApprove}
                onRequestReject={this.handleRequestReject}
              />
            </div>
          )}
          {!isLoading && !isNil(currentUser.companyId) && !isEmpty(friends) && (
            <div className="contactListContainer friends">
              <h2><Trans>Friends</Trans></h2>
              <span className="badge">{formatDecimal(friends.length)}</span>
              <ContactList
                data={friends}
                onRequestCancel={this.handleRequestCancel}
                onVideoSendClick={this.handleVideoSendClick}
              />
            </div>
          )}
          {!isLoading && isNil(currentUser.companyId) && isEmpty(friendRequestsReceived) && isEmpty(friends) && isEmpty(students) && isEmpty(companies) && (
            <div className="contactListContainer emptyList">
              <h2><Trans>You haven't got anyone on your contact list yet.</Trans></h2>
              <p><Trans>Send a friend request by tapping<br />the <i className="fal fa-plus text-larger color-primary" /> button at the top-right corner.</Trans></p>
            </div>
          )}

          {!isLoading && !isNil(currentUser.companyId) && isEmpty(friendRequestsReceived) && isEmpty(friends) && isEmpty(students) && isEmpty(companies) && (
            <div className="contactListContainer emptyList">
              <h2><Trans>You haven't got anyone on your contact list yet.</Trans></h2>
              <p><Trans>Invite a student by tapping<br />the <i className="fal fa-plus text-larger color-primary" /> button at the top-right corner.</Trans></p>
            </div>
          )}
        </section>
      </div>
    );
  }
}

export default withTranslation()(withApi(ContactsScreen));