import React from 'react';
import cx from 'classnames';
import { isNil } from 'lodash';
import { withTranslation, Trans } from 'react-i18next';

import './style.scss';

import { getCurrentUserMobile } from '../../services/Api';
import { joinNames, formatMobile } from '../../utils';
import AvatarIcon from '../AvatarIcon';

class MainMenu extends React.Component {
  handleMenuItemClick = (value) => {
    const { onMenuItemClick } = this.props;
    if (onMenuItemClick) {
      onMenuItemClick(value);
    }
  };

  render() {
    const { visible, currentUser, company, onSignOut, onClose } = this.props;
    const { firstName, lastName } = currentUser || {};
    const mobile = getCurrentUserMobile();
    const name = joinNames(firstName, lastName);

    return (
      <div className={cx('MainMenu', { visible })}>
        <div className="blocker" onClick={onClose} />
        <nav>
          {!isNil(currentUser) && !isNil(company) && (
            <div className="navCompany">
              <div className="companyLogo" style={{
                backgroundImage: `url(${company.photoUrl})`,
              }} />
              <div className="companySubInfo">
                <span className="companyName">{company.name}</span>
              </div>
            </div>
          )}
          {!isNil(currentUser) && (
            <div className="navProfile">
              <AvatarIcon {...currentUser} />
              <div className="name">
                {(name || '').trim() ? (
                  name.trim()
                ) : (
                  <em className="empty">
                    <Trans>Unknown name</Trans>
                  </em>
                )}
              </div>
              <div className="mobile">
                {(mobile || '').trim() ? (
                  formatMobile(mobile.trim())
                ) : (
                  <em className="empty">
                    <Trans>Unknown mobile number</Trans>
                  </em>
                )}
              </div>
              {!isNil(company) && (
                <div className="companyRole">
                  {(currentUser.companyRole || '').trim() ? (
                    currentUser.companyRole.trim()
                  ) : (
                    <em className="empty">
                      <Trans>Unknown role</Trans>
                    </em>
                  )}
                </div>
              )}
            </div>
          )}
          <ul className="navItems">
            {!isNil(currentUser) && !isNil(company) && currentUser.companyRole === 'Admin' && (
              <React.Fragment>
                <li>
                  <button type="button" onClick={() => this.handleMenuItemClick('staff')}>
                    <i className="fal fa-users" />
                    <span className="text"><Trans>Manage Staff</Trans></span>
                  </button>
                </li>
                {/* <li>
                  <button type="button" onClick={() => this.handleMenuItemClick('students')}>
                    <i className="fal fa-users-class" />
                    <span className="text"><Trans>Manage Students</Trans></span>
                  </button>
                </li> */}
              </React.Fragment>
            )}
          </ul>
          <div className="navFooter">
            <button type="button" className="btn btn-danger signOutButton" onClick={onSignOut}>
              <i className="fal fa-sign-out" />
              <span className="text"><Trans>Sign out</Trans></span>
            </button>
          </div>
          <button className="btn btn-icon-only closeButton" onClick={onClose}>
            <i className="fal fa-times icon-big" />
            <span className="sr-only"><Trans>Close</Trans></span>
          </button>
        </nav>
      </div>
    );
  }
}

export default withTranslation()(MainMenu);