import React from 'react';
import cx from 'classnames';
import { isNil } from 'lodash';
import { withTranslation, Trans } from 'react-i18next';
import AudioRecorder from 'audio-recorder-polyfill';
import mpegEncoder from 'audio-recorder-polyfill/mpeg-encoder';

import './style.scss';

const TYPE_PLAYBACK = 'playback';
const TYPE_RECORDING = 'recording';

const OSCILLOSCOPE_NONE = 'none';
const OSCILLOSCOPE_WAVE = 'wave';
const OSCILLOSCOPE_CIRCLE = 'circle';

AudioRecorder.encoder = mpegEncoder;
AudioRecorder.prototype.mimeType = 'audio/mpeg';
window.MediaRecorder = AudioRecorder;

const { MediaRecorder } = window;

class AudioRecorderScreen extends React.Component {
  static defaultProps = {
    oscilloscope: OSCILLOSCOPE_NONE,
  };

  state = {
    isRecording: false,
  };

  constructor(props) {
    super(props);

    this.renderOscilloscope = this.renderOscilloscope.bind(this);
  }

  componentWillUnmount() {
    this.destroyAudio(true);
  }

  setupAudio(isPlaybackOrRecording, source) {
    const { oscilloscope } = this.props;
    if (oscilloscope === OSCILLOSCOPE_NONE) {
      return;
    }

    if (isPlaybackOrRecording === TYPE_RECORDING) {
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      this.audioMasterGain = this.audioContext.createGain();
      this.audioMasterGain.connect(this.audioContext.destination);
      this.audioSource = this.audioContext.createMediaStreamSource(source);
      this.audioSource.connect(this.audioMasterGain);
      this.audioAnalyser = this.audioContext.createAnalyser();
      this.audioMasterGain.connect(this.audioAnalyser);
      if (this.audioAnalyser.getFloatTimeDomainData) {
        this.audioWaveform = new Float32Array(this.audioAnalyser.frequencyBinCount);
        this.audioAnalyser.getFloatTimeDomainData(this.audioWaveform);
      } else {
        this.audioAnalyser.fftSize = 1024;
        this.audioDataArray = new Uint8Array(this.audioAnalyser.fftSize);
        this.audioAnalyser.getByteTimeDomainData(this.audioDataArray);
      }
    } else if (isPlaybackOrRecording === TYPE_PLAYBACK) {
      if (this.playbackAudioContext) {
        this.audioContext = this.playbackAudioContext;
        this.audioMasterGain = this.playbackAudioMasterGain;
        this.audioSource = this.playbackAudioSource;
        this.audioAnalyser = this.playbackAudioAnalyser;
        this.audioWaveform = this.playbackAudioWaveform;
        this.audioDataArray = this.playbackAudioDataArray;
      } else {
        this.audioContext = this.playbackAudioContext = new (window.AudioContext || window.webkitAudioContext)();
        this.audioMasterGain = this.playbackAudioMasterGain = this.audioContext.createGain();
        this.audioMasterGain.connect(this.audioContext.destination);
        this.audioSource = this.playbackAudioSource = this.audioContext.createMediaElementSource(source);
        this.audioSource.connect(this.audioMasterGain);
        this.audioAnalyser = this.playbackAudioAnalyser = this.audioContext.createAnalyser();
        this.audioMasterGain.connect(this.audioAnalyser);
        if (this.audioAnalyser.getFloatTimeDomainData) {
          this.audioWaveform = this.playbackAudioWaveform = new Float32Array(this.audioAnalyser.frequencyBinCount);
          this.audioAnalyser.getFloatTimeDomainData(this.audioWaveform);
        } else {
          this.audioAnalyser.fftSize = 1024;
          this.audioDataArray = this.playbackAudioDataArray = new Uint8Array(this.audioAnalyser.fftSize);
          this.audioAnalyser.getByteTimeDomainData(this.audioDataArray);
        }
      }
    }
  }

  destroyAudio(clean) {
    if (this.recorder) {
      this.recorder.removeEventListener('dataavailable', this.handleRecordComplete);
    }
    this.recorder = null;
    this.audioContext = null;
    this.audioAnalyser = null;
    this.audioWaveform = null;
    this.audioMasterGain = null;
    if (clean || this.audioSource !== this.playbackAudioSource) {
      try { this.audioSource.close(); } catch (error) {}
    }
    this.audioSource = null;
  }

  handleSave = () => {
    const { onSave } = this.props;
    const { audioData } = this.state;
    if (onSave) {
      onSave(audioData);
    }
  };

  handleClose = () => {
    this.handleRecordStop();
    this.handlePlaybackStop();

    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  };

  handleRecordComplete = event => {
    const { data: audioData } = event;
    this.audioRef.src = URL.createObjectURL(audioData);
    this.setState({ audioData }, () => {
      this.destroyAudio();
    });
  };

  handleRecordStart = () => {
    this.destroyAudio();

    try {
      window.navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          this.recorder = new MediaRecorder(stream);
          this.recorder.addEventListener('dataavailable', this.handleRecordComplete);
          this.recorder.start();
          this.setState({ isRecording: true, isPlaying: false }, () => {
            this.setupAudio(TYPE_RECORDING, stream);
            this.renderOscilloscope();
          });
        }).catch((error) => {
          alert(error);
        });
    } catch (error) {
      console.warn(error);
    }
  };

  handleRecordStop = () => {
    this.setState({ isRecording: false, isPlaying: false }, () => {
      if (this.recorder) {
        this.recorder.stop();
        this.recorder.stream.getTracks().forEach(i => i.stop());
      }
    });
  };

  handlePlaybackPlay = () => {
    this.setupAudio(TYPE_PLAYBACK, this.audioRef);
    this.audioRef.play();
    this.setState({ isRecording: false, isPlaying: true }, () => {
      this.renderOscilloscope();
    });
  };

  handlePlaybackStop = () => {
    if (this.audioRef) {
      this.audioRef.pause();
      this.audioRef.currentTime = 0;
    }
    this.setState({ isRecording: false, isPlaying: false }, () => {
      this.destroyAudio();
    });
  };

  renderOscilloscope() {
    const { oscilloscope } = this.props;
    if (oscilloscope === OSCILLOSCOPE_NONE) {
      return;
    }

    const { isRecording, isPlaying } = this.state;

    if ((isRecording || isPlaying) && this.audioContext) {
      if (!this.scopeContext) {
        this.scopeCanvas = this.waveOscilloscopeRef;
        this.scopeContext = this.scopeCanvas.getContext('2d');
      }
  
      let audioWaveform;
      let audioWaveformLen;
      let audioWaveformFactor;
      let heightFactor;
      let startFactor;
      let startScaleFactor;
      let scaleFactor;

      if (this.audioWaveform) {
        this.audioAnalyser.getFloatTimeDomainData(this.audioWaveform);
        audioWaveform = this.audioWaveform;
        audioWaveformLen = audioWaveform.length;
        audioWaveformFactor = 2;
        heightFactor = 1;
        startFactor = 0.5;
        startScaleFactor = 1;
        scaleFactor = 10;
      } else {
        this.audioAnalyser.getByteTimeDomainData(this.audioDataArray);
        audioWaveform = this.audioDataArray;
        audioWaveformLen = this.audioAnalyser.fftSize;
        audioWaveformFactor = 128;
        heightFactor = 0.5;
        startFactor = 0;
        startScaleFactor = -3;
        scaleFactor = 1 / 32;
      }

      if (oscilloscope === OSCILLOSCOPE_WAVE) {
        this.scopeCanvas.width = audioWaveformLen;
        this.scopeCanvas.height = 200;
        this.scopeContext.clearRect(0, 0, this.scopeCanvas.width, this.scopeCanvas.height);
        this.scopeContext.strokeStyle = '#FF9900';
        this.scopeContext.beginPath();
    
        const waveInc = Math.floor(audioWaveformLen * 0.01);
        for (let i = 0; i < audioWaveformLen; i += waveInc) {
          const index = i < audioWaveformLen ? i : audioWaveformLen - 1;
          const x = i;
          const y = (startFactor + (audioWaveform[index] / audioWaveformFactor)) * this.scopeCanvas.height * heightFactor;

          if (i === 0) {
            this.scopeContext.moveTo(x, y);
          } else {
            this.scopeContext.lineTo(x, y);
          }
        }
        this.scopeContext.stroke();
      } else if (oscilloscope === OSCILLOSCOPE_CIRCLE) {
        const scale = startScaleFactor + audioWaveform[Math.floor(audioWaveformLen * 0.5)] * scaleFactor;
        this.circleOscilloscopeRef.style.transform = `scale(${scale})`;
      }

      window.requestAnimationFrame(this.renderOscilloscope);
    }
  }

  render() {
    const { visible, className, oscilloscope } = this.props;
    const { isRecording, isPlaying, audioData } = this.state;

    return (
      <div className={cx('Screen AudioRecorderScreen', className, { visible })}>
        <header>
          <nav>
            <ul>
              <li>
                <button type="button" className="btn btn-icon-only" onClick={this.handleClose}>
                  <i className="fal fa-times icon-big color-primary" />
                  <div className="sr-only"><Trans>Close</Trans></div>
                </button>
              </li>
              <li><Trans>Record Voice</Trans></li>
              <li>
                <button type="button" className="btn btn-icon-only" onClick={this.handleSave}>
                  <i className="fal fa-check icon-big color-primary" />
                  <div className="sr-only"><Trans>Save</Trans></div>
                </button>
              </li>
            </ul>
          </nav>
        </header>
        <section>
          <div className="oscilloscopeContainer">
            <canvas
              ref={ref => this.waveOscilloscopeRef = ref}
              className={cx('waveCanvas', { visible: (isRecording || isPlaying) && oscilloscope === OSCILLOSCOPE_WAVE })}
            />
            <div
              ref={ref => this.circleOscilloscopeRef = ref}
              className={cx('circleIndicator', { visible: (isRecording || isPlaying) && oscilloscope === OSCILLOSCOPE_CIRCLE })}
            />
          </div>
          <div className="actions">
            {!(isRecording || isPlaying) && (
              <React.Fragment>
                {!isNil(audioData) && (
                  <button className="btn btn-default btn-icon-only" onClick={this.handlePlaybackPlay}>
                    <i className="fas fa-play" />
                  </button>
                )}
                <button className="btn btn-default btn-icon-only" onClick={this.handleRecordStart}>
                  <i className="fas fa-microphone-alt" />
                </button>
              </React.Fragment>
            )}
            {isRecording && (
              <button className="btn btn-danger btn-icon-only" onClick={this.handleRecordStop}>
                <i className="fas fa-stop" />
              </button>
            )}
            {isPlaying && (
              <button className="btn btn-danger btn-icon-only" onClick={this.handlePlaybackStop}>
                <i className="fas fa-stop" />
              </button>
            )}
          </div>
          <audio ref={ref => this.audioRef = ref } />
        </section>
      </div>
    );
  }
}

export default withTranslation()(AudioRecorderScreen);