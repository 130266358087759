import { isNil } from 'lodash';

export const TYPE_IMAGE = 'image';
export const TYPE_VIDEO = 'video';
export const TYPE_TEXT = 'text';
export const TYPE_DRAWING = 'drawing';

export const AUDIO_SUPPORTED_TYPES = [TYPE_DRAWING, TYPE_IMAGE, TYPE_TEXT];

export function getAnnotationType(type) {
  if (isNil(type)) return TYPE_TEXT;
  if (type.includes(TYPE_IMAGE)) {
    return TYPE_IMAGE;
  } else if (type.includes(TYPE_VIDEO)) {
    return TYPE_VIDEO;
  } else if (type.includes(TYPE_DRAWING)) {
    return TYPE_DRAWING;
  }
  return TYPE_TEXT;
}

export function checkAnnotationIsPortrait(annotation) {
  if (isNil(annotation)) return false;
  const type = getAnnotationType(annotation.type);
  switch (type) {
    case TYPE_VIDEO:
    case TYPE_IMAGE:
      return annotation.width < annotation.height;

    case TYPE_TEXT:
    case TYPE_DRAWING:
    default:
      return false;
  }
}

export function checkAnnotationHasAudio(annotation) {
  const type = getAnnotationType((annotation || {}).type);
  return AUDIO_SUPPORTED_TYPES.includes(type) &&
         !isNil(annotation.audio) &&
         !isNil(annotation.audio.src);
}