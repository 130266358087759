import React from 'react';
import cx from 'classnames';
import { v4 as uuid } from 'uuid';
import { isNil, isEmpty, cloneDeep } from 'lodash';
import { withTranslation, Trans } from 'react-i18next';

import './style.scss';

import { withApi } from '../../services/Api';
import { withGlobalState } from '../../services/GlobalState';
import { formatDecimal, normalizeMobile } from '../../utils';
import LoadingIndicator from '../LoadingIndicator';
import StaffList from '../StaffList';
import StaffDetailsScreen from '../StaffDetailsScreen';

class StaffScreen extends React.Component {
  state = {
    isLoading: false,
    isDeleting: false,
    staff: [],
    usersById: {},
    staffDetails: null,
  };

  isInit = false;

  componentDidMount() {
    this.checkFetchStaff({});
  }
  
  componentDidUpdate(prevProps) {
    this.checkFetchStaff(prevProps);
  }
0
  getStaffIds(staff) {
    return staff.filter(s => !isNil(s.id)).map(s => s.id);
  }

  checkFetchStaff(prevProps) {
    const company = this.props.company || {};
    const staffIds = this.getStaffIds(company.staff || []);
    const prevStaffIds = this.getStaffIds((prevProps.company || {}).staff || []);

    let diffIds = [];
    if (staffIds.sort().join(',') !== prevStaffIds.sort().join(',')) {
      diffIds = staffIds;
    }

    if (!isEmpty(diffIds)) {
      if (!this.isInit) {
        this.setState({ isLoading: true });
      }
      this.fetchStaff(diffIds);
    } else {
      if (!isNil(this.props.company) && this.state.isLoading) {
        if (!this.isInit && staffIds.length === 0) {
          this.setState({ isLoading: false });
        }
        this.isInit = true;
      }
    }
  }

  fetchStaff(ids) {
    const { api } = this.props;
    const company = this.props.company || {};
    const staff = company.staff || [];
    const allStaff = ids || this.getStaffIds(staff);

    if (!isEmpty(allStaff)) {
      api.user.getByIds(allStaff, (result) => {
        const { usersById } = this.state;
        result.forEach(s => {
          usersById[s.id] = s;
        });
        this.isInit = true;
        this.setState({
          usersById,
          isLoading: false,
        });
      }, { refreshCache: true });
    }
  }

  handleAddClick = () => {
    this.setState({ staffDetails: cloneDeep(StaffDetailsScreen.defaultProps.data) });
  };

  handleStaffClick = (staff) => {
    this.setState({ staffDetails: cloneDeep(staff) });
  };

  handleStaffDetailsClose = () => {
    this.setState({ staffDetails: null });
  };

  handleStaffCreate = (newStaff) => {
    const { api, company } = this.props;
    if (!isNil(company)) {
      const staff = company.staff || [];
      const existingStaff = staff.find(s => (normalizeMobile(s.mobile) === normalizeMobile(newStaff.mobile)));
      if (!isNil(existingStaff)) {
        Object.keys(existingStaff).concat(Object.keys(newStaff)).forEach(key => {
          existingStaff[key] = newStaff[key];
        });
      } else {
        staff.push({
          rid: uuid(),
          ...newStaff,
        });
      }
      company.staff = staff;
      api.user.update({
        id: company.id,
        staff,
      }).then(() => {
        this.setState({ staffDetails: null });
      });
    }
  };

  handleStaffUpdate = (updatedStaff) => {
    const { api, company } = this.props;
    if (!isNil(company)) {
      const staff = company.staff || [];
      const existingStaff = staff.find(s => s.rid === updatedStaff.rid);
      if (!isNil(existingStaff)) {
        Object.keys(existingStaff).concat(Object.keys(updatedStaff)).forEach(key => {
          existingStaff[key] = updatedStaff[key];
        });
        company.staff = staff;
        api.user.update({
          id: company.id,
          staff,
        }).then(() => {
          this.setState({ staffDetails: null });
        });
      }
    }
  };

  handleStaffEdit = (staffDetails) => {
    this.setState({ staffDetails });
  };

  handleStaffDeleteClick = (staffToDelete) => {
    const { t } = this.props;
    const { showAlert } = this.props.globalState;
    showAlert({
      staffToDelete,
      type: 'warning',
      title: t('Delete Staff'),
      message: t('Are you sure you want to delete this staff?'),
      cancel: t('No'),
      ok: t('Yes'),
      onOk: this.handleItemDelete,
    });
  };

  handleItemDelete = ({ staffToDelete }) => {
    const rid = (staffToDelete || {}).rid;
    const { api, company } = this.props;
    if (!isNil(company)) {
      if (!isNil(rid)) {
        const staff = (company.staff || []).filter(s => s.rid !== rid);
        company.staff = staff;
        api.user.update({
          id: company.id,
          staff,
        }).then(() => {
          this.setState({ staffDetails: null });
        });
      }
    }
  };

  render() {
    const { isLoading, isDeleting, staffDetails, usersById } = this.state;
    const { visible, onClose } = this.props;
    const company = this.props.company || {};
    let staff = cloneDeep(company.staff || []);
    staff.filter(s => !isNil(s.id)).forEach(s => {
      staff[s.id] = usersById[s.id];
    });

    return (
      <React.Fragment>
        <div className={cx('Screen StaffScreen', { visible, behind: !isNil(staffDetails) })}>
          <header>
            <nav>
              <ul>
                <li>
                  <button type="button" className="btn btn-icon-only" onClick={onClose}>
                    <i className="fal fa-times color-default icon-big" />
                    <div className="sr-only"><Trans>Close</Trans></div>
                  </button>
                </li>
                <li><Trans>Staff</Trans></li>
                <li>
                  <button type="button" className="btn btn-icon-only" onClick={this.handleAddClick}>
                    <i className="fal fa-plus color-primary icon-big" />
                    <div className="sr-only"><Trans>Add</Trans></div>
                  </button>
                </li>
              </ul>
            </nav>
          </header>
          <section>
            {(isLoading || isDeleting) && <LoadingIndicator light />}
            {!isLoading && !isDeleting && !isEmpty(staff) && (
              <div className="staffListContainer">
                <h2><Trans>Staff</Trans></h2>
                <span className="badge">{formatDecimal(staff.length)}</span>
                <StaffList
                  data={staff}
                  usersById={usersById}
                  onItemEdit={this.handleStaffEdit}
                  onItemDelete={this.handleStaffDeleteClick}
                />
              </div>
            )}
            {!isLoading && !isDeleting && isEmpty(staff) && (
              <div className="staffListContainer emptyList">
                <h2><Trans>You haven't got anyone on your staff list yet.</Trans></h2>
                <p><Trans>Add a staff by tapping<br />the <i className="fal fa-plus text-larger color-primary" /> button at the top-right corner.</Trans></p>
              </div>
            )}
          </section>
        </div>
        <StaffDetailsScreen
          visible={!isNil(staffDetails)}
          data={staffDetails}
          staff={staff}
          onCreate={this.handleStaffCreate}
          onUpdate={this.handleStaffUpdate}
          onClose={this.handleStaffDetailsClose}
        />
      </React.Fragment>
    );
  }
}

export default withTranslation()(withGlobalState(withApi(StaffScreen)));