import React from 'react';
import cx from 'classnames';
import { isNil, isEmpty } from 'lodash';
import shortid from 'shortid';
import { withTranslation, Trans } from 'react-i18next';
import CanvasDraw from 'react-canvas-draw';
import ReactAudioPlayer from 'react-audio-player';

import './style.scss';

import { formatTime } from '../../utils';
import { withGlobalState } from '../../services/GlobalState';
import {
  getVideoMetadata,
  getAudioMetadata,
  getImageMetadata,
  cropAndResizeImage,
  dataURLtoBlob,
} from '../../utils/media';
import {
  TYPE_IMAGE,
  TYPE_TEXT,
  TYPE_VIDEO,
  TYPE_DRAWING,
  AUDIO_SUPPORTED_TYPES,
  getAnnotationType,
  checkAnnotationIsPortrait,
  checkAnnotationHasAudio,
} from '../../utils/annotation';
import VideoPlayer from '../VideoPlayer';
import DrawingScreen from '../DrawingScreen';
import AudioRecorderScreen from '../AudioRecorderScreen';

const PRESS_MOVE_CIRC = 29 * 29;
const PRESS_CLICK_DURATION = 400;
const PRESS_HOLD_DURATION = 500;

const sortByTime = (a, b) => a.time - b.time;

function timeToPixel(time, duration, width) {
  return (time / duration) * width;
}

function pixelToTime(x, duration, width) {
  return (x / width) * duration;
}

function hasQuiet() {
  var cold = false,
  hike = function() {};
  try {
    var aid = Object.defineProperty({}, 'passive', {
      get: () => { cold = true; return cold }
    });
    window.addEventListener('test', hike, aid);
    window.removeEventListener('test', hike, aid);
  } catch (e) {}
  return cold;
}

class VideoEditor extends React.Component {
  static defaultProps = {
    type: null,
    bigPlayButton: false,
    playsinline: true,
    controls: false,
    annotationVideoWidthPercent: 25,
    annotationVideoHeightPercent: 40,
    annotationDisableGapFactor: 0.015,
    annotationAutoPlayGapFactor: 0.001,
  };

  state = {
    videoUrl: this.props.videoUrl,
    annotations: this.props.annotations || [],
    isAnnotationTextInputVisible: false,
    isDrawing: false,
  };

  constructor(props) {
    super(props);

    this.annotationVideoX = 10;
    this.annotationVideoY = 10;
  }

  componentDidMount() {
    window.oncontextmenu = (event) => {
      event.preventDefault();
      event.stopPropagation();
      return false;
    };

    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);

    this.annotationViewerRef.style.transform = `translate(${this.annotationVideoX}px, ${this.annotationVideoY}px)`;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
    document.removeEventListener('mousemove', this.handleAnnotationMouseMove);
    document.removeEventListener('touchmove', this.handleAnnotationMouseMove);
    document.removeEventListener('mousemove', this.handleAnnotationViewerMouseMove);
    document.removeEventListener('touchmove', this.handleAnnotationViewerMouseMove);

    window.oncontextmenu = null;
  }

  getAnnotationAt(time, annotationDisableGapFactor = this.props.annotationDisableGapFactor) {
    const { annotations } = this.state;
    const duration = this.player.duration();
    const timeGap = annotationDisableGapFactor * duration;
    const minTime = time - timeGap;
    const maxTime = time + timeGap;
    return annotations.find(a => a.time >= minTime && a.time <= maxTime);
  }

  updateScrubber = (stopOnPause) => {
    const { annotationAutoPlayGapFactor } = this.props;
    const currentTime = this.player.currentTime();

    if (this.scrubberRef) {
      this.updateScrubberToTime(currentTime);
    }

    if (stopOnPause && this.player.paused()) {
      return;
    }

    window.requestAnimationFrame(this.updateScrubber);

    const { selectedAnnotation } = this.state;
    const { lastPlayedAnnotation } = this;
    const annotation = this.getAnnotationAt(currentTime, annotationAutoPlayGapFactor);
    if (annotation 
        && (!selectedAnnotation || annotation.time !== selectedAnnotation.time)
        && (!lastPlayedAnnotation || annotation.time !== lastPlayedAnnotation.time)) {
      this.lastPlayedAnnotation = annotation;
      this.handleAnnotationClick(annotation);
    }
  };

  simulateClickAnnotation(annotation) {
    if (annotation) {
      const annotationButton = this[`annotationButton${annotation.time}`];
      if (annotationButton) {
        annotationButton.click();
      }
    }
  }

  updateScrubberToTime(time) {
    if (!this.scrubberRef) {
      return;
    }

    this.time = time;
    const factor = time / this.player.duration();
    let x = Math.floor(factor * this.fullScrubWidth);
    if (x < 0) x = 0;
    else if (x > this.fullScrubWidth) x = this.fullScrubWidth;
    this.scrubberRef.style.transform = `translateX(${x}px)`;

    const annotation = this.getAnnotationAt(time);
    const annotationType = getAnnotationType((annotation || {}).type);
    const hasAudio = checkAnnotationHasAudio(annotation);

    let disabled = !!annotation;
    let audioSupported = false;

    if (AUDIO_SUPPORTED_TYPES.includes(annotationType)) {
      disabled = hasAudio;
      audioSupported = !hasAudio;
    }

    if (audioSupported && !this.scrubberRef.classList.contains('audioSupported')) {
      this.scrubberRef.classList.add('audioSupported');
    }
    if (!audioSupported && this.scrubberRef.classList.contains('audioSupported')) {
      this.scrubberRef.classList.remove('audioSupported');
    }
    if (disabled && !this.scrubberRef.classList.contains('disabled')) {
      this.scrubberRef.classList.add('disabled');
    }
    if (!disabled && this.scrubberRef.classList.contains('disabled')) {
      this.scrubberRef.classList.remove('disabled');
    }
  }

  dispatchChange = () => {
    const { onChange } = this.props;
    const { annotations } = this.state;
    if (onChange) {
      onChange({ annotations });
    }
  };

  resetFileInputs() {
    if (this.scrubberVideoFileInputRef) {
      this.scrubberVideoFileInputRef.value = '';
    }
    if (this.scrubberAudioFileInputRef) {
      this.scrubberAudioFileInputRef.value = '';
    }
    if (this.scrubberImageFileInputRef) {
      this.scrubberImageFileInputRef.value = '';
    }

    this.updateScrubberToTime(this.currentScrubTime);
  }

  handleWindowResize = () => {
    if (this.fullScrubRef) {
      this.fullScrubWidth = this.fullScrubRef.offsetWidth;
      this.fullScrubHeight = this.fullScrubRef.offsetHeight;
      this.setState({
        fullScrubWidth: this.fullScrubWidth,
        fullScrubHeight: this.fullScrubHeight,
      });
    }
  };

  handleFullScrubTouchStart = (event) => {
    event.nativeEvent.preventDefault();
    this.handleFullScrubMouseDown(event);
  }

  handleFullScrubTouchMove = (event) => {
    event.nativeEvent.preventDefault();
    this.handleFullScrubMouseMove(event);
  }

  handleFullScrubTouchEnd = (event) => {
    event.nativeEvent.preventDefault();
    this.handleFullScrubMouseUp(event);
  }

  handleFullScrubMouseDown = (event) => {
    this.isMouseDown = true;
    this.isMouseMove = false;
    this.mx0 =
      event.clientX ||
      (event.changedTouches && event.changedTouches[0].pageX);
    this.my0 =
      event.clientY ||
      (event.changedTouches && event.changedTouches[0].pageY);
    this.isPlayerPaused = this.player.paused();
  };

  handleFullScrubMouseMove = (event) => {
    if (this.isMouseDown) {
      this.player.pause();
      this.lastPlayedAnnotation = null;
      let x =
        event.clientX ||
        (event.changedTouches && event.changedTouches[0].pageX);
      let y =
        event.clientY ||
        (event.changedTouches && event.changedTouches[0].pageY);
      if (!this.isMouseMove) {
        const dx = this.mx0 - x;
        const dy = this.my0 - y;
        const diff = dx*dx + dy*dy;
        if (diff > PRESS_MOVE_CIRC) {
          this.isMouseMove = true;
        }
      }
      const factor = x / this.fullScrubWidth;
      this.currentScrubTime = factor * this.player.duration();
      this.updateScrubberToTime(this.currentScrubTime);

      const duration = this.player.duration();
      this.player.currentTime(duration * factor);
    }
  };

  handleFullScrubMouseUp = () => {
    this.isMouseDown = false;

    if (!this.isMouseMove) {
      if (this.isPlayerPaused) this.player.play();
      else this.player.pause();
    }
    if (this.scrubberRef.classList.contains('visible')) {
      this.scrubberRef.classList.remove('visible');
    }
  };

  handleAnnotationViewerMouseDown = (event) => {
    this.isAnnotationVideoMouseDown = true;
    this.annotationVideoX0 = this.annotationVideoX;
    this.annotationVideoY0 = this.annotationVideoY;
    this.annotationVideoWidth = this.annotationViewerRef.offsetWidth;
    this.annotationVideoHeight = this.annotationViewerRef.offsetHeight;
    this.annotationVideoMX0 =
      event.clientX ||
      (event.changedTouches && event.changedTouches[0].pageX);
    this.annotationVideoMY0 =
      event.clientY ||
      (event.changedTouches && event.changedTouches[0].pageY);

    document.removeEventListener('mousemove', this.handleAnnotationViewerMouseMove);
    document.removeEventListener('touchmove', this.handleAnnotationViewerMouseMove);
    document.addEventListener('mousemove', this.handleAnnotationViewerMouseMove, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchmove', this.handleAnnotationViewerMouseMove, hasQuiet() ? { passive: false } : false);
  };

  handleAnnotationViewerMouseMove = (event) => {
    if (this.isAnnotationVideoMouseDown) {
      this.annotationVideoMX =
        event.clientX ||
        (event.changedTouches && event.changedTouches[0].pageX);
      this.annotationVideoMY =
        event.clientY ||
        (event.changedTouches && event.changedTouches[0].pageY);
      
      const dx = this.annotationVideoMX - this.annotationVideoMX0;
      const dy = this.annotationVideoMY - this.annotationVideoMY0;
      this.annotationVideoX = this.annotationVideoX0 + dx;
      this.annotationVideoY = this.annotationVideoY0 + dy;
      if (this.annotationVideoX < 0) this.annotationVideoX = 0;
      if (this.annotationVideoX > this.fullScrubWidth - this.annotationVideoWidth) this.annotationVideoX = this.fullScrubWidth - this.annotationVideoWidth;
      if (this.annotationVideoY < 0) this.annotationVideoY = 0;
      if (this.annotationVideoY > this.fullScrubHeight - this.annotationVideoHeight) this.annotationVideoY = this.fullScrubHeight - this.annotationVideoHeight;
      this.annotationViewerRef.style.transform = `translate(${this.annotationVideoX}px, ${this.annotationVideoY}px)`;
    }
  };

  handleAnnotationViewerMouseUp = () => {
    document.removeEventListener('mousemove', this.handleAnnotationViewerMouseMove);
    document.removeEventListener('touchmove', this.handleAnnotationViewerMouseMove);
    this.isAnnotationVideoMouseDown = false;
  };

  handleAnnotateClick = () => {
    if (this.player) {
      this.player.pause();
    }
  };

  handleAnnotateTextClick = () => {
    this.handleAnnotateClick();
    this.setState({ isAnnotationTextInputVisible: true, isDrawing: false });
  };

  handleAnnotateDrawingClick = () => {
    this.handleAnnotateClick();
    this.setState({ isDrawing: true, isAnnotationTextInputVisible: false });
  };

  handleAnnotationTextInputChange = (event) => {
    this.setState({ annotationTextInputValue: event.target.value });
  };

  handleAnnotationTextInputCancel = () => {
    this.setState({
      isAnnotationTextInputVisible: false,
      annotationTextInputValue: '',
    });
  };

  handleAnnotationTextInputSave = () => {
    this.setState(state => {
      const { annotations, annotationTextInputValue } = state;
      if (!annotations.find(a => a.time === this.currentScrubTime)) {
        annotations.push({
          id: shortid.generate(), 
          time: this.currentScrubTime,
          type: TYPE_TEXT,
          message: annotationTextInputValue,
        });
      }
      return {
        annotations: annotations.sort(sortByTime),
        isAnnotationTextInputVisible: false,
        annotationTextInputValue: '',
      };
    }, () => {
      this.dispatchChange();
      this.resetFileInputs();
    });
  };

  handleAnnotateVideoInputChange = (event) => {
    const { files } = event.target;
    if (!isEmpty(files)) {
      const videoFile = files[0];
      const videoUrl = URL.createObjectURL(videoFile);
      
      getVideoMetadata(videoFile)
        .then(({ width, height, duration }) => {
          const { size, type } = videoFile;
          this.setState(state => {
            const { annotations } = state;
            if (!annotations.find(a => a.time === this.currentScrubTime)) {
              annotations.push({
                id: shortid.generate(), 
                time: this.currentScrubTime,
                size,
                type,
                width,
                height,
                duration,
                file: files[0],
                src: videoUrl,
              });
            }
            return { annotations: annotations.sort(sortByTime) };
          }, () => {
            this.dispatchChange();
            this.resetFileInputs();
          });
        });
    }
  };

  handleAnnotateAudioClick = () => {
    this.handleAnnotateClick();
    this.setState({ isRecordingAudio: true, isAnnotationTextInputVisible: false });
  };

  handleAnnotateAudioInputChange = (event) => {
    const { files } = event.target;
    if (!isEmpty(files)) {
      const audioFile = files[0];
      const audioUrl = URL.createObjectURL(audioFile);
      const currentAnnotation = this.getAnnotationAt(this.currentScrubTime);
      const currentAnnotationType = getAnnotationType((currentAnnotation || {}).type);
      
      if (!isNil(currentAnnotationType) && AUDIO_SUPPORTED_TYPES.includes(currentAnnotationType)) {
        getAudioMetadata(audioFile)
          .then(({ duration }) => {
            const { size, type } = audioFile;
            this.setState(state => {
              const { annotations } = state;
              const annotationToUpdate = annotations.find(a => a.id === currentAnnotation.id);
              if (!isNil(annotationToUpdate)) {
                annotationToUpdate.audio = {
                  size,
                  type,
                  duration,
                  src: audioUrl,
                  file: files[0],
                };
              }
              return { annotations: annotations.sort(sortByTime) };
            }, () => {
              this.dispatchChange();
              this.resetFileInputs();
            });
          });
      }
    }
  };

  handleAnnotateImageInputChange = (event) => {
    const { files } = event.target;
    if (!isEmpty(files)) {
      const rawImageFile = files[0];
      
      cropAndResizeImage(rawImageFile, 1280)
        .then(imageFile => {
          return getImageMetadata(imageFile).then(({ width, height }) => ({ imageFile, width, height }));
        })
        .then(({ imageFile, width, height }) => {
          const imageUrl = URL.createObjectURL(imageFile);
          const { size, type } = imageFile;
          this.setState(state => {
            const { annotations } = state;
            if (!annotations.find(a => a.time === this.currentScrubTime)) {
              annotations.push({
                id: shortid.generate(), 
                time: this.currentScrubTime,
                size,
                type,
                width,
                height,
                file: imageFile,
                src: imageUrl,
              });
            }
            return { annotations: annotations.sort(sortByTime) };
          }, () => {
            this.dispatchChange();
            this.resetFileInputs();
          });
        });
    }
  };

  handleSkipAnnotation = () => {
    this.player.play();
    this.handleVideoPlayerPlay();
  }

  handleAnnotationVideoPlayerReady = (player) => {
    this.annotationPlayer = player;
    setTimeout(() => {
      const selectedAnnotationVideoRatio = player.videoHeight() / player.videoWidth();
      this.setState({ selectedAnnotationVideoRatio });
      try {
        this.annotationPlayer.play().catch(() => {});
      } catch (e) {}
    }, 100);
  };

  handleAnnotationVideoPlayerEnd = () => {
    this.handleAnnotationViewerClose();
  };

  handleAnnotationViewerClose = () => {
    this.setState({ selectedAnnotation: null, annotationToPlay: null }, () => {
      if (this.player && this.state.isPlayerPlaying) {
        this.player.play();
      }
    });
  };

  handleAnnotationClick = (annotationToPlay, immediate) => {
    const isPlayerPlaying = this.player && !this.player.paused();
    this.player && this.player.pause();
    this.setState({ isPlayerPlaying, annotationToPlay }, () => immediate && this.handleViewAnnotation());
  };

  handleAnnotationTouchStart = (event, annotation) => {
    event.preventDefault();
    this.handleAnnotationMouseDown(event, annotation);
  };

  handleAnnotationTouchEnd = (event, annotation) => {
    event.preventDefault();
    this.handleAnnotationMouseUp(event, annotation);
  };

  handleAnnotationMouseDown = (event, annotation) => {
    if (isNil(annotation)) {
      return;
    }
    this.pressedAnnotationTime = Date.now();
    this.pressedAnnotation = annotation;
    this.pressedAnnotationRef = event.target;
    this.deleteArea = this.pressedAnnotationRef.parentNode.querySelector('.deleteArea');
    
    const deleteAreaRect = this.deleteArea.getBoundingClientRect();
    const pressedAnnotationRect = this.pressedAnnotationRef.getBoundingClientRect();
    this.deleteAreaDistance = ((deleteAreaRect.bottom + deleteAreaRect.top) * 0.5) - ((pressedAnnotationRect.bottom + pressedAnnotationRect.top) * 0.5);
    this.videoDuration = this.player.duration();
    this.isAnnotationMouseDown = true;
    this.deleteAnnotation = false;
    this.annotationWidth = this.pressedAnnotationRef.offsetWidth;
    this.annotationHeight = this.pressedAnnotationRef.offsetHeight;
    this.annotationMX0 =
      event.clientX ||
      (event.changedTouches && event.changedTouches[0].pageX);
    this.annotationMY0 =
      event.clientY ||
      (event.changedTouches && event.changedTouches[0].pageY);

    setTimeout(() => {
      if (this.isAnnotationMouseDown) {
        document.removeEventListener('mousemove', this.handleAnnotationMouseMove);
        document.removeEventListener('touchmove', this.handleAnnotationMouseMove);
        document.addEventListener('mousemove', this.handleAnnotationMouseMove, hasQuiet() ? { passive: false } : false);
        document.addEventListener('touchmove', this.handleAnnotationMouseMove, hasQuiet() ? { passive: false } : false);
        this.setState({ pressedAnnotation: annotation });
      }
    }, PRESS_HOLD_DURATION);
  };

  handleAnnotationMouseMove = (event) => {
    if (this.isAnnotationMouseDown) {
      this.annotationMX =
        event.clientX ||
        (event.changedTouches && event.changedTouches[0].pageX);
      this.annotationMY =
        event.clientY ||
        (event.changedTouches && event.changedTouches[0].pageY);
      
      const dx = this.annotationMX - this.annotationMX0;
      const dy = this.annotationMY - this.annotationMY0;
      this.annotationX = timeToPixel(this.pressedAnnotation.time, this.videoDuration, this.fullScrubWidth) + dx;
      this.annotationY = dy;

      if (this.annotationX < 0) this.annotationX = 0;
      if (this.annotationX > this.fullScrubWidth - this.annotationWidth) this.annotationX = this.fullScrubWidth - this.annotationWidth;
      if (this.annotationY > 0) this.annotationY = 0;
      if (this.annotationY < this.deleteAreaDistance) this.annotationY = this.deleteAreaDistance;

      let annotationY = this.annotationY; 
      let annotationScale = 1;
      let annotationOpacity = 1;
      if (annotationY < this.deleteAreaDistance * 0.7) {
        annotationY = this.deleteAreaDistance;
        annotationScale = 0.75;
        annotationOpacity = 0.5;
        this.deleteAnnotation = true;
      } else {
        this.deleteAnnotation = false;
      }
      this.pressedAnnotationRef.parentNode.style.transform = `translate(${this.annotationX}px, -20px)`;
      this.pressedAnnotationRef.style.transform = `translateY(${annotationY}px) scale(${annotationScale})`;
      this.pressedAnnotationRef.style.opacity = annotationOpacity;
      
      const deleteAreaScale = 1 + (this.annotationY / this.deleteAreaDistance) * 0.5;
      this.deleteArea.style.transform = `scale(${deleteAreaScale})`;
    }
  };

  handleAnnotationMouseUp = (event, annotation) => {
    document.removeEventListener('mousemove', this.handleAnnotationMouseMove);
    document.removeEventListener('touchmove', this.handleAnnotationMouseMove);
    this.isAnnotationMouseDown = false;
    this.setState({ pressedAnnotation: null });

    if (Date.now() - this.pressedAnnotationTime < PRESS_CLICK_DURATION) {
      // it's a click
      this.handleAnnotationClick(annotation, true);
    } else {
      if (this.deleteAnnotation) {
        this.handleAnnotationDelete(annotation);
      } else {
        this.setState(state => {
          const { annotations } = state;
          const selectedAnnotation = annotations.find(a => a.time === annotation.time);
          if (selectedAnnotation) {
            selectedAnnotation.time = pixelToTime(this.annotationX, this.videoDuration, this.fullScrubWidth);
          }
          return { annotations };
        });
      }
    }

    this.deleteAnnotation = false;
  };

  handleAnnotationDelete = (annotationToDelete) => {
    if (!isNil(annotationToDelete)) {
      this.setState(state => {
        const { annotations } = this.state;
        return { annotations: annotations.filter(a => a.time !== annotationToDelete.time) };
      });
    }
  };

  handleViewAnnotation = () => {
    this.setState(state => ({ selectedAnnotation: state.annotationToPlay, annotationToPlay: null }));
  };

  handleVideoInputChange = (event) => {
    const { files } = event.target;
    if (!isEmpty(files)) {
      const videoUrl = URL.createObjectURL(files[0]);
      this.setState({ videoUrl });
    }
  };

  handleVideoPlayerReady = (player, videoRef) => {
    this.player = player;
    this.videoRef = videoRef;
    this.setState({ player });
  };

  handleVideoPlayerPlay = () => {
    this.updateScrubber();
    this.handleAnnotationViewerClose();
  };

  handleDrawingScreenClose = () => {
    this.setState({ isDrawing: false });
  };

  handleDrawingScreenSave = ({ data, width, height, dataUrl }) => {
    this.setState(state => {
      const { annotations } = state;
      if (!annotations.find(a => a.time === this.currentScrubTime)) {
        annotations.push({
          id: shortid.generate(), 
          time: this.currentScrubTime,
          type: TYPE_DRAWING,
          width,
          height,
          data,
          file: dataURLtoBlob(dataUrl),
        });
      }
      return {
        annotations: annotations.sort(sortByTime),
        isDrawing: false,
      };
    }, () => {
      this.dispatchChange();
      this.resetFileInputs();
    });
  };

  handleAudioRecorderScreenClose = () => {
    this.setState({ isRecordingAudio: false });
  };

  handleAudioRecorderScreenSave = data => {
    this.handleAnnotateAudioInputChange({
      target: {
        files: [data],
      },
    });
    this.setState({ isRecordingAudio: false });
  };

  render() {
    const {
      annotationVideoWidthPercent,
      annotationVideoHeightPercent,
      isVideoPortrait,
      globalState,
    } = this.props;
    const {
      videoUrl,
      annotations,
      annotationToPlay,
      selectedAnnotation,
      pressedAnnotation,
      fullScrubWidth,
      isAnnotationTextInputVisible,
      annotationTextInputValue,
      isDrawing,
      isRecordingAudio,
    } = this.state;

    const config = globalState.getConfig();
    const time = formatTime(this.time || 0);

    let duration = this.props.duration || 0;
    if (this.player) {
      duration = this.player.duration();
    }

    const selectedAnnotationType = getAnnotationType((selectedAnnotation || {}).type);
    const annotationToPlayType = getAnnotationType((annotationToPlay || {}).type);
    const isAnnotationPortrait = checkAnnotationIsPortrait(selectedAnnotation);
    const annotationViewerWidthRatio = isAnnotationPortrait ? 9/16 : 1;
    const annotationViewerHeightRatio = isAnnotationPortrait ? 1 : 9/16;
    const hasTextInputValue = !!(annotationTextInputValue || '').trim();
    
    let annotationViewerWidth; 
    let annotationViewerHeight;
    if (isVideoPortrait) {
      annotationViewerWidth = `${annotationVideoHeightPercent * annotationViewerWidthRatio}vh`;
      annotationViewerHeight = `${annotationVideoHeightPercent * annotationViewerHeightRatio}vh`;
    } else {
      annotationViewerWidth = `${annotationVideoWidthPercent * annotationViewerWidthRatio}vw`;
      annotationViewerHeight = `${annotationVideoWidthPercent * annotationViewerHeightRatio}vw`;
    }

    return (
      <div className={cx('VideoEditor', { editing: !isNil(videoUrl), drawing: isDrawing })}>
        <div className="videoInput">
          {isNil(videoUrl) && (
            <React.Fragment>
              <input
                type="file"
                accept="video/mp4,video/x-m4v,video/*"
                onChange={this.handleVideoInputChange}
              />
              <p><Trans>Tap to select a video</Trans></p>
            </React.Fragment>
          )}
        </div>
        {!isNil(videoUrl) && (
          <VideoPlayer
            className="videoPlayer"
            type="mp4"
            controls
            src={videoUrl}
            onReady={this.handleVideoPlayerReady}
            onPlay={this.handleVideoPlayerPlay}
          />
        )}
        <div ref={(ref) => (this.fullScrubRef = ref)} className="fullScrub">
          <div
            className="blocker"
            onTouchStart={this.handleFullScrubTouchStart}
            onTouchMove={this.handleFullScrubTouchMove}
            onTouchEnd={this.handleFullScrubTouchEnd}
            onTouchCancel={this.handleFullScrubTouchEnd}
            onMouseDown={this.handleFullScrubMouseDown}
            onMouseMove={this.handleFullScrubMouseMove}
            onMouseUp={this.handleFullScrubMouseUp}
          />
          <div className="scrubber" ref={(ref) => (this.scrubberRef = ref)}>
            <div className="annotateButton annotateTextButton">
              <button
                type="button"
                onClick={this.handleAnnotateTextClick}
              />
              <i className="fas fa-comment-alt-plus" />
            </div>
            <div className="annotateButton annotateDrawingButton">
              <button
                type="button"
                onClick={this.handleAnnotateDrawingClick}
              />
              <i className="fas fa-paint-brush-alt" />
            </div>
            <div className="annotateButton annotateVideoButton">
              <input
                ref={ref => this.scrubberVideoFileInputRef = ref}
                type="file"
                accept="video/mp4,video/x-m4v,video/*"
                onClick={this.handleAnnotateClick}
                onChange={this.handleAnnotateVideoInputChange}
              />
              <i className="fas fa-video-plus" />
            </div>
            <div className="annotateButton annotateImageButton">
              <input
                ref={ref => this.scrubberImageFileInputRef = ref}
                type="file"
                accept="image/*"
                onClick={this.handleAnnotateClick}
                onChange={this.handleAnnotateImageInputChange}
              />
              <i className="fas fa-image" />
            </div>
            <div className="annotateButton annotateAudioButton">
              {/* <input
                ref={ref => this.scrubberAudioFileInputRef = ref}
                type="file"
                accept="audio/ogg,audio/webm,audio/aac,audio/wav,audio/mp3"
                onClick={this.handleAnnotateClick}
                onChange={this.handleAnnotateAudioInputChange}
              /> */}
              <button
                type="button"
                onClick={this.handleAnnotateAudioClick}
              />
              <i className="fas fa-microphone-alt" />
            </div>
          </div>
        </div>
        {selectedAnnotation && selectedAnnotationType === TYPE_DRAWING && (
          <div className="drawingAnnotationViewer" onClick={this.handleAnnotationViewerClose}>
            <CanvasDraw
              disabled
              hideGrid
              brushColor="rgba(0, 0, 0, 0)"
              catenaryColor="rgba(0, 0, 0, 0)"
              canvasWidth={selectedAnnotation.width}
              canvasHeight={selectedAnnotation.height}
              saveData={selectedAnnotation.data}
            />
            {!isNil(selectedAnnotation.audio) && !isNil(selectedAnnotation.audio.src) && (
              <ReactAudioPlayer
                src={selectedAnnotation.audio.src}
                autoPlay
                controls={false}
              />
            )}
          </div>
        )}
        <div className="annotations">
          {annotations.map((annotation) => {
            const { time } = annotation;
            const type = getAnnotationType(annotation.type);
            const hasAudio = checkAnnotationHasAudio(annotation);
            return (
              <div
                ref={ref => this[`annotationButton${time}`] = ref}
                key={time}
                className={cx('annotation', {
                  selected: !isNil(selectedAnnotation) && selectedAnnotation.time === time,
                  pressed: !isNil(pressedAnnotation) && pressedAnnotation.time === time,
                  hasAudio,
                })}
                style={{
                  transform: `translate(${timeToPixel(time, duration, fullScrubWidth)}px, -20px)`,
                }}
              >
                <button type="button" className="playButton"
                  onTouchStart={(event) => this.handleAnnotationTouchStart(event, annotation)}
                  onTouchEnd={(event) => this.handleAnnotationTouchEnd(event, annotation)}
                  onTouchCancel={(event) => this.handleAnnotationTouchEnd(event, annotation)}
                  onMouseDown={(event) => this.handleAnnotationMouseDown(event, annotation)}
                  onMouseUp={(event) => this.handleAnnotationMouseUp(event, annotation)}
                >
                  {hasAudio && <i className="fas fa-microphone-alt" />}
                  <i className={cx('fas', {
                    'fa-video': type === TYPE_VIDEO,
                    'fa-image': type === TYPE_IMAGE,
                    'fa-comment-alt': type === TYPE_TEXT,
                    'fa-paint-brush-alt': type === TYPE_DRAWING,
                   })} />
                </button>
                <div className="deleteArea">
                  <i className="fas fa-trash-alt" />
                </div>
              </div>
            );
          })}
        </div>
        <div
          ref={ref => this.annotationViewerRef = ref}
          className={cx('annotationViewer', { visible: !isNil(selectedAnnotation) && selectedAnnotationType !== TYPE_DRAWING })}
          style={{
            width: annotationViewerWidth,
            height: annotationViewerHeight,
          }}
          onTouchStart={this.handleAnnotationViewerMouseDown}
          onTouchEnd={this.handleAnnotationViewerMouseUp}
          onTouchCancel={this.handleAnnotationViewerMouseUp}
          onMouseDown={this.handleAnnotationViewerMouseDown}
          onMouseUp={this.handleAnnotationViewerMouseUp}
        >
          <div className="closeButton" onClick={this.handleAnnotationViewerClose}><i className="fal fa-times-circle" /></div>
          {selectedAnnotation && selectedAnnotationType === TYPE_VIDEO && (
            <VideoPlayer
              key={selectedAnnotation.src}
              className="videoPlayer"
              type="mp4"
              controls
              src={selectedAnnotation.src}
              onReady={this.handleAnnotationVideoPlayerReady}
              onEnd={this.handleAnnotationVideoPlayerEnd}
            />
          )}
          {selectedAnnotation && selectedAnnotationType === TYPE_IMAGE && (
            <React.Fragment>
              <div className="imageViewer" style={{
                backgroundImage: `url(${selectedAnnotation.src})`,
              }} />
              {!isNil(selectedAnnotation.audio) && !isNil(selectedAnnotation.audio.src) && (
                <ReactAudioPlayer
                  src={selectedAnnotation.audio.src}
                  autoPlay
                  controls={false}
                />
              )}
            </React.Fragment>
          )}
          {selectedAnnotation && selectedAnnotationType === TYPE_TEXT && (
            <div className="textViewer">
              <p>{selectedAnnotation.message}</p>
            </div>
          )}
        </div>
        <div className={cx('annotationToPlay', { visible: !isNil(annotationToPlay) })}>
          <div className="dialog">
            <h2>
              {annotationToPlayType === TYPE_VIDEO && <Trans>There is a video message here!</Trans>}
              {annotationToPlayType === TYPE_IMAGE && <Trans>There is a image message here!</Trans>}
              {annotationToPlayType === TYPE_TEXT && <Trans>There is a text message here!</Trans>}
              {annotationToPlayType === TYPE_DRAWING && <Trans>There is a drawing message here!</Trans>}
            </h2>
            <p><Trans>Would you like to view it?</Trans></p>
            <div className="buttons">
              <button type="button" className="playAnnotationButton" onClick={this.handleViewAnnotation}>
                <i className="fas fa-play" />
                <span className="text"><Trans>Play</Trans></span>
              </button>
              <button type="button" className="skipAnnotationButton" onClick={this.handleSkipAnnotation}>
                <i className="fas fa-step-forward" />
                <span className="text"><Trans>Skip</Trans></span>
              </button>
            </div>
          </div>
        </div>
        <div className={cx('annotationTextInput', { visible: isAnnotationTextInputVisible })}>
          <div className="annotationTextInputContainer">
            <div className="inputHeading"><Trans time={time}>Text Message for {{time}}</Trans></div>
            <textarea onChange={this.handleAnnotationTextInputChange} value={annotationTextInputValue} />
            <div className="actions">
              <button 
                className="btn btn-icon-only btn-default"
                onClick={this.handleAnnotationTextInputCancel}
              >
                <i className="fal fa-times" />
              </button>
              <button 
                className={cx('btn btn-icon-only', {
                  'btn-primary': hasTextInputValue,
                  'btn-default': !hasTextInputValue,
                })}
                disabled={!hasTextInputValue}
                onClick={this.handleAnnotationTextInputSave}
              >
                <i className="fal fa-check" />
              </button>
            </div>
          </div>
        </div>
        {isDrawing && (
          <DrawingScreen
            naturalWidth={this.player && this.player.videoWidth()}
            naturalHeight={this.player && this.player.videoHeight()}
            onClose={this.handleDrawingScreenClose}
            onSave={this.handleDrawingScreenSave}
          />
        )}
        <AudioRecorderScreen
          visible={isRecordingAudio}
          oscilloscope={config.oscilloscope}
          onClose={this.handleAudioRecorderScreenClose}
          onSave={this.handleAudioRecorderScreenSave}
        />
      </div>
    );
  }
}

export default withTranslation()(withGlobalState(VideoEditor));
