import React from 'react';
import videojs from 'video.js';
import cx from 'classnames';

import 'video.js/dist/video-js.css';
import './style.scss';

import VideoThumbnail from '../VideoThumbnail';

export default class VideoPlayer extends React.Component {
  static defaultProps = {
    type: null,
    generateThumbnails: false,
    bigPlayButton: false,
    playsinline: true,
    controls: true,
    annotationVideoWidthPercent: 25,
    disablePictureInPicture: true,
  };

  state = {
    thumbnails: [],
    annotations: [],
    isPlayedOnce: false,
  };

  constructor(props) {
    super(props);

    this.annotationVideoX = 10;
    this.annotationVideoY = 10;
  }

  componentDidMount() {
    this.initPlayer();
  }

  componentWillUnmount() {
    this.destroyPlayer();
  }

  componentDidUpdate(prevProps) {
    const prevSrc = prevProps.src || '';
    const src = this.props.src || '';

    if (prevSrc !== src) {
      this.initPlayer();
    }

    const { visible } = this.props;
    if (prevProps.visible !== visible) {
      window.enableTouchMove = visible;
    }
  }

  initPlayer() {
    const {
      onReady,
      onPlay,
      onPause,
      onTimeUpdate,
      onSeeking,
      onSeeked,
      onEnd,
    } = this.props;

    this.destroyPlayer();

    let currentTime = 0;
    let previousTime = 0;
    let position = 0;

    this.player = videojs(this.videoRef, {
      ...this.props,
      controlBar: {
        ...(this.props.controlBar || {}),
        liveDisplay: true,
        pictureInPictureToggle: false,
      },
    });

    this.player.ready(() => {
      onReady && onReady(this.player, this.videoRef);
    });

    this.player.on('progress', () => {
      const percent = this.player.bufferedPercent();
      this.setState({
        isLoading: percent > 0 && percent < 1,
      });
    });

    this.player.on('play', () => {
      this.setState({ isPlayedOnce: true });
      onPlay && onPlay(this.player.currentTime());
    });

    this.player.on('pause', () => {
      onPause && onPause(this.player.currentTime());
    });

    this.player.on('timeupdate', () => {
      onTimeUpdate && onTimeUpdate(this.player.currentTime());
      previousTime = currentTime;
      currentTime = this.player.currentTime();
      if (previousTime < currentTime) {
        position = previousTime;
        previousTime = currentTime;
      }
    });

    this.player.on('seeking', () => {
      this.player.off('timeupdate', () => {});
      this.player.one('seeked', () => {});
      onSeeking && onSeeking(this.player.currentTime());
    });

    this.player.on('seeked', () => {
      let completeTime = Math.floor(this.player.currentTime());
      onSeeked && onSeeked(position, completeTime);
    });

    this.player.on('ended', () => {
      onEnd && onEnd();
    });
  }

  destroyPlayer() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {
    const { src, type, className } = this.props;
    const { isLoading, isPlayedOnce } = this.state;

    return (
      <div className={cx('VideoPlayer', className, { isLoading })}>
        <video
          ref={(ref) => (this.videoRef = ref)}
          className="video-js"
          preload="auto"
          data-setup="{}"
          disablePictureInPicture
        >
          <source src={src} type={type && `video/${type}`} />
          <p className="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading
            to a web browser that
            <a
              href="https://videojs.com/html5-video-support/"
              rel="noopener noreferrer"
              target="_blank"
            >
              supports HTML5 video
            </a>
          </p>
        </video>
        {!isPlayedOnce && <VideoThumbnail autoGenerate src={src} />}
      </div>
    );
  }
}
