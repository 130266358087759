import React from 'react';
import cx from 'classnames';
import { isNil } from 'lodash';
import debounce from 'debounce';
import { withTranslation, Trans } from 'react-i18next';

import './style.scss';

import { withApi } from '../../services/Api';
import { joinNames } from '../../utils';
import AvatarIcon from '../AvatarIcon';
import LoadingIndicator from '../LoadingIndicator';

class FriendRequestScreen extends React.Component {
  state = {
    code: '',
    foundUser: null,
    lastFoundUser: null,
    isSendingFriendRequest: false,
  };

  constructor(props) {
    super(props);

    this.debouncedGetUserByCode = debounce(this.getUserByCode, 200);
  }

  componentWillUnmount() {
    if (this.debouncedGetUserByCode && this.debouncedGetUserByCode.clear) {
      this.debouncedGetUserByCode.clear();
    }
  }

  componentDidUpdate(prevProps) {
    const { visible } = this.props;
    if (visible && prevProps.visible !== visible) {
      this.setState({ 
        code: '',
        foundUser: null,
        lastFoundUser: null,
      });
    }
  }

  getUserByCode = () => {
    const { api, currentUser } = this.props;
    const { code } = this.state;

    this.setState({ foundUser: null }, () => {
      api.user.getByCode(code, foundUser => {
        if (foundUser && foundUser.id !== currentUser.id) {
          this.setState({ foundUser, lastFoundUser: foundUser });
        } else {
          this.setState({ foundUser: null });
        }
      });
    });
  };

  handleCodeInputChange = event => {
    const code = (event.target.value || '').trim();
    this.setState({ code }, () => {
      if (code.length >= 7) {
        this.debouncedGetUserByCode();
      }
    });
  };

  handleSend = () => {
    const { api, currentUser, onClose } = this.props;
    const { foundUser } = this.state;

    if (foundUser) {
      this.setState({ isSendingFriendRequest: true }, () => {})
      api.user
        .addFriendRequest(currentUser.id, foundUser.id)
        .then(() => {
          this.setState({ isSendingFriendRequest: false }, () => {
            if (onClose) {
              onClose();
            }
          });
        })
        .catch(error => {
          // TODO
          this.setState({ isSendingFriendRequest: false });
          console.log(error);
        });
    }
  };

  render() {
    const { className, visible, currentUser, onClose } = this.props;
    const { code, foundUser, isSendingFriendRequest } = this.state;
    const lastFoundUser = this.state.lastFoundUser || {};
    const { firstName, lastName, description } = lastFoundUser;

    const name = joinNames(firstName, lastName);
    const isAlreadyAFriend = !isNil(foundUser) && ((currentUser || []).friends || []).includes(foundUser.id);
    const isAlreadyRequested = !isNil(foundUser) && ((currentUser || []).friendRequestsSent || []).includes(foundUser.id);

    return (
      <div className={cx('Screen FriendRequestScreen', className, { visible })}>
        <header>
          <nav>
            <ul>
              <li>
                <button type="button" className="btn btn-icon-only" onClick={onClose}>
                  <i className="fal fa-times icon-big color-primary" />
                  <div className="sr-only"><Trans>Close</Trans></div>
                </button>
              </li>
              <li><Trans>Friend Request</Trans></li>
              <li></li>
            </ul>
          </nav>
        </header>
        <section>
          <div className="wrapper">
            <form>
              <fieldset>
                <label htmlFor="code"><Trans>Enter Friend Code</Trans></label>
                <input type="text" name="code" className="form-control" onChange={this.handleCodeInputChange} value={code} />
              </fieldset>
            </form>
            <div className={cx('foundUser', { visible: !isNil(foundUser) })}>
              <AvatarIcon {...lastFoundUser} />
              <div className="name">
                {(name || '').trim() ? name.trim() : <em className="empty"><Trans>Unknown name</Trans></em>}
              </div>
              <div className="description">
                {(description || '').trim() ? description.trim() : <em className="empty"><Trans>No description yet.</Trans></em>}
              </div>
              {isAlreadyAFriend && (
                <button type="button" className="btn btn-default" disabled>
                  <i className="fal fa-check" />
                  <span className="text"><Trans>Already a friend</Trans></span>
                </button>
              )}
              {!isAlreadyAFriend && isAlreadyRequested && (
                <button type="button" className="btn btn-default" disabled>
                  <Trans>Request already sent</Trans>
                </button>
              )}
              {!isAlreadyAFriend && !isAlreadyRequested && !isSendingFriendRequest && (
                <button type="button" className="btn btn-primary sendFriendRequestButton" onClick={this.handleSend}>
                  <i className="fas fa-paper-plane" />
                  <span className="text"><Trans>Send friend request</Trans></span>
                </button>
              )}
              {!isAlreadyAFriend && !isAlreadyRequested && isSendingFriendRequest && (
                <button type="button" className="btn btn-primary sendFriendRequestButton">
                  <LoadingIndicator />
                </button>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withTranslation()(withApi(FriendRequestScreen));