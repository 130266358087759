import React from 'react';
import cx from 'classnames';
import { isNil, isEmpty } from 'lodash';
import { withTranslation, Trans } from 'react-i18next';

import './style.scss';

import { formatDate } from '../../utils';
import { getIsAttentionNeeded } from '../../utils/video';
import AvatarIcon from '../AvatarIcon';
import SwipeOptionWrapper from '../SwipeOptionWrapper';

class VideoList extends React.Component {
  static defaultProps = {
    showSenderOrRecipient: 'recipient',
  };

  constructor(props) {
    super(props);

    this.itemSwipeOptions = [
      {
        label: <Trans>Delete</Trans>,
        className: 'btn-danger',
        onClick: this.handleItemDelete,
      }
    ];
  }
  
  handleItemClick = (item) => {
    const { onItemClick } = this.props;
    if (onItemClick) {
      onItemClick(item);
    }
  };
  
  handleItemDelete = (item) => {
    const { onItemDelete } = this.props;
    if (onItemDelete) {
      onItemDelete(item);
    }
  };

  render() {
    const {
      t,
      className,
      data,
      currentUser,
    } = this.props;

    return (
      <div className={cx('VideoList', className)}>
        {!isEmpty(data) && !isNil(currentUser) && !isNil(currentUser.id) && (
          <ul>
            {data.map((item) => {
              let createdAt, sentAt, seenAt, repliedAt;
              let showSenderOrRecipient = this.props.showSenderOrRecipient;
              if (isNil(showSenderOrRecipient)) {
                if (item.senderId === currentUser.id) {
                  showSenderOrRecipient = 'recipient';
                } else {
                  showSenderOrRecipient = 'sender';
                }
              }

              const isAttentionNeeded = getIsAttentionNeeded(item, currentUser.id);
              
              createdAt = t(formatDate(item.createdAt));
              const name = item[showSenderOrRecipient].name || `${item[showSenderOrRecipient].firstName} ${item[showSenderOrRecipient].lastName}`;

              let dateInfo;

              if (!item.isSample) {
                dateInfo = <Trans createdAt={createdAt} name={name}>Created {{createdAt}} by {{name}}</Trans>;

                if (showSenderOrRecipient === 'sender') {
                  if (!isNil(item.sentAt)) {
                    sentAt = t(formatDate(item.sentAt));
                    dateInfo = <Trans sentAt={sentAt} name={name}>Sent {{sentAt}} by {{name}}</Trans>;
                  }
                  if (!isNil(item.repliedAt)) {
                    repliedAt = t(formatDate(item.repliedAt));
                    dateInfo = (
                      <React.Fragment>
                        <i className="fas fa-check-circle color-primary" />
                        {' '}
                        <Trans repliedAt={repliedAt} name={name}>Replied {{repliedAt}} to {{name}}</Trans>
                      </React.Fragment>
                    );
                  }
                } else {
                  if (!isNil(item.sentAt)) {
                    sentAt = t(formatDate(item.sentAt));
                    dateInfo = <Trans sentAt={sentAt} name={name}>Sent {{sentAt}} to {{name}}</Trans>;
                  }
                  if (!isNil(item.seenAt)) {
                    seenAt = t(formatDate(item.seenAt));
                    dateInfo = <Trans seenAt={seenAt} name={name}>Seen {{seenAt}} by {{name}}</Trans>;
                  }
                  if (!isNil(item.repliedAt)) {
                    repliedAt = t(formatDate(item.repliedAt));
                    dateInfo = (
                      <React.Fragment>
                        <i className="fas fa-check-circle color-primary" />
                        {' '}
                        <Trans repliedAt={repliedAt} name={name}>Replied {{repliedAt}} by {{name}}</Trans>
                      </React.Fragment>
                    );
                  }
                }
              }

              return (
                <li 
                  key={item.id}
                  className={cx({ highlight: isAttentionNeeded })}
                >
                  <SwipeOptionWrapper
                    data={item}
                    disabled={item.senderId !== currentUser.id}
                    options={this.itemSwipeOptions}
                    onClick={this.handleItemClick}
                  >
                    <div className="avatar">
                      <AvatarIcon {...item[showSenderOrRecipient]} />
                      {!isNil(currentUser.companyId) && item.recipientId === currentUser.companyId && (
                        <AvatarIcon className="companyAvatar" {...item.recipient} />
                      )}
                    </div>
                    <div className="info">
                      <div className="title">
                        <i className={cx('statusIcon fas', {
                          'fa-arrow-to-bottom': item.recipientId === currentUser.id || (!isNil(currentUser.companyId) && item.recipientId === currentUser.companyId),
                          'fa-share': item.senderId === currentUser.id,
                        })} />
                        {item.title}
                      </div>
                      <div className="dates color-dimmed">{dateInfo}</div>
                    </div>
                    <div className="caret">
                      <i className={cx('fa-chevron-right color-primary', {
                        'fal': !isAttentionNeeded,
                        'fas': isAttentionNeeded,
                      })} />
                    </div>
                  </SwipeOptionWrapper>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}

export default withTranslation()(VideoList);
