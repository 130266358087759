import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import { isNil, isEmpty, cloneDeep } from 'lodash';
import shortid from 'shortid';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { withTranslation, Trans } from 'react-i18next';
import TextareaAutosize from 'react-autosize-textarea';
import Switch from 'rc-switch';

import './style.scss';

import { withApi, getCurrentUserMobile } from '../../services/Api';
import { joinNames, formatMobile } from '../../utils';
import { cropAndResizeImage } from '../../utils/media';
import AvatarIcon from '../AvatarIcon';
import UploadProgress from '../UploadProgress';

class ProfileScreen extends React.Component {
  state = {
    isEditing: false,
    uploadProgress: 0,
    videoFile: null,
  };

  componentWillUnmount() {
    if (this.resetCopiedTimeoutId) {
      clearTimeout(this.resetCopiedTimeoutId);
    }
  }

  handleEdit = () => {
    this.setState({
      isEditing: true,
      updatedData: cloneDeep(this.props.data),
    });
  };

  handleSave = () => {
    const { api } = this.props;
    const { photoFile: rawPhotoFile, updatedData } = this.state;

    if (!isNil(rawPhotoFile) && !rawPhotoFile.type) {
      cropAndResizeImage(rawPhotoFile).then((photoFile) => {
        this.setState({ uploadProgress: 0.01 }, () => {
          api.user
            .uploadPhoto(photoFile, updatedData.id, this.handleUploadProgress)
            .then((photoUrl) => {
              this.setState({ uploadProgress: 0.99 });
              const { isNew, ...data } = {
                ...updatedData,
                code: updatedData.code || shortid.generate(),
                photoUrl,
              };
              let promise;
              if (isNew) {
                promise = api.user.create(data, data.id);
              } else {
                promise = api.user.update({
                  ...data,
                  updatedAt: moment(),
                });
              }
              this.setState({ isEditing: false });
              return promise;
            })
            .catch((error) => {
              console.error('Error uploading photo!', error);
            })
            .finally(() => {
              this.setState({ isEditing: false, uploadProgress: 1 });
            });
        });
      });
    } else {
      const { isNew, ...data } = {
        ...updatedData,
        code: updatedData.code || shortid.generate(),
      };
      let promise;
      if (isNew) {
        promise = api.user.create(data, data.id);
      } else {
        promise = api.user.update({
          ...data,
          updatedAt: moment(),
        });
      }
      this.setState({ isEditing: false });
      return promise;
    }
  };

  handleCancel = () => {
    this.setState({ isEditing: false });
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState((state) => {
      const { updatedData } = state;
      updatedData[name] = value;
      return { updatedData };
    });
  };

  handleIsOptOutSmsChange = (value) => {
    this.handleInputChange({
      target: {
        name: 'isOptOutSms',
        value: !value,
      },
    });
  };

  handleUploadInputChange = (event) => {
    const { files } = event.target;
    let photoFile;
    let photoUrl;

    if (!isEmpty(files)) {
      photoFile = files[0];
      photoUrl = URL.createObjectURL(photoFile);
    } else {
      return;
    }

    this.setState({ photoFile }, () => {
      this.handleInputChange({
        target: {
          name: 'photoUrl',
          value: photoUrl,
        },
      });
    });
  };

  handleUploadProgress = ({ progress }) => {
    this.setState((state) => {
      let { uploadProgress } = state;
      uploadProgress = Math.max(uploadProgress, progress);
      uploadProgress = Math.min(uploadProgress, 0.99);
      return { uploadProgress };
    });
  };

  handleCopyCode = () => {
    this.setState({ copied: true }, () => {
      this.resetCopiedTimeoutId = setTimeout(() => this.setState({ copied: false }), 2000);
    });
  };

  render() {
    const { onMenuClick } = this.props;
    const data = this.props.data || {};
    const { firstName, lastName, code } = data;
    const { isEditing, updatedData, uploadProgress, copied } = this.state;
    const mobile = getCurrentUserMobile();
    const isOptOutSms = !!(updatedData || {}).isOptOutSms;

    const name = joinNames(firstName, lastName);
    const description = (data.description || '').trim();

    return (
      <React.Fragment>
        <div className={cx('Screen ProfileScreen', { editing: isEditing })}>
          <header>
            <nav>
              <ul>
                <li>
                  {!isEditing && (
                    <button type="button" className="btn btn-icon-only" onClick={onMenuClick}>
                      <i className="fal fa-bars color-primary icon-big" />
                      <div className="sr-only"><Trans>Menu</Trans></div>
                    </button>
                  )}
                  {isEditing && (
                    <button
                      type="button"
                      className="btn btn-icon-only"
                      onClick={this.handleCancel}
                    >
                      <i className="fal fa-times icon-big color-primary" />
                      <div className="sr-only">
                        <Trans>Cancel</Trans>
                      </div>
                    </button>
                  )}
                </li>
                <li>
                  <Trans>Profile</Trans>
                </li>
                <li>
                  {!isEditing && (
                    <button
                      type="button"
                      className="btn btn-icon-only"
                      onClick={this.handleEdit}
                    >
                      <div className="text color-primary">
                        <Trans>Edit</Trans>
                      </div>
                    </button>
                  )}
                  {isEditing && (
                    <button
                      type="button"
                      className="btn btn-icon-only"
                      onClick={this.handleSave}
                    >
                      <div className="text color-primary">
                        <Trans>Save</Trans>
                      </div>
                    </button>
                  )}
                </li>
              </ul>
            </nav>
          </header>
          <section>
            <div className="wrapper">
              {!isEditing && (
                <div className="view">
                  <AvatarIcon {...data} />
                  <div className="name">
                    {(name || '').trim() ? (
                      name.trim()
                    ) : (
                      <em className="empty">
                        <Trans>Unknown name</Trans>
                      </em>
                    )}
                  </div>
                  <div className="mobile">
                    {(mobile || '').trim() ? (
                      formatMobile(mobile.trim())
                    ) : (
                      <em className="empty">
                        <Trans>Unknown mobile number</Trans>
                      </em>
                    )}
                  </div>
                  <div className="description">
                    {(description || '').trim() ? (
                      description.trim()
                    ) : (
                      <em className="empty">
                        <Trans>No description yet.</Trans>
                      </em>
                    )}
                  </div>
                  <div className="code">
                    <h3>
                      <Trans>Friend Code</Trans>
                    </h3>
                    {(code || '').trim() ? (
                      <React.Fragment>
                        <CopyToClipboard
                          text={code}
                          onCopy={this.handleCopyCode}
                        >
                          <div className="codeValue">{code}</div>
                        </CopyToClipboard>
                        <div className={cx('notes', { copied })}>
                          <p>
                            <i className="fal fa-info-circle" />
                            <Trans>Tap code to copy to your clipboard</Trans>
                          </p>
                          <p>
                            <i className="fal fa-check color-primary" />
                            <Trans>Code has been copied to your clipboard</Trans>
                          </p>
                        </div>
                      </React.Fragment>
                    ) : (
                      <em className="empty">
                        <i className="fal fa-info-circle" />
                        <Trans>
                          Your friend code will be generated after you update
                          your profile.
                        </Trans>
                      </em>
                    )}
                  </div>
                  {!isNil(data) && !isNil(data.company) && (
                    <div className="company">
                      <h3>
                        <Trans>Company</Trans>
                      </h3>
                      <div className="companyInfo">
                        <div className="companyLogo" style={{
                          backgroundImage: `url(${data.company.photoUrl})`,
                        }} />
                        <div className="companySubInfo">
                          <span className="companyName">{data.company.name}</span>
                          <span className="companyRole">
                            <span className="companyRoleLabel"><Trans>Role</Trans></span>
                            <span className="companyRoleValue">{data.companyRole}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {isEditing && (
                <form>
                  <fieldset className="upload">
                    <AvatarIcon {...updatedData} />
                    <button type="button" className="btn btn-link">
                      <Trans>Change profile photo</Trans>
                    </button>
                    <div className="uploadInput">
                      <input
                        type="file"
                        name="photoUrl"
                        accept="image/*"
                        onChange={this.handleUploadInputChange}
                      />
                    </div>
                  </fieldset>
                  <fieldset className="inline">
                    <label htmlFor="firstName">
                      <Trans>First name</Trans>
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      className="form-control"
                      onChange={this.handleInputChange}
                      value={updatedData.firstName}
                    />
                  </fieldset>
                  <fieldset className="inline">
                    <label htmlFor="lastName">
                      <Trans>Last name</Trans>
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      className="form-control"
                      onChange={this.handleInputChange}
                      value={updatedData.lastName}
                    />
                  </fieldset>
                  <fieldset className="inline">
                    <label htmlFor="mobile">
                      <Trans>Mobile</Trans>
                    </label>
                    <input
                      type="text"
                      name="mobile"
                      disabled
                      className="form-control"
                      value={mobile}
                    />
                    <p className="notes">
                      <Trans>
                        Please contact{' '}
                        <a href="mailto:hello@vidbax.com">hello@vidbax.com</a>{' '}
                        if you'd like to change your mobile number.
                      </Trans>
                    </p>
                  </fieldset>
                  <fieldset className="inline">
                    <label htmlFor="isOptOutSms">
                      <Trans>SMS Notification</Trans>
                    </label>
                    <Switch
                      name="isOptOutSms"
                      onChange={this.handleIsOptOutSmsChange}
                      checked={!isOptOutSms}
                    />
                  </fieldset>
                  <fieldset className="inline">
                    <label htmlFor="description">
                      <Trans>Description</Trans>
                    </label>
                    <TextareaAutosize
                      name="description"
                      className="form-control"
                      value={updatedData.description}
                      maxRows={6}
                      maxLength={2048}
                      onChange={this.handleInputChange}
                    />
                  </fieldset>
                  {!isNil(data) && !isNil(data.company) && (
                    <React.Fragment>
                      <fieldset className="inline">
                        <label htmlFor="company">
                          <Trans>Company</Trans>
                        </label>
                        <div className="company">
                          <span className="companyLogo" style={{
                            backgroundImage: `url(${data.company.photoUrl})`,
                          }} />
                          <span className="companyName">{data.company.name}</span>
                        </div>
                      </fieldset>
                      <fieldset className="inline">
                        <label htmlFor="companyRole">
                          <Trans>Role</Trans>
                        </label>
                        <input
                          type="text"
                          name="companyRole"
                          className="form-control"
                          onChange={this.handleInputChange}
                          value={updatedData.companyRole}
                        />
                      </fieldset>
                    </React.Fragment>
                  )}
                </form>
              )}
            </div>
          </section>
          <UploadProgress
            title={<Trans>Saving Profile</Trans>}
            percent={uploadProgress}
          >
            <p>
              <Trans>
                Please <strong className="color-primary">DO NOT CLOSE</strong>{' '}
                the app until it's complete.
              </Trans>
            </p>
          </UploadProgress>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(withApi(ProfileScreen));
