import React from 'react';
import firebase from 'firebase';
import cx from 'classnames';
import { isNil } from 'lodash';
import { withTranslation, Trans } from 'react-i18next';
import ReactCodeInput from 'react-code-input';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Capacitor } from '@capacitor/core';

import './style.scss';

import { ReactComponent as Logo } from '../../images/logo-full.svg';
import { withApi } from '../../services/Api';
import LoadingIndicator from '../LoadingIndicator';

const resendDelay = 60000;

class LoginScreen extends React.Component {
  state = {
    mobileCodeValue: '',
    mobileCodeSent: false,
    verifyingMobileCode: false,
  };

  constructor(props) {
    super(props);

    this.codeInputRef = React.createRef();
  }

  componentDidMount() {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('signInButton', {
      'size': 'invisible',
      'callback': () => {
        this.handleLoginClick();
      }
    });

    const sendCodeReadyTime = +window.localStorage.getItem('nextSendCodeReadyTime');
    this.setState({ sendCodeReadyTime });
  }

  componentDidUpdate(prevProps) {
    const { visible } = this.props;

    if (visible !== prevProps.visible) {
      if (visible) {
        this.setState({
          sendingMobileCode: false,
          mobileCodeSent: false,
          verifyingMobileCode: false,
        }, this.focusOnMobileCodeInput);
      }
    }
  }

  sendMobileCode = (force) => {
    return new Promise((resolve, reject) => {
      const { sendCodeReadyTime } = this.state;
      const now = Date.now();

      if (!force && sendCodeReadyTime - now > 0) {
        this.updateSendCodeReadyTime();
        reject();
      } else {
        const { mobile } = this.state || {};
        const nextSendCodeReadyTime = now + resendDelay;
        this.setState({ sendCodeReadyTime: nextSendCodeReadyTime, now }, this.updateSendCodeReadyTime);
        window.localStorage.setItem('nextSendCodeReadyTime', nextSendCodeReadyTime);

        const { FirebasePlugin } = window;
        if (!isNil(FirebasePlugin)) {
          FirebasePlugin
            .getVerificationID(`+${mobile}`, (verificationId) => {
              this.verificationId = verificationId;
              resolve(verificationId);
            }, (error) => {
              console.log('Error sending verification code', error);
              this.verificationId = null;
              reject(error);
            });
        } else {
          const appVerifier = window.recaptchaVerifier;
          firebase.auth()
            .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
            .then(() => {
              return firebase.auth().signInWithPhoneNumber(`+${mobile}`, appVerifier);
            })
            .then((confirmationResult) => {
              this.confirmationResult = confirmationResult;
              resolve(confirmationResult);
            })
            .catch((error) => {
              console.log('Error sending verification code', error);
              this.confirmationResult = null;
              reject(error);
            });
          }
        }
    });
  }

  updateSendCodeReadyTime = () => {
    setTimeout(() => {
      try {
        const now = Date.now();
        this.setState({ now }, this.updateSendCodeReadyTime);
      } catch(e) {}
    }, 250);
  };

  focusOnMobileCodeInput = () => {
    try {
      document.querySelector('.react-tel-input > input').focus();
    } catch (e) {}
  };

  focusOnVerificationCodeInput = () => {
    try {
      document.querySelector('.react-code-input > input:first-child').focus();
    } catch (e) {}
  };

  clearVerificationCodeInput = () => {
    try {
      if (this.codeInputRef && this.codeInputRef.current) {
        const { state } = this.codeInputRef.current;
        state.input[0] = '';
        state.input[1] = '';
        state.input[2] = '';
        state.input[3] = '';
        state.input[4] = '';
        state.input[5] = '';
      }
      console.log('mobileCodeValue', this.state.mobileCodeValue);
      document.querySelectorAll('.react-code-input > input').forEach(i => i.value = '');
      this.focusOnVerificationCodeInput();
    } catch (e) {}
  };

  handleFormBackClick = () => {
    this.setState({ mobileCodeSent: false });
  };

  handleResendMobileCodeClick = () => {
    this.setState({ sendingMobileCode: true });
    this.sendMobileCode().then(() => this.setState({ sendingMobileCode: false, mobileCodeSent: true }));
  }

  handleMobileInputChange = event => {
    let mobile = event.target.value;
    if (mobile === '6104') {
      mobile = '614';
    }
    this.setState({ mobile });
  };

  handleMobileCodeInputChange = value => {
    let activeId;
    try {
      activeId = document.activeElement.dataset.id;
    } catch(error) {}

    this.setState({ mobileCodeValue: value });

    if (value && value.length === 6 && (this.confirmationResult || this.verificationId) && (activeId === '1' || activeId === '5')) {
      this.setState({ verifyingMobileCode: true }, () => {
        try {
          document.activeElement.blur();
        } catch (e) {}
      });

      const { FirebasePlugin } = window;
      if (FirebasePlugin && this.verificationId) {
        const credential = firebase.auth.PhoneAuthProvider.credential(this.verificationId, value);
        firebase.auth()
          .signInWithCredential(credential)
          .then((result) => {
            this.setState({ mobileCodeInvalid: false }, this.handleAuthenticated);
          })
          .catch((error) => {
            this.setState({ mobileCodeInvalid: true, verifyingMobileCode: false, mobileCodeValue: '' }, this.clearVerificationCodeInput);
          });
      } else if (this.confirmationResult) {
        this.confirmationResult
          .confirm(value)
          .then(() => {
            this.setState({ mobileCodeInvalid: false }, this.handleAuthenticated);
          })
          .catch(() => {
            this.setState({ mobileCodeInvalid: true, verifyingMobileCode: false, mobileCodeValue: '' }, this.clearVerificationCodeInput);
          });
      }
    } else {
      this.setState({ mobileCodeInvalid: false, verifyingMobileCode: false });
    }
  };

  handleAuthenticated = () => {
    const { onAuthenticated } = this.props;
    if (onAuthenticated) {
      onAuthenticated();
    }
  }; 

  handleLoginClick = () => {
    this.setState({ sendingMobileCode: true });
    this.sendMobileCode(true).then(() => {
      this.setState({ sendingMobileCode: false, mobileCodeSent: true }, this.focusOnVerificationCodeInput);
    });
  };

  render() {
    const { visible, onInstallClick } = this.props;
    const {
      mobile,
      mobileCodeValue,
      sendingMobileCode,
      mobileCodeSent,
      sendCodeReadyTime,
      mobileCodeInvalid,
      verifyingMobileCode,
    } = this.state || {};

    const now = Date.now();
    const sendCodeRemainingTime = sendCodeReadyTime - now;

    return (
      <div className={cx('LoginScreen', {
        mobileCodeSent,
        verifyingMobileCode,
        visible,
      })}>
        <div className="logoContainer">
          <div className="logo"><Logo /></div>
        </div>
        <form>
          <fieldset data-step="mobile">
            <label htmlFor="mobile"><Trans>What's your mobile number?</Trans></label>
            <PhoneInput
              inputProps={{
                autoFocus: false,
              }}
              name="mobile"
              country="au"
              value={mobile}
              masks={{ au: '... ... ...' }}
              onlyCountries={['au']}
              disableDropdown={true}
              disabled={sendingMobileCode}
              onChange={value => this.handleMobileInputChange({ target: { name: 'mobile', value } })}
            />
            <div className="notes">
              <p className="text-center">
                <Trans><em>Vidbax</em> will send verification code to your mobile.</Trans>
              </p>
            </div>
            <div className="actions">
              <button
                id="signInButton"
                type="button"
                className="btn btn-primary btn-rounded btn-shadow signInButton"
                onClick={!sendingMobileCode ? this.handleLoginClick : null}>
                {!sendingMobileCode && 'Sign in'}
                {sendingMobileCode && <LoadingIndicator />}
              </button>
            </div>
            {!Capacitor.isNative && (
              <div className="installPrompt">
                <h4><Trans>Psst! Did you know?</Trans></h4>
                <p><Trans>You can get better experience by adding our app to your home screen.</Trans></p>
                <button type="button" disabled={sendingMobileCode} className="btn btn-default btn-rounded btn-shadow" onClick={onInstallClick}>
                  <Trans>Show me how!</Trans>
                </button>
              </div>
            )}
          </fieldset>
          <fieldset data-step="mobileCode" className={cx({ mobileCodeInvalid })}>
            <div className="notes">
              <p>
                <Trans>You should get an SMS text message from <em>Vidbax</em> that contains a 6-digit verification code.</Trans>
                <Trans>Enter the verification code into the input box above to proceed to the next step!</Trans>
              </p>
            </div>
            <label htmlFor="mobileCode"><Trans>Got verification code via SMS?</Trans></label>
            {visible && (
              <ReactCodeInput
                ref={this.codeInputRef}
                value={mobileCodeValue}
                autoFocus={false}
                type="tel"
                inputMode="tel"
                name="mobileCode"
                fields={6}
                disabled={sendingMobileCode}
                onChange={this.handleMobileCodeInputChange}
              />
            )}
            {mobileCodeInvalid && (
              <p className="color-danger">
                <Trans>The verification code you just entered was incorrect.<br />Please try again.</Trans>
              </p>
            )}
            <div className="actions">
              <button type="button" className="btn btn-default btn-rounded btn-shadow" onClick={this.handleFormBackClick}>Back</button>
              <button
                type="button"
                className="btn btn-default btn-rounded btn-shadow resendCodeButton"
                disabled={sendCodeRemainingTime > 0}
                onClick={!sendingMobileCode ? this.handleResendMobileCodeClick : null}>
                {!sendingMobileCode && 'Re-send code'}
                {sendingMobileCode && <LoadingIndicator />}
              </button>
            </div>
            <div className={cx('sendCodeReadyTime', { visible: sendCodeRemainingTime > 0 })}>Ready in {Math.ceil(sendCodeRemainingTime * 0.001)}</div>
          </fieldset>
          <fieldset data-step="verification">
            <p><Trans>Please wait while we're authenticating your account...</Trans></p>
            <LoadingIndicator light />
          </fieldset>
        </form>
      </div>
    );
  }
}

export default withTranslation()(withApi(LoginScreen));