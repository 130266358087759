import React from 'react';
import cx from 'classnames';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const styles = {
  textColor: '#FF9900',
  trailColor: '#333333',
  pathColor: '#FF9900',
};

export default class ProgressBar extends React.Component {
  static defaultProps = {
    animate: true,
  };

  render() {
    const { className, animate = true } = this.props;
    let percentage = this.props.percentage || 0;
    percentage = !Number.isNaN(percentage) ? percentage : 0;
    percentage = Math.floor(percentage * 100);

    return (
      <div className={cx('ProgressBar', className)}>
        <CircularProgressbar
          styles={buildStyles({
            ...styles,
            pathTransitionDuration: animate ? 0.5 : 0,
          })}
          value={percentage}
          text={`${percentage}%`}
        />
      </div>
    );
  }
}
