import React from 'react';
import cx from 'classnames';
import { isNil, isEmpty } from 'lodash';
import { withTranslation, Trans } from 'react-i18next';

import './style.scss';

import AvatarIcon from '../AvatarIcon';

class ContactList extends React.Component {
  handleVideoSendClick = (item) => {
    const { onVideoSendClick } = this.props;
    if (onVideoSendClick) {
      onVideoSendClick(item);
    }
  };
  
  handleRequestReject = (item) => {
    const { onRequestReject } = this.props;
    if (onRequestReject) {
      onRequestReject(item);
    }
  };

  handleRequestApprove = (item) => {
    const { onRequestApprove } = this.props;
    if (onRequestApprove) {
      onRequestApprove(item);
    }
  };

  handleRequestCancel = (item) => {
    const { onRequestCancel } = this.props;
    if (onRequestCancel) {
      onRequestCancel(item);
    }
  };

  render() {
    const { className, data } = this.props;

    return (
      <div className={cx('ContactList', className)}>
        {!isEmpty(data) && (
          <ul>
            {data.map((item) => {
              let name = item.name;
              if (isNil(name)) {
                name = `${item.firstName} ${item.lastName}`;
              }

              return (
                <li key={item.id}>
                  <div className="avatar">
                    <AvatarIcon {...item} />
                  </div>
                  <div className="info">
                    <div className="name">{name}</div>
                    <div className="description">{item.description}</div>
                  </div>
                  <div className="actions">
                    {item.status === 'approved' && (
                      <button type="button" className="btn btn-icon-only" onClick={() => this.handleVideoSendClick(item)}>
                        <i className="fal fa-video-plus" />
                        <span className="sr-only"><Trans>Send video</Trans></span>
                      </button>
                    )}
                    {item.status === 'received' && (
                      <React.Fragment>
                        <button type="button" className="btn btn-default" onClick={() => this.handleRequestReject(item)}>
                        <Trans>Ignore</Trans>
                        </button>
                        <button type="button" className="btn btn-primary" onClick={() => this.handleRequestApprove(item)}>
                        <Trans>Approve</Trans>
                        </button>
                      </React.Fragment>
                    )}
                    {item.status === 'sent' && (
                      <button type="button" className="btn btn-default" onClick={() => this.handleRequestCancel(item)}>
                        <Trans>Cancel request</Trans>
                      </button>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}

export default withTranslation()(ContactList);
