import React from 'react';

import './TargetIndicator.scss';

export default class TargetIndicator extends React.Component {
  render() {
    return (
      // <div className="TargetIndicator">
      //   <div className="circle">
      //     <div className="circle-inner-container">
      //       <div className="circle-inner"></div>
      //     </div>
      //   </div>

      //   <div className="circle">
      //     <div className="circle-inner-container">
      //       <div className="circle-inner"></div>
      //     </div>
      //   </div>

      //   <div className="circle">
      //     <div className="circle-inner-container">
      //       <div className="circle-inner"></div>
      //     </div>
      //   </div>

      //   <div className="circle">
      //     <div className="circle-inner-container">
      //       <div className="circle-inner"></div>
      //     </div>
      //   </div>
      // </div>
      <div className="SimpleTargetIndicator" />
    );
  }
}