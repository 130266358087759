import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import numeral from 'numeral';
import filesize from 'filesize';
import { isNil, uniqWith, isEqual, concat } from 'lodash';

momentDurationFormatSetup(moment);

export function formatDecimal(value, format = '0,0') {
  return numeral(value).format(format);
}

export function formatDate(value, options) {
  const d = moment(value);
  if (d.isSame(moment(), 'day')) {
    return d.fromNow();
  }
  return d.calendar(
    null,
    options || {
      sameDay: 'H:MM',
      nextDay: '[tomorrow]',
      nextWeek: 'dddd',
      lastDay: '[yesterday]',
      lastWeek: '[last] dddd',
      sameElse: 'D/M/YY',
    }
  );
}

export function formatFilesize(value, options) {
  if (isNil(value) || isNaN(value)) return value;
  return filesize(value, options);
}

export function formatDuration(value, format = 'h _ m _ s _') {
  return moment.duration(value, 'seconds').format(format);
}

export function formatTime(value, format = 'mm:ss') {
  return moment
    .duration(value >= 0.5 ? value - 0.5 : value, 'seconds')
    .format(format, { trim: false });
}

export function formatMobile(mobile) {
  var cleaned = ('' + mobile).replace(/\D/g, '');
  var match = cleaned.match(/^(61|)?(\d{3})(\d{3})(\d{3})$/);
  if (match) {
    var intlCode = match[1] ? '+61' : '';
    return [intlCode, match[2], match[3], match[4]].join(' ');
  }
  return mobile;
}

export function normalizeMobile(mobile) {
  if (isNil(mobile)) return '';

  let number = mobile;
  number = number.replace(/[^\d+]+/g, '');
  number = number.replace(/^0/, '+61');
  if (number.match(/^61/)) number = '+' + number;
  if (!number.match(/^\+/)) number = '+61' + number;
  return number;
}

export function joinNames(firstName, lastName) {
  let name = '';
  if (firstName) name = firstName;
  if (lastName) {
    if (name) {
      name += ' ';
    }
    name += lastName;
  }
  name = name.trim();
  return name;
}

export function getConnectionType() {
  var connection =
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection;
  if (!isNil(connection)) {
    return connection.effectiveType;
  }
  return null;
}

export function isSlowConnection() {
  var connection =
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection;
  if (!isNil(connection)) {
    return connection.downlink < 4.4;
  }
  return false;
}

export function uniqueConcat(ar1, ar2) {
  return uniqWith(concat(ar1 || [], ar2 || []), isEqual);
}
