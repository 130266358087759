import React from 'react';
import cx from 'classnames';
import { isNil, isEmpty, cloneDeep } from 'lodash';
import { withTranslation, Trans } from 'react-i18next';
import Select from 'react-select';
import Switch from 'rc-switch';

import './style.scss';
import { normalizeMobile, formatMobile } from '../../utils';

class StaffDetailsScreen extends React.Component {
  static defaultProps = {
    data: {
      firstName: '',
      lastName: '',
      mobile: '',
      role: 'Staff',
      isActive: true,
    },
  };

  state = {
    updatedData: cloneDeep(this.props.data),
    errors: {},
    recipients: [],
  };

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (JSON.stringify(prevProps.data) !== JSON.stringify(data) && !isNil(data)) {
      this.setState({ updatedData: cloneDeep(data) });
    }
  }

  validateForm = () => {
    return new Promise((resolve, reject) => {
      const errors = {};
      
      this.setState(state => {
        const { updatedData } = state;
        if ((updatedData.mobile || '').trim()) {
          updatedData.mobile = normalizeMobile(updatedData.mobile);
        }
        return { updatedData };
      }, () => {
        const { firstName, lastName, mobile } = this.state.updatedData;
        if (!(firstName || '').trim()) {
          errors.firstName = true;
        }

        if (!(lastName || '').trim()) {
          errors.lastName = true;
        }

        if (!(mobile || '').trim()) {
          errors.mobile = true;
        }

        this.setState({ errors }, () => {
          if (isEmpty(Object.keys(errors))) {
            resolve();
          } else {
            reject();
          }
        });
      });
    });
  };

  handleSave = () => {
    const { onCreate, onUpdate } = this.props;
    const { updatedData } = this.state;

    this.validateForm().then(() => {
      if (isNil(updatedData.rid)) {
        if (onCreate) {
          onCreate(updatedData);
        }
      } else {
        if (onUpdate) {
          onUpdate(updatedData);
        }
      }
    });
  };

  handleCancel = () => {
    this.setState({ isEditing: false });
  };

  handleInputChange = event => {
    const { name, value } = event.target;
    this.setState(state => {
      const { updatedData } = state;
      updatedData[name] = value;
      return { updatedData };
    });
  };

  handleRoleChange = (option) => {
    this.setState(state => {
      const { updatedData } = state;
      updatedData.role = option.value;
      return { updatedData };
    });
  };

  handleIsActiveChange = (value) => {
    this.handleInputChange({
      target: {
        name: 'isActive',
        value,
      },
    });
  };

  handleRemoveError = (fieldName) => {
    const { errors } = this.state;
    delete errors[fieldName];
    this.setState({ errors });
  };

  render() {
    const { t, className, visible, onClose } = this.props;
    const { errors } = this.state;
    const updatedData = this.state.updatedData || cloneDeep(StaffDetailsScreen.defaultProps.data);

    const roles = [
      t('Admin'),
      t('Staff'),
      t('Instructor'),
    ];
    const roleOptions = roles.map(role => ({ value: role, label: role }));

    return (
      <div className={cx('Screen StaffDetailsScreen', className, { visible })}>
        <header>
          <nav>
            <ul>
              <li>
                <button type="button" className="btn btn-icon-only" onClick={onClose}>
                  <i className="fal fa-times icon-big color-default" />
                  <div className="sr-only"><Trans>Close</Trans></div>
                </button>
              </li>
              {!isNil(updatedData.rid) && (<li><Trans>Edit Staff</Trans></li>)}
              {isNil(updatedData.rid) && (<li><Trans>Add Staff</Trans></li>)}
              <li>
                <button type="button" className="btn btn-icon-only" onClick={this.handleSave}>
                  <div className="text color-primary"><Trans>Save</Trans></div>
                </button>
              </li>
            </ul>
          </nav>
        </header>
        <section>
          <div className="wrapper">
            <h2><Trans>Staff Details</Trans></h2>
            <form>
              <fieldset className={cx('inline', { error: errors.firstName })} onAnimationEnd={() => this.handleRemoveError('firstName')}>
                <label htmlFor="firstName">
                  <Trans>First name</Trans>
                </label>
                <input
                  type="text"
                  name="firstName"
                  className="form-control"
                  placeholder={t('i.e. John')}
                  onChange={this.handleInputChange}
                  value={updatedData.firstName}
                />
              </fieldset>
              <fieldset className={cx('inline', { error: errors.lastName })} onAnimationEnd={() => this.handleRemoveError('lastName')}>
                <label htmlFor="lastName">
                  <Trans>Last name</Trans>
                </label>
                <input
                  type="text"
                  name="lastName"
                  className="form-control"
                  placeholder={t('i.e. Smith')}
                  onChange={this.handleInputChange}
                  value={updatedData.lastName}
                />
              </fieldset>
              <fieldset className={cx('inline', { error: errors.mobile })} onAnimationEnd={() => this.handleRemoveError('mobile')}>
                <label htmlFor="mobile">
                  <Trans>Mobile</Trans>
                </label>
                <input
                  type="text"
                  name="mobile"
                  className="form-control"
                  placeholder={t('i.e. +61 412 233 455')}
                  onChange={this.handleInputChange}
                  value={formatMobile(updatedData.mobile)}
                />
              </fieldset>
              <fieldset className={cx('inline', { error: errors.role })} onAnimationEnd={() => this.handleRemoveError('role')}>
                <label htmlFor="role">
                  <Trans>Role</Trans>
                </label>
                <Select
                  name="role"
                  className="form-control SelectControl"
                  isSearchable={false}
                  isClearable={false}
                  options={roleOptions}
                  onChange={this.handleRoleChange}
                  value={roleOptions.find(option => option.value === updatedData.role)}
                />
              </fieldset>
              <fieldset className="inline">
                <label htmlFor="isActive">
                  <Trans>Is Active?</Trans>
                </label>
                <Switch
                  name="isActive"
                  onChange={this.handleIsActiveChange}
                  checked={updatedData.isActive}
                />
              </fieldset>
            </form>
          </div>
        </section>
      </div>
    );
  }
}

const component = withTranslation()(StaffDetailsScreen);
component.defaultProps = StaffDetailsScreen.defaultProps;

export default component;