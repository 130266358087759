import React from 'react';
import cx from 'classnames';
import './style.scss';

export default class LoadingIndiactor extends React.Component {
  render() {
    return (
      <div className={cx('LoadingIndicator', { ...this.props })}>
        <div className="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
}
