import React from 'react';
import cx from 'classnames';
import { isNil } from 'lodash';

import './style.scss';

export default class AvatarIcon extends React.Component {
  render() {
    const { className, firstName, lastName, photoUrl, onClick } = this.props;

    let mode = 'icon';
    if (!isNil(photoUrl)) mode = 'photo';
    else if (!isNil(firstName) && !isNil(lastName)) mode = 'initial';

    return (
      <div className={cx('AvatarIcon', className, { clickable: !isNil(onClick) })} onClick={onClick}>
        <div className="iconContainer">
          {mode === 'photo' && <div className="photo" style={{ backgroundImage: `url(${photoUrl})` }} />}
          {mode === 'initial' && <div className="initial">{firstName && firstName[0].toUpperCase()}{lastName && lastName[0].toUpperCase()}</div>}
          {mode === 'icon' && <div className="icon"><i className="fas fa-user" /></div>}
        </div>
      </div>
    );
  }
}