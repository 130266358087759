import React from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import { isNil } from 'lodash';
import { withTranslation } from 'react-i18next';
import SweetAlert from 'react-bootstrap-sweetalert';

import './style.scss';

class AlertModal extends React.Component {
  static defaultProps = {
    labels: {
      title: '',
      cancel: 'Cancel',
      ok: 'Ok',
    },
    containerId: 'root',
    isCancelVisible: true,
    isOkVisible: true,
  };

  handleClose = () => {
    const { onClose, data } = this.props;
    if (onClose) {
      onClose(data);
    } else {
      this.handleCancel();
    }
  };

  handleCancel = () => {
    const { onCancel, data } = this.props;
    if (onCancel) {
      onCancel(data);
    }
  };

  handleOk = () => {
    const { onOk, data } = this.props;
    if (onOk) {
      onOk(data);
    }
  };

  render() {
    const {
      t,
      visible,
      type,
      labels,
      children,
      containerId,
      isCancelVisible,
      isOkVisible,
    } = this.props;
    
    if (!visible) return null;

    const modal = (
      <SweetAlert
        customClass={cx('AlertModal', type)}
        confirmButtonColor=""
        title={t(labels.title || '')}
        type={type}
        show={visible}
        showCancel={isCancelVisible}
        showConfirm={isOkVisible}
        confirmBtnText={t(labels.ok)}
        cancelBtnText={t(labels.cancel)}
        cancelBtnBsStyle="default"
        onEscapeKey={this.handleClose}
        onConfirm={this.handleOk}
        onCancel={this.handleCancel}
      >
        {typeof children === 'string' ? <p>{t(children)}</p> : children}
      </SweetAlert>
    );

    if (!isNil(containerId)) {
      return ReactDOM.createPortal(modal, document.getElementById(containerId));
    }

    return modal;
  }
}

const translatedComponent = withTranslation()(AlertModal);
translatedComponent.defaultProps = AlertModal.defaultProps;

export default translatedComponent;
