import React from 'react';
import cx from 'classnames';
import { isNil } from 'lodash';
import { withTranslation, Trans } from 'react-i18next';

import './style.scss';

import TargetIndicator from './TargetIndicator';

function hasQuiet() {
  var cold = false,
  hike = function() {};
  try {
    var aid = Object.defineProperty({}, 'passive', {
      get: () => { cold = true; return cold }
    });
    window.addEventListener('test', hike, aid);
    window.removeEventListener('test', hike, aid);
  } catch (e) {}
  return cold;
}

class PositionalTextScreen extends React.Component {
  state = {
    containerWidth: 0,
    containerHeight: 0,
  };
  
  componentDidMount() {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
    this.dispatchChange();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.naturalWidth !== this.props.naturalWidth || 
        prevProps.naturalHeight !== this.props.naturalHeight) {
      this.handleWindowResize();
    }
  }

  dispatchChange = () => {
    const { text, onChange } = this.props;
    if (!isNil(onChange)) {
      onChange({
        x: this.contentX / this.containerWidth,
        y: this.contentY / this.containerHeight,
        moved: this.isMouseMoved,
        text,
      });
    }
  };

  updateContentPosition = () => {
    if (this.textContainerRef) {
      let isOnTheLeft = true;
      let isAtTheTop = true;
      if (this.contentX > this.containerWidth * 0.5) {
        isOnTheLeft = false;
      }
      if (this.contentY > this.containerHeight * 0.5) {
        isAtTheTop = false;
      }

      if (this.contentRef) {
        this.contentRef.className = `content ${isAtTheTop ? 'topAlign' : 'bottomAlign'} ${isOnTheLeft ? 'leftAlign' : 'rightAlign'}`;
        this.contentRef.style.transform = `translate(${Math.round(this.contentX)}px, ${Math.round(this.contentY)}px)`;
      }
    }
  }

  handleWindowResize = () => {
    if (this.contentRef) {
      this.contentWidth = this.contentRef.offsetWidth;
      this.contentHeight = this.contentRef.offsetHeight;
    }
    if (this.containerRef && this.rootRef) {
      const { offsetWidth, offsetHeight } = this.rootRef;
      this.containerWidth = offsetWidth;
      this.containerHeight = offsetHeight;

      const { initialX, initialY, naturalWidth, naturalHeight } = this.props;
      if (!isNil(naturalWidth) && !isNil(naturalHeight)) {
        const widthScale = offsetWidth / naturalWidth;
        const heightScale = offsetHeight / naturalHeight;
        const scale = Math.min(widthScale, heightScale);
        this.containerWidth = naturalWidth * scale;
        this.containerHeight = naturalHeight * scale;
      }

      if (isNaN(this.containerWidth)) this.containerWidth = 0;
      if (isNaN(this.containerHeight)) this.containerHeight = 0;

      if (this.containerWidth && this.containerHeight) {
        if (isNil(this.contentX) || isNil(this.contentY)) {
          this.contentX = this.containerWidth * (initialX || 0.5);
          this.contentY = this.containerHeight * (initialY || 0.5);
        }
        this.updateContentPosition();
      }

      this.setState({
        containerWidth: this.containerWidth,
        containerHeight: this.containerHeight,
      });
    }
  };

  handleMouseDown = (event) => {
    this.isMouseDown = true;
    this.isMouseMoved = false;
    this.contentX0 = this.contentX;
    this.contentY0 = this.contentY;
    this.contentMX0 =
      event.clientX ||
      (event.changedTouches && event.changedTouches[0].pageX);
    this.contentMY0 =
      event.clientY ||
      (event.changedTouches && event.changedTouches[0].pageY);

    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('touchmove', this.handleMouseMove);
    document.addEventListener('mousemove', this.handleMouseMove, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchmove', this.handleMouseMove, hasQuiet() ? { passive: false } : false);
  };

  handleMouseMove = (event) => {
    if (this.isMouseDown) {
      this.contentMX =
        event.clientX ||
        (event.changedTouches && event.changedTouches[0].pageX);
      this.contentMY =
        event.clientY ||
        (event.changedTouches && event.changedTouches[0].pageY);
      
      const dx = this.contentMX - this.contentMX0;
      const dy = this.contentMY - this.contentMY0;
      const dist = Math.sqrt(dx * dx + dy * dy);
      this.contentX = this.contentX0 + dx;
      this.contentY = this.contentY0 + dy;

      if (dist > 10) {
        this.isMouseMoved = true;
      }

      if (this.contentX < 0) this.contentX = 0;
      if (this.contentX > this.containerWidth) this.contentX = this.containerWidth;
      if (this.contentY < 0) this.contentY = 0;
      if (this.contentY > this.containerHeight) this.contentY = this.containerHeight;
      this.updateContentPosition();
    }
  };

  handleMouseUp = () => {
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('touchmove', this.handleMouseMove);
    this.isMouseDown = false;
    this.dispatchChange();
  };

  render() {
    const { className, text } = this.props;
    const { containerWidth, containerHeight } = this.state;

    return (
      <div
        className={cx('Screen PositionalTextScreen', className)}
        ref={ref => this.rootRef = ref}
      >
        <div
          className="container"
          ref={ref => this.containerRef = ref}
          style={{
            width: containerWidth,
            height: containerHeight,
          }}
        >
          <div className="content"
            ref={ref => this.contentRef = ref}
            onTouchStart={this.handleMouseDown}
            onTouchEnd={this.handleMouseUp}
            onTouchCancel={this.handleMouseUp}
            onMouseDown={this.handleMouseDown}
            onMouseUp={this.handleMouseUp}
          >
            <div
              className="dragArea"
              style={{
                width: containerWidth * 6,
                height: containerHeight * 6,
                marginLeft: containerWidth * -3,
                marginTop: containerHeight * -3,
              }}
            />
            <TargetIndicator />
            <div
              className="textContainer"
              ref={ref => this.textContainerRef = ref}
            >
              <p>{text || <Trans>Write your feedback...</Trans>}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
  
export default withTranslation()(PositionalTextScreen);