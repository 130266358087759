import React from 'react';
import cx from 'classnames';

import './style.scss';

import { ReactComponent as Logo } from '../../images/logo-full.svg';

export default class BlockerMessageScreen extends React.Component {
  render() {
    const { visible, children } = this.props;
    
    return (
      <div className={cx('BlockerMessageScreen', { visible })}>
        <div className="logo"><Logo /></div>
        <div className="messageContainer">
          {children}
        </div>
      </div>
    );
  }
}