import React from 'react';
import cx from 'classnames';

import './style.scss';

const PRESS_MOVE_CIRC = 29 * 29;

function hasQuiet() {
  var cold = false,
  hike = function() {};
  try {
    var aid = Object.defineProperty({}, 'passive', {
      get: () => { cold = true; return cold }
    });
    window.addEventListener('test', hike, aid);
    window.removeEventListener('test', hike, aid);
  } catch (e) {}
  return cold;
}

export default class SwipeOptionWrapper extends React.Component {
  state = {
    isMouseDown: false,
  };

  handleContentMouseDown = (event) => {
    this.isMouseDown = true;
    this.isMouseMove = false;
    this.setState({ isMouseDown: this.isMouseDown });
    this.mx0 =
      event.clientX ||
      (event.changedTouches && event.changedTouches[0].pageX);
    this.my0 =
      event.clientY ||
      (event.changedTouches && event.changedTouches[0].pageY);
    this.contentX0 = this.contentX || 0;

    document.removeEventListener('mousemove', this.handleContentMouseMove);
    document.removeEventListener('touchmove', this.handleContentMouseMove);
    document.addEventListener('mousemove', this.handleContentMouseMove, hasQuiet() ? { passive: false } : false);
    document.addEventListener('touchmove', this.handleContentMouseMove, hasQuiet() ? { passive: false } : false);
  };

  handleContentMouseMove = (event) => {
    if (this.isMouseDown) {
      this.mx =
        event.clientX ||
        (event.changedTouches && event.changedTouches[0].pageX);
      this.my =
        event.clientY ||
        (event.changedTouches && event.changedTouches[0].pageY);
      
      const { innerWidth } = window;
      const { options } = this.props;
      const minX = options.length * -0.2 * innerWidth;
      const dx = this.mx - this.mx0;
      const dy = this.my - this.my0;

      if (!this.isMouseMove) {
        const diff = dx*dx + dy*dy;
        if (diff > PRESS_MOVE_CIRC) {
          this.isMouseMove = true;
        }
      }

      this.contentX = this.contentX0 + dx;
      if (this.contentX < minX) {
        this.contentX = minX + (dx - minX) * 0.1;
      }
      if (this.contentX > 0) {
        this.contentX = (dx - minX) * 0.1;
      }

      const { disabled } = this.props;
      if (!disabled) {
        this.contentRef.style.transform = `translateX(${this.contentX}px)`;
      }
    }
  };

  handleContentMouseUp = (_, ignoreClick) => {
    document.removeEventListener('mousemove', this.handleContentMouseMove);
    document.removeEventListener('touchmove', this.handleContentMouseMove);

    const { data, disabled, onClick } = this.props;
    if (!this.isMouseMove && !ignoreClick) {
      if (onClick) {
        onClick(data);
      }
    }

    this.isMouseDown = false;
    this.isMouseMove = false;

    let open = false;
    const { innerWidth } = window;
    if (this.contentX < -0.1 * innerWidth) {
      this.contentX = -0.2 * innerWidth;
      open = !disabled;
    } else {
      this.contentX = 0;
      open = false;
    }

    this.setState({ isMouseDown: this.isMouseDown, open }, () => {
      const { disabled } = this.props;
      if (!disabled) {
        this.contentRef.style.transform = `translateX(${this.contentX}px)`;
      }
    });
  };

  handleBlockerClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.contentX = 0;
    this.handleContentMouseUp(event, true);
  };
  
  render() {
    const { children, disabled, options, data } = this.props;
    const { isMouseDown, open } = this.state;

    return (
      <div className={cx('SwipeOptionWrapper', { open: open && !disabled })}>
        <div className="options">
          {options.map((option, index) => (
            <button
              key={index}
              type="button"
              className={cx('btn', option.className || 'btn-default')}
              onClick={() => option.onClick && option.onClick(data)}
            >
              {option.label}
            </button>
          ))}
        </div>
        <div
          ref={ref => this.contentRef = ref}
          className={cx('content', {
            dragged: !disabled && isMouseDown,
          })}
          onTouchStart={this.handleContentMouseDown}
          onTouchEnd={this.handleContentMouseUp}
          onTouchCancel={this.handleContentMouseUp}
          onMouseDown={this.handleContentMouseDown}
          onMouseUp={this.handleContentMouseUp}
        >
          {children}
        </div>
        {open && <div className="swipeOptionBlocker" onClick={this.handleBlockerClick} />}
      </div>
    );
  }
}