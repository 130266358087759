import React from 'react';
import cx from 'classnames';
import { isNil, isEmpty } from 'lodash';
import { withTranslation, Trans } from 'react-i18next';
import CanvasDraw from 'react-canvas-draw';

import './style.scss';

import { isSlowConnection, formatTime } from '../../utils';
import {
  TYPE_IMAGE,
  TYPE_VIDEO,
  getAnnotationType,
} from '../../utils/annotation';
import VideoPlayer from '../VideoPlayer';
import VideoThumbnail from '../VideoThumbnail';
import TargetIndicator from '../PositionalTextScreen/TargetIndicator';

class AnnotationViewerScreen extends React.Component {
  componentDidMount() {
    this.checkDrawingContaineRef();
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
    this.stopVideo();
  }

  componentDidUpdate(prevProps) {
    const prevData = JSON.stringify(prevProps.data || {});
    const data = JSON.stringify(this.props.data || {});

    if (prevData !== data) {
      this.sourceVideoPlayer = null;

      if (!isNil(this.props.data)) {
        this.setState(
          { isDrawingBgLoaded: false, data: this.props.data },
          () => {
            this.handleWindowResize();
            setTimeout(() => {
              this.setState({ isDrawingBgLoaded: true });
            }, 1000);
          }
        );
      }
    }

    if (this.props.isLandscape !== prevProps.isLandscape) {
      this.handleWindowResize();
    }
  }

  checkDrawingContaineRef = () => {
    if (isNil(this.drawingContainerRef)) {
      window.requestAnimationFrame(this.checkDrawingContaineRef);
    } else {
      this.handleWindowResize();
    }
  };

  stopVideo = () => {
    if (this.sourceVideoPlayer) {
      this.sourceVideoPlayer.pause();
    }
  };

  handleWindowResize = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });

    if (!isNil(this.$wrapper)) {
      this.wrapperRect = this.$wrapper.getBoundingClientRect();
      this.wrapperWidth = this.wrapperRect.width;
      this.wrapperHeight = this.wrapperRect.height;
    }
  };

  handleSourceVideoPlayerReady = (player) => {
    const { data } = this.props;
    if (data) {
      this.sourceVideoPlayer = player;
      this.sourceVideoPlayer.currentTime(data.time);
    }
  };

  handleDrawingBgLoading = () => {
    this.setState({ isDrawingBgLoaded: false });
  };

  handleDrawingBgLoaded = () => {
    setTimeout(() => {
      this.setState({ isDrawingBgLoaded: true });
    }, 1000);
  };

  handleClose = () => {
    this.stopVideo();

    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  };

  render() {
    const {
      className,
      overlay,
      data: propsData,
      videoId,
      naturalVideoWidth,
      naturalVideoHeight,
    } = this.props;
    const { data, isDrawingBgLoaded } = this.state || {};
    const { message, type: rawType, time: rawTime } = data || {};
    const time = formatTime(rawTime || 0);
    const type = getAnnotationType(rawType);

    let drawingScale = 1;
    if (!isNil(data) && !isNil(data.data) && !isNil(this.drawingContainerRef)) {
      const { offsetWidth, offsetHeight } = this.drawingContainerRef;
      const widthScale = offsetWidth / data.width;
      const heightScale = offsetHeight / data.height;
      drawingScale = widthScale < heightScale ? widthScale : heightScale;
    }

    let videoWidth = 0;
    let videoHeight = 0;

    try {
      if (!isNil(naturalVideoWidth) && !isNil(naturalVideoHeight)) {
        const widthScale = this.wrapperWidth / naturalVideoWidth;
        const heightScale = this.wrapperHeight / naturalVideoHeight;
        const scale = Math.min(widthScale, heightScale);
        videoWidth = naturalVideoWidth * scale;
        videoHeight = naturalVideoHeight * scale;
      }
      if (isNaN(videoWidth)) videoWidth = 0;
      if (isNaN(videoHeight)) videoHeight = 0;
    } catch (error) {}

    return (
      <div
        className={cx('Screen AnnotationViewerScreen', className, {
          overlay,
          visible: !isNil(propsData),
        })}
      >
        <header>
          <nav>
            <ul>
              <li>
                <button
                  type="button"
                  className="btn btn-icon-only"
                  onClick={this.handleClose}
                >
                  <i className="fal fa-times icon-big color-primary" />
                  <div className="sr-only">
                    <Trans>Close</Trans>
                  </div>
                </button>
              </li>
              {!isNil(data) && (
                <li>
                  <Trans time={time}>Reply at {{ time }}</Trans>
                </li>
              )}
              {isNil(data) && (
                <li>
                  <Trans>Reply</Trans>
                </li>
              )}
              <li></li>
            </ul>
          </nav>
        </header>
        <section>
          {type === TYPE_VIDEO && isSlowConnection() && (
            <p className="wifiWarning">
              <Trans>
                Make sure you upload videos while connected to Wi-Fi to avoid
                data charges.
              </Trans>
            </p>
          )}
          {!isNil(data) && (
            <div className="wrapper" ref={(ref) => (this.$wrapper = ref)}>
              {type === TYPE_VIDEO && !isNil(data.src) && (
                <VideoPlayer
                  className="videoPlayer"
                  type="mp4"
                  controls
                  autoplay
                  src={data.src}
                  onReady={this.handleSourceVideoPlayerReady}
                  onEnd={this.handleClose}
                />
              )}
              {type === TYPE_IMAGE && !isNil(data.src) && (
                <div
                  className="image"
                  style={{
                    backgroundImage: `url(${data.src})`,
                  }}
                />
              )}
              {!isNil(data.message) && (
                <div className="textContainer">
                  <p>{message}</p>
                </div>
              )}
              {!isEmpty(data.messages) && (
                <div className="positionalTextAnnotationViewer positionalTextSupport">
                  <div
                    className="positionalTextContainer"
                    style={{
                      width: videoWidth,
                      height: videoHeight,
                    }}
                  >
                    {data.messages.map((message, index) => (
                      <div
                        key={`${data.time}-${index}`}
                        className={cx('positionalText', {
                          leftAlign: message.x <= 0.5,
                          rightAlign: message.x > 0.5,
                          topAlign: message.y <= 0.5,
                          bottomAlign: message.y > 0.5,
                        })}
                        style={{
                          transform: `translate(${Math.round(
                            message.x * videoWidth
                          )}px, ${Math.round(message.y * videoHeight)}px)`,
                        }}
                      >
                        <TargetIndicator />
                        <div className="textContainer">
                          <p>{message.text}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {!isNil(data.data) && (
                <div
                  className="drawingContainer"
                  ref={(ref) => (this.drawingContainerRef = ref)}
                >
                  <VideoThumbnail
                    path={`videos/${videoId}/${data.id}_bg.jpg`}
                    onLoading={this.handleDrawingBgLoading}
                    onLoad={this.handleDrawingBgLoaded}
                  />
                  {isDrawingBgLoaded && (
                    <div
                      className="drawing"
                      style={{
                        transform: `scale(${drawingScale})`,
                        width: `${data.width}px`,
                        height: `${data.height}px`,
                      }}
                    >
                      <CanvasDraw
                        disabled
                        hideGrid
                        brushColor="rgba(0, 0, 0, 0)"
                        catenaryColor="rgba(0, 0, 0, 0)"
                        canvasWidth={data.width}
                        canvasHeight={data.height}
                        saveData={data.data}
                      />
                    </div>
                  )}
                </div>
              )}
              {!isNil(data.audio) && !isNil(data.audio.src) && (
                <audio src={data.audio.src} autoPlay />
              )}
            </div>
          )}
        </section>
      </div>
    );
  }
}

export default withTranslation()(AnnotationViewerScreen);
