import React from 'react';
import cx from 'classnames';
import { isNil, isEmpty } from 'lodash';
import { withTranslation, Trans } from 'react-i18next';

import './style.scss';

import AvatarIcon from '../AvatarIcon';
import SwipeOptionWrapper from '../SwipeOptionWrapper';
import { joinNames } from '../../utils';

class StudentList extends React.Component {
  constructor(props) {
    super(props);

    this.itemSwipeOptions = [
      {
        label: <Trans>Delete</Trans>,
        className: 'btn-danger',
        onClick: this.handleItemDelete,
      }
    ];
  }

  handleItemEdit = (item) => {
    const { onItemEdit } = this.props;
    if (onItemEdit) {
      onItemEdit(item);
    }
  };
  
  handleItemDelete = (item) => {
    const { onItemDelete } = this.props;
    if (onItemDelete) {
      onItemDelete(item);
    }
  };

  render() {
    const { className, data, usersById } = this.props;

    return (
      <div className={cx('StudentList', className)}>
        {!isEmpty(data) && (
          <ul>
            {data.map((item) => {
              const user = !isNil(item.id) && usersById[item.id];
              const userOrItem = user || item;

              let avatar = item;
              let name = userOrItem.name;
              if (!name && !isNil(item)) {
                const itemFullName = joinNames(item.firstName, item.lastName);
                if (itemFullName) {
                  name = itemFullName;
                  avatar = item;
                }
              }
              if (!name && !isNil(user)) {
                const userFullName = joinNames(user.firstName, user.lastName);
                if (userFullName) {
                  name = userFullName;
                  avatar = user;
                }
              }

              return (
                <li key={item.mobile}>
                  <SwipeOptionWrapper
                    data={item}
                    options={this.itemSwipeOptions}
                    onClick={this.handleItemEdit}
                  >
                    <div className="avatar">
                      <AvatarIcon {...avatar} />
                    </div>
                    <div className="info">
                      <div className="name">{name}</div>
                      <div className="description">
                        <span className="role">{item.role}</span>
                        {!item.isActive && <span className="badge color-danger"><Trans>Inactive</Trans></span>}
                        {item.isActive && isNil(item.id) && <span className="badge"><Trans>Hasn't logged in</Trans></span>}
                      </div>
                    </div>
                    <div className="actions">
                      <button type="button" className="btn btn-icon-only">
                        <i className="fal fa-pencil" />
                        <span className="sr-only"><Trans>Edit</Trans></span>
                      </button>
                    </div>
                  </SwipeOptionWrapper>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}

export default withTranslation()(StudentList);
