import firebase from 'firebase';

export function listConversations() {
  return new Promise((resolve) => {
    const listConversationsFn = firebase.functions().httpsCallable('conversations');
    listConversationsFn().then(conversations => {
      resolve(conversations.data);
    });
  });
}

export function chatModel() {
  return {
    listConversations: () => listConversations(),
  };
}

export default {
  listConversations,
};