import React from 'react';
import cx from 'classnames';

import './style.scss';

export default class AnimatedPlayButton extends React.Component {
  render() {
    const { className, hidden, onClick } = this.props;
    const icon = this.props.icon || 'fa-play';

    return (
      <button type="button" className={cx('AnimatedPlayButton', className, { hidden })} onClick={onClick}>
        <div className="iconContainer">
          <i className={cx('fas', icon)} />
        </div>
      </button>
    );
  }
}