import React from 'react';
import firebase from 'firebase';
import cx from 'classnames';
import { isNil } from 'lodash';

import './style.scss';

const generateThumbnail = function(videoUrl) {
  console.info('[INFO] Generating thumbnail', videoUrl);
  const generateThumbnailFn = firebase.functions().httpsCallable('generateThumbnail');
  return generateThumbnailFn({ videoUrl });
}

export default class VideoThumbnail extends React.Component {
  state = {
    isLoading: false,
  };

  componentDidMount() {
    this.loadThumbnail();
  }

  componentDidUpdate(prevProps) {
    const { src: prevSrc } = prevProps;
    const { src } = this.props;
    if (prevSrc !== src && !this.state.isLoading) {
      this.loadThumbnail();
    }
  }

  loadThumbnail() {
    const { src, path, autoGenerate, onLoading, onLoad } = this.props;
    if (isNil(path) && (isNil(src) || src.startsWith('blob'))) {
      return;
    }

    this.setState({ isLoading: true }, () => {
      if (onLoading) {
        onLoading();
      }
      const storage = firebase.storage();
      let thumbnailRef = null;
      if (!isNil(path)) {
        thumbnailRef = storage.ref(path);
      } else {
        const videoRef = storage.refFromURL(src);
        const thumbnailSrc = `${videoRef.fullPath}.jpg`;
        thumbnailRef = storage.ref(thumbnailSrc);
      }
      if (!isNil(thumbnailRef)) {
        thumbnailRef.getDownloadURL()
          .then(thumbnailUrl => {
            this.setState({ thumbnailUrl });
            if (onLoad) {
              onLoad();
            }
          })
          .catch(error => {
            if (autoGenerate) {
              generateThumbnail(src);
            }
          });
      }
    });
  }

  render() {
    const { className } = this.props;
    const { isLoading, thumbnailUrl } = this.state;

    return (
      <div className={cx('VideoThumbnail', className, {
        loading: isLoading,
        visible: !isNil(thumbnailUrl),
      })}>
        <div className="image" style={{
          backgroundImage: `url(${thumbnailUrl})`,
        }} />
      </div>
    )
  }
}