import React from 'react';
import cx from 'classnames';

import './style.scss';

import { ReactComponent as Logo } from '../../images/logo-full.svg';

export default class SplashScreen extends React.Component {
  render() {
    const { visible, loadingPercent, loadingMessage } = this.props;
    
    return (
      <div className={cx('SplashScreen', { visible })}>
        <div className="logo"><Logo /></div>
        {(loadingMessage || loadingPercent > 0) && (
          <React.Fragment>
            <div className="loadingProgressBar">
              <div style={{
                width: `${(loadingPercent || 0) * 100}%` 
              }} />
            </div>
            <div className="loadingMessage">{loadingMessage}</div>
          </React.Fragment>
        )}
      </div>
    );
  }
}