import React from 'react';
import { isEmpty } from 'lodash';
import Switch from 'rc-switch';
import Select, { components } from 'react-select';
import Flag from 'react-flagkit';
import { withTranslation, Trans } from 'react-i18next';
import { Capacitor } from '@capacitor/core';

import './style.scss';

import { resources } from '../../i18n';
import { ReactComponent as Logo } from '../../images/logo-full.svg';
import { withGlobalState } from '../../services/GlobalState';

const SingleValue = ({ children, ...props }) => {
  const { data } = props;
  return (
    <components.SingleValue {...props}>
      <Flag className="flag" country={data.flag} />
      <span className="label">{data.label}</span>
    </components.SingleValue>
  );
};

const Option = ({ children, ...props }) => {
  const { options, value } = props;
  const data = options.find(o => o.value === value);
  return (
    <components.Option {...props}>
      <Flag className="flag" country={data.flag} />
      <span className="label">{data.label}</span>
    </components.Option>
  );
};

const languages = Object.keys(resources).map(key => {
  const r = resources[key];
  return {
    ...r,
    value: r.key, 
  };
});

class SettingsScreen extends React.Component {
  getLanguageById(id) {
    return languages.find(l => l.value === id);
  }

  handleSampleVideosChange = (checked) => {
    const { globalState } = this.props;
    globalState.updateSettings({
      sampleVideos: checked,
    });
  };

  handleThemeChange = (checked) => {
    const { globalState } = this.props;
    globalState.updateSettings({
      theme: checked ? 'dark' : 'light',
    });
  };

  handleLanguageChange = (resource) => {
    const { globalState } = this.props;
    globalState.updateSettings({
      lang: resource.key,
    });
  };

  render() {
    const { onMenuClick } = this.props;
    const { appVersion, settings, getConfig } = this.props.globalState;
    const config = getConfig() || {};
    const { latestAppVersion } = config;
    const languagesConfig = config.languages || [];
    const enabledLanguages = languages.filter(l => languagesConfig.includes(l.key));
    const themeAllowed = !!config.theme;
    const hasLanguages = !isEmpty(enabledLanguages);

    const colorStyles = {
      option: (styles) => {
        return {
          ...styles,
          backgroundColor: '#444',
        };
      },
    };

    return (
      <div className="Screen SettingsScreen">
        <header>
          <nav>
            <ul>
              <li>
                <button type="button" className="btn btn-icon-only" onClick={onMenuClick}>
                  <i className="fal fa-bars color-primary icon-big" />
                  <div className="sr-only"><Trans>Menu</Trans></div>
                </button>
              </li>
              <li><Trans>Settings</Trans></li>
              <li></li>
            </ul>
          </nav>
        </header>
        <section>
          <div className="wrapper">
            <div className="logo">
              <Logo />
              <div className="version">v{appVersion}</div>
            </div>
            <form>
              <fieldset className="inline">
                <label htmlFor="sampleVideos"><Trans>Sample Videos</Trans></label>
                <Switch
                  name="sampleVideos"
                  onChange={this.handleSampleVideosChange}
                  checked={settings.sampleVideos}
                />
              </fieldset>
              {themeAllowed && (
                <fieldset className="inline">
                  <label htmlFor="theme"><Trans>Dark theme</Trans></label>
                  <Switch
                    name="theme"
                    onChange={this.handleThemeChange}
                    checked={settings.theme === 'dark'}
                  />
                </fieldset>
              )}
              {hasLanguages && (
                <fieldset className="inline">
                  <label htmlFor="language"><Trans>Language</Trans></label>
                  <Select
                    name="language"
                    className="form-control SelectControl"
                    searchable
                    creatable
                    isClearable
                    styles={colorStyles}
                    components={{ SingleValue, Option }}
                    options={enabledLanguages}
                    onChange={this.handleLanguageChange}
                    value={this.getLanguageById(settings.lang)}
                  />
                </fieldset>
              )}
            </form>
            {Capacitor.isNative && appVersion !== latestAppVersion && (
              <button
                type="button"
                className="btn btn-default updateButton"
                onClick={() => document.location.href = `/?${Date.now()}`}
              >
                <Trans latestAppVersion={latestAppVersion}>Update to Latest Version v{{latestAppVersion}}</Trans>
              </button>
            )}
          </div>
        </section>
      </div>
    );
  }
}

export default withTranslation()(withGlobalState(SettingsScreen));