import React, { createContext } from 'react';
import { isNil, cloneDeep } from 'lodash';
import i18n from '../../i18n';

const GlobalStateContext = createContext({
  currentUser: null,
  usersById: null,
  alert: null,
  settings: null,
  config: null,
  appVersion: null,
  init: () => {},
  setAppVersion: (appVersion) => {},
  setUser: () => {},
  getUserById: () => {},
  updateCurrentUser: () => {},
  logoutCurrentUser: () => {},
  setGlobalState: () => {},
  showAlert: (options) => {},
  hideAlert: () => {},
  getSettings: () => {},
  updateSettings: (updatedSettings) => {},
  setConfig: (config) => {},
  getConfig: () => {},
});

export class GlobalStateProvider extends React.Component {
  init = (appVersion) => {
    const originalSettings = JSON.parse(window.localStorage.getItem('settings')) || {};
    const settings = cloneDeep(originalSettings);
    settings.theme = settings.theme || 'dark';
    settings.lang = settings.lang || 'en_AU';
    this.updateSettings(settings);
    this.setAppVersion(appVersion);
  };

  updateCurrentUser = currentUser => {
    this.setState({ currentUser });
  };

  logoutCurrentUser = () => {
    this.updateCurrentUser();
  };

  setAppVersion = (appVersion) => {
    this.setState({ appVersion });
  }

  setUser = (user) => {
    if (isNil(user) || isNil(user.id)) {
      return;
    }

    this.setState(state => {
      const { usersById } = state;
      usersById[user.id] = user;
      return { usersById };
    });
  };

  getUserById = (id) => {
    return this.state.usersById[id];
  };

  showAlert = (options) => {
    this.setState({ alert: {
      ...options,
      visible: true,
    }});
  };

  hideAlert = () => {
    this.setState({ alert: {
      visible: false
    }});
  };

  getSettings = () => {
    return JSON.parse(window.localStorage.getItem('settings')) || this.state.settings;
  };

  updateSettings = (updatedSettings) => {
    const { settings } = this.state;
    this.setState({
      settings: {
        ...settings,
        ...updatedSettings,
      },
    }, () => {
      window.localStorage.setItem('settings', JSON.stringify(this.state.settings));
      i18n.changeLanguage(this.state.settings.lang);
    });
  };

  setConfig = (config) => {
    this.setState({ config });
  };

  getConfig = () => {
    const config = this.state.config || {};
    return config[config.status] || {};
  };

  state = {
    currentUser: null,
    usersById: {},
    alert: {},
    settings: {},
    init: this.init,
    setAppVersion: this.appVersion,
    setUser: this.setUser,
    getUserById: this.getUserById,
    updateCurrentUser: this.updateCurrentUser,
    logoutCurrentUser: this.logoutCurrentUser,
    setGlobalState: this.setState,
    showAlert: this.showAlert,
    hideAlert: this.hideAlert,
    getSettings: this.getSettings,
    updateSettings: this.updateSettings,
    setConfig: this.setConfig,
    getConfig: this.getConfig,
  };

  render() {
    return (
      <GlobalStateContext.Provider value={this.state}>
        {this.props.children}
      </GlobalStateContext.Provider>
    );
  }
}

export const GlobalStateConsumer = GlobalStateContext.Consumer;
export const withGlobalState = Component => {
  class ComponentWithGlobalState extends React.Component {
    render() {
      return (
        <GlobalStateContext.Consumer>
          {globalState => (
            <Component globalState={globalState} {...this.props} />
          )}
        </GlobalStateContext.Consumer>
      );
    }
  }

  return ComponentWithGlobalState;
};
