import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

import { ReactComponent as PhoneIcon } from '../../images/iphone.svg';
import { ReactComponent as ArrowIcon } from '../../images/arrow.svg';

import './style.scss';

class LandscapeBlocker extends React.Component {
  render() {
    return (
      <div className="LandscapeBlocker">
        <div className="icon">
          <div className="phone portrait"><PhoneIcon /> </div>
          <div className="phone landscape"><PhoneIcon /> </div>
          <div className="arrow"><ArrowIcon /> </div>
        </div>
        <p><Trans>Please turn your device</Trans></p>
      </div>
    );
  }
}

export default withTranslation()(LandscapeBlocker);