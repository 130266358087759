import React from 'react';
import cx from 'classnames';
import { withTranslation, Trans } from 'react-i18next';

import './style.scss';

class HomeScreenGuide extends React.Component {
  render() {
    const { onClose, visible } = this.props;

    return (
      <div className={cx('HomeScreenGuide', { visible })}>
        <button type="button" className="btn btn-icon-only closeButton" onClick={onClose}>
          <i className="fal fa-times icon-big" />
        </button>
        <h2><Trans>How to Add Vidbax to Home Screen</Trans></h2>
        <h4><Trans>Safari on iPhone</Trans></h4>
        <ol>
          <li>
            <Trans>Tap <strong>Share</strong> icon at the bottom of the screen</Trans>
            <div className="imageContainer">
              <img width="70%" src="./guide-01.jpg" alt="Tap share button" />
              <div className="indicator indicator-1">
                <i className="fas fa-hand-point-up" />
              </div>
            </div>
          </li>
          <li>
            <Trans>Tap <strong>Add to Home Screen</strong></Trans>
            <p className="notes"><small><Trans>(If you don't see the action, scroll to the bottom and tap Edit Actions, then tap Add next to the Add to Home Screen action. After that, you'll be able to select it from the Share Sheet.)</Trans></small></p>
            <div className="imageContainer">
              <img width="70%" src="./guide-02.jpg" alt="Tap Add To Home Screen" />
              <div className="indicator indicator-2">
                <i className="fas fa-hand-point-up" />
              </div>
            </div>
          </li>
        </ol>

        <h4><Trans>Android</Trans></h4>
        <p><Trans>Coming soon...</Trans></p>
      </div>
    )
  }
}

export default withTranslation()(HomeScreenGuide);