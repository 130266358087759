import React from 'react';
import cx from 'classnames';
import firebase from 'firebase';
import debounce from 'debounce';
import moment from 'moment';
import { get, isNil, isEmpty, cloneDeep, differenceBy } from 'lodash';
import { withTranslation, Trans } from 'react-i18next';
import { Plugins, Capacitor } from '@capacitor/core';
import { ToastContainer, toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import 'react-toastify/dist/ReactToastify.css';

import './App.scss';

import { uniqueConcat, normalizeMobile } from './utils';
import { goToRoute, getRouteFromPath, getWindowLocation } from './utils/router';
import { withApi, getCurrentUserId } from './services/Api';
import { trackPage } from './services/Analytics';
import { withGlobalState } from './services/GlobalState';

import MainMenu from './components/MainMenu';
import InstallPrompt from './components/InstallPrompt';
import AlertModal from './components/AlertModal';
import LandscapeBlocker from './components/LandscapeBlocker';
import SplashScreen from './components/SplashScreen';
import LoginScreen from './components/LoginScreen';
import CombinedInboxScreen from './components/CombinedInboxScreen';
import ContactsScreen from './components/ContactsScreen';
import ProfileScreen from './components/ProfileScreen';
import SettingsScreen from './components/SettingsScreen';
import VideoScreen from './components/VideoScreen';
import SendVideoScreen from './components/SendVideoScreen';
import FriendRequestScreen from './components/FriendRequestScreen';
import HomeScreenGuide from './components/HomeScreenGuide';
import OnboardingScreen from './components/OnboardingScreen';
import BlockerMessageScreen from './components/BlockerMessageScreen';
import StaffScreen from './components/StaffScreen';
import StudentsScreen from './components/StudentsScreen';
import StudentDetailsScreen from './components/StudentDetailsScreen';

const APP_VERSION = '1.0.17';

const AUTH_AUTHENTICATING = 0;
const AUTH_AUTHENTICATED = 1;
const AUTH_UNAUTHENTICATED = 2;
const AUTH_UNAUTHORIZED = 3;

const STATUS_MAINTENANCE = 'maintenance';

const ROUTE_REQUEST = 'Friend Request';
const ROUTE_SEND = 'Send Video';
const ROUTE_VIDEO = 'Video';
const ROUTE_INBOX = 'Inbox';
const ROUTE_OUTBOX = 'Outbox';
const ROUTE_CONTACTS = 'Contacts';
const ROUTE_PROFILE = 'Profile';
const ROUTE_SETTINGS = 'Settings';
const ROUTE_LOGIN = 'Login';
const ROUTE_HOME = 'Home';
const ROUTE_HS_GUIDE = 'Home Screen Guide';
const ROUTE_ONBOARDING = 'Onboarding';
const ROUTE_STAFF = 'Staff';
const ROUTE_STUDENTS = 'Students';
const ROUTE_STUDENT_DETAILS = 'Student Details';

const routes = [
  { id: ROUTE_REQUEST, path: '/request' },
  { id: ROUTE_SEND, path: '/send' },
  { id: ROUTE_VIDEO, path: '/video/:id' },
  { id: ROUTE_INBOX, path: '/inbox' },
  { id: ROUTE_OUTBOX, path: '/outbox' },
  { id: ROUTE_CONTACTS, path: '/contacts' },
  { id: ROUTE_PROFILE, path: '/profile' },
  { id: ROUTE_SETTINGS, path: '/settings' },
  { id: ROUTE_LOGIN, path: '/login' },
  { id: ROUTE_HS_GUIDE, path: '/hsguide' },
  { id: ROUTE_ONBOARDING, path: '/onboarding' },
  { id: ROUTE_STAFF, path: '/staff' },
  { id: ROUTE_STUDENTS, path: '/students' },
  { id: ROUTE_STUDENT_DETAILS, path: '/studentd' },
  { id: ROUTE_HOME, path: '/' },
];

const mainScreenRoutes = [
  { id: ROUTE_INBOX, path: '/inbox' },
  { id: ROUTE_OUTBOX, path: '/outbox' },
  { id: ROUTE_CONTACTS, path: '/contacts' },
  { id: ROUTE_PROFILE, path: '/profile' },
  { id: ROUTE_SETTINGS, path: '/settings' },
];

const frontRoutes = [
  { id: ROUTE_REQUEST, path: '/request' },
  { id: ROUTE_SEND, path: '/send' },
  { id: ROUTE_HS_GUIDE, path: '/hsguide' },
  { id: ROUTE_STAFF, path: '/staff' },
  { id: ROUTE_STUDENTS, path: '/students' },
  { id: ROUTE_STUDENT_DETAILS, path: '/studentd' },
];

class App extends React.Component {
  static defaultProps = {
    routes,
    mainScreenRoutes,
    frontRoutes,
    useInstallPrompt: true,
    onboardOnNoFirstName: true,
  };

  state = {
    authState: AUTH_AUTHENTICATING,
    isLoading: true,
    loadingMessage: this.props.t('Starting up...'),
    loadingPercent: 0.5,
    activeScreen: null,
    showInstallPrompt: false,
    companies: [],
    isLandscape: false,
  };

  constructor(props) {
    super(props);

    this.debouncedNotifyVideoArrival = debounce(this.notifyVideoArrival, 500);
  }

  componentDidMount() {
    const { globalState, api } = this.props;
    globalState.init(APP_VERSION);

    this.initCapacitor();

    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
    document.addEventListener(
      'touchmove',
      (event) => {
        if (window.disableTouchMove) {
          event.preventDefault();
        }
      },
      {
        passive: false,
      }
    );

    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      this.setState({ deferredInstallPrompt: event });
    });

    window.addEventListener('load', () => {
      if (navigator.standalone) {
        this.setState({ showInstallPrompt: false });
      } else if (matchMedia('(display-mode: standalone)').matches) {
        this.setState({ showInstallPrompt: false });
      } else {
        this.setState({ showInstallPrompt: true });
      }
    });

    this.unsubscribeSystemConfig = api.user.getSystemConfig((config) => {
      globalState.setConfig(config);
      if (config.status === STATUS_MAINTENANCE) {
        this.setState({ isOnMaintenance: true });
      } else {
        this.setState({ isOnMaintenance: false });
      }
    });

    firebase.auth().onAuthStateChanged(this.handleAuthStateChange);

    const { pathname } = window.location;
    switch (pathname) {
      case '/':
        this.goToRoute(ROUTE_INBOX, '/inbox');
        break;

      case '/studentd':
        this.goToRoute(ROUTE_CONTACTS, '/contacts');
        break;

      default:
        break;
    }
    this.processRoute();
  }

  componentWillUnmount() {
    this.unsubscribeData();
  }

  unsubscribeData() {
    if (this.unsubscribeProfile) {
      this.unsubscribeProfile();
    }
    if (this.unsubscribeCompany) {
      this.unsubscribeCompany();
    }
    if (this.unsubscribeListedCompanies) {
      this.unsubscribeListedCompanies();
    }
    if (this.unsubscribeUnseenFriendVideoList) {
      this.unsubscribeUnseenFriendVideoList();
    }
    if (this.unsubscribeUnseenCompanyVideoList) {
      this.unsubscribeUnseenCompanyVideoList();
    }
    if (this.unsubscribeUnreviewedVideoList) {
      this.unsubscribeUnreviewedVideoList();
    }
    if (this.unsubscribeSystemConfig) {
      this.unsubscribeSystemConfig();
    }
  }

  recalculateLandscapeTransform() {
    const landscapeOffset = (window.innerHeight - window.innerWidth) * 0.5;
    document.documentElement.style.setProperty('--lso', `${landscapeOffset}px`);
  }

  checkLatestAppVersion() {
    if (Capacitor.isNative) {
      try {
        const { getConfig } = this.props.globalState;
        const config = getConfig() || {};
        const { latestAppVersion } = config;
        const isUrlTimestamped = document.location.href
          .split('/')
          .pop()
          .startsWith('?');
        if (!isUrlTimestamped && latestAppVersion !== APP_VERSION) {
          document.location.href = `/?${Date.now()}`;
        }
      } catch (error) {}
    }
  }

  initCapacitor() {
    if (Capacitor.isNative) {
      const { Keyboard, PushNotifications, FCMPlugin, App: CapApp } = Plugins;

      Keyboard.setResizeMode('none');

      PushNotifications.removeAllDeliveredNotifications();

      PushNotifications.addListener('registration', async (token) => {
        console.log('Capacitor.PushNotifications.registration', token);
      });

      PushNotifications.addListener('registrationError', (error) => {
        console.log('Capacitor.PushNotifications.registrationError', error);
      });

      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification) => {
          console.log(
            'Capacitor.PushNotifications.pushNotificationActionPerformed',
            notification
          );
          // alert(`pushNotificationActionPerformed: ${JSON.stringify(notification)}`);
          const videoId = get(notification, 'notification.data.videoId');
          if (!isNil(videoId)) {
            this.props.api.video.get(videoId, (video) => {
              if (!isNil(video)) {
                this.goToVideoScreen(video);
              }
            });
          }
        }
      );

      CapApp.addListener('appUrlOpen', (data) => {
        console.log('Capacitor.appUrlOpen', JSON.stringify(data));
        // alert(`Capacitor.appUrlOpen: ${JSON.stringify(data)}`);
      });

      CapApp.addListener('appStateChange', (state) => {
        if (state.isActive) {
          console.log('App become active');
          this.identifyAnalyticsUser();
          this.checkLatestAppVersion();
        }
      });

      PushNotifications.requestPermission()
        .then((response) => {
          console.log(
            'Capacitor.PushNotifications.requestPermission',
            response
          );
          if (response.granted) {
            PushNotifications.register();
          } else {
            console.log(
              'Capacitor.PushNotifications.requestPermission.notGranted',
              response
            );
          }
        })
        .catch((reason) => {
          console.log(reason);
        });

      try {
        console.log('Capacitor.fcm.getToken...');
        FCMPlugin.getToken()
          .then((response) => {
            console.log('Capacitor.fcm.getToken.success', response);
            this.fcmToken = response.token;
            this.updateFcmToken();
          })
          .catch((error) =>
            console.log(
              `Capacitor.fcm.getToken.reject: ${
                typeof error === 'object' ? JSON.stringify(error) : error
              }`,
              error
            )
          );
      } catch (error) {
        console.log(
          `Capacitor.fcm.getToken.error: ${
            typeof error === 'object' ? JSON.stringify(error) : error
          }`,
          error
        );
      }
    }
  }

  subscribeToFcmTopic = (topic) => {
    if (Capacitor.isNative) {
      const { FCMPlugin } = Plugins;
      FCMPlugin.subscribeTo({ topic })
        .then((response) =>
          console.log('Capacitor.fcm.subscribeTo.success', response)
        )
        .catch((error) =>
          console.log(
            `Capacitor.fcm.subscribeTo.reject: ${
              typeof error === 'object' ? JSON.stringify(error) : error
            }`,
            error
          )
        );
    }
  };

  subscribeToMessagingTopic = () => {
    const { companies, company } = this.state;
    const isInstructor = !isNil(company);
    const isStudent = !isEmpty(companies);

    if (isInstructor) {
      this.subscribeToFcmTopic(`instructors_${company.id}`);
    }
    if (isStudent) {
      companies.forEach((c) => {
        this.subscribeToFcmTopic(`students_${c.id}`);
      });
    }

    if (!isNil(window.heap)) {
      window.heap.addUserProperties({
        is_instructor: isInstructor,
        is_student: isStudent,
        company: !isNil(company) ? company.id : undefined,
        academies: !isEmpty(companies) ? companies.join(',') : undefined,
      });
    }
  };

  updateFcmToken = () => {
    const { api } = this.props;
    const { fcmToken } = this;
    const currentUserId = getCurrentUserId();

    if (!isNil(currentUserId) && !isNil(fcmToken)) {
      api.user
        .updateFcmToken(currentUserId, fcmToken)
        .then(() => console.log('Capacitor.fcm.updateUserToken.success'))
        .catch((error) =>
          console.log(
            `Capacitor.fcm.updateUserToken.reject: ${
              typeof error === 'object' ? JSON.stringify(error) : error
            }`,
            error
          )
        );
    }
  };

  processRoute = () => {
    const { routes } = this.props;
    const { pathname } = getWindowLocation();
    const route = getRouteFromPath(routes, pathname) || mainScreenRoutes[0];

    const newState = {
      activeRoute: route.id,
    };

    if (!isNil(route)) {
      switch (route.id) {
        case ROUTE_INBOX:
        case ROUTE_OUTBOX:
        case ROUTE_CONTACTS:
        case ROUTE_PROFILE:
        case ROUTE_SETTINGS:
          newState.activeMainScreenId = route.id;
          break;

        case ROUTE_VIDEO:
          if (route.params && route.params.id) {
            newState.selectedVideoId = route.params.id;
          }
          break;

        default:
          break;
      }

      if (this.lastPathName !== pathname) {
        this.lastPathName = pathname;
        trackPage(pathname, route.id);
      }
    }

    this.setState(newState);
  };

  goToRoute = (route, path, callback) => {
    this.setState({ activeRoute: route }, callback);
    this.handleMenuClose();
    goToRoute(path, route);
  };

  goToMainScreenById = (id, callback) => {
    const activeMainScreen = routes.find((r) => r.id === id);
    if (activeMainScreen) {
      this.setState({ activeMainScreenId: id });
      this.goToRoute(activeMainScreen.id, activeMainScreen.path, callback);
    }
  };

  goToVideoScreen = (selectedVideo) => {
    if (!isNil(selectedVideo) && !isNil(selectedVideo.id)) {
      this.setState({ selectedVideoId: selectedVideo.id });
      const currentUserId = getCurrentUserId();
      if (
        !selectedVideo.isSample &&
        selectedVideo.senderId !== currentUserId &&
        isNil(selectedVideo.repliedAt)
      ) {
        this.goToRoute(ROUTE_VIDEO, `/video/${selectedVideo.id}?edit=true`);
      } else {
        this.goToRoute(ROUTE_VIDEO, `/video/${selectedVideo.id}`);
      }
    } else {
      this.setState({ selectedVideoId: null });
      this.goToMainScreenById(ROUTE_INBOX);
    }
  };

  checkOnboarding() {
    this.onboardingChecked = true;

    const { api, onboardOnNoFirstName } = this.props;
    const { profile } = this.state;
    const hasProfile = !isNil(profile) && !profile.isNew;
    const hasFirstName = !isNil(profile) && !!profile.firstName;

    const currentUserId = getCurrentUserId();
    api.user.get(currentUserId, (userAccount) => {
      if (isNil(userAccount)) {
        const { api } = this.props;
        api.user
          .create(
            {
              id: currentUserId,
            },
            currentUserId
          )
          .then(() => {
            this.identifyAnalyticsUser();
            this.updateDeviceInfo();
            this.syncCompanyAndAcademyDetails();
          });
      }
    });

    let isOnboarded =
      (hasProfile && hasFirstName) ||
      window.localStorage.getItem('isOnboarded') === 'true';

    if (isOnboarded) {
      if (onboardOnNoFirstName && !hasFirstName) {
        isOnboarded = false;
      }
    }

    if (isOnboarded) {
      this.processRoute();
    } else {
      this.goToRoute(ROUTE_ONBOARDING, '/onboarding');
    }

    return isOnboarded;
  }

  identifyAnalyticsUser() {
    const currentUserId = getCurrentUserId();
    if (!isNil(window.heap) && !isNil(currentUserId)) {
      window.heap.identify(currentUserId);
    }
  }

  updateDeviceInfo() {
    const { api } = this.props;
    const currentUserId = getCurrentUserId();
    if (!isNil(currentUserId)) {
      if (!isNil(window.heap)) {
        window.heap.addUserProperties({
          app_version: APP_VERSION,
          user_agent: window.navigator.userAgent,
          platform: Capacitor && Capacitor.getPlatform(),
          is_native: Capacitor && Capacitor.isNative,
        });
      }
      api.user.update({
        id: currentUserId,
        lastLogin: moment(),
        device: {
          appVersion: APP_VERSION,
          userAgent: window.navigator.userAgent,
          platform: Capacitor && Capacitor.getPlatform(),
          isNative: Capacitor && Capacitor.isNative,
        },
      });
    }
  }

  syncCompanyAndAcademyDetails() {
    const { api } = this.props;
    const currentUserId = getCurrentUserId();
    if (!isNil(currentUserId)) {
      api.user.syncCompany(currentUserId);
      api.user.syncAcademies(currentUserId);
    }
  }

  notifyUnseenReceivedVideo = (
    unseenReceivedVideos,
    prevUnseenReceivedVideos
  ) => {
    const newlyReceivedVideos = differenceBy(
      unseenReceivedVideos,
      prevUnseenReceivedVideos,
      'id'
    );
    if (!isEmpty(newlyReceivedVideos)) {
      this.debouncedNotifyVideoArrival(newlyReceivedVideos.pop());
    }
  };

  notifyVideoArrival = (video) => {
    const { t } = this.props;
    toast.dark(t('A new video arrived in your inbox!'), {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClick: () => {
        this.goToVideoScreen(video);
      },
    });
  };

  handleAuthStateChange = (user) => {
    if (user) {
      const { api, globalState } = this.props;
      let unseenVideosInit = false;

      api.user
        .checkAccess()
        .then(() => {
          const currentUserId = getCurrentUserId();
          const unsetProfile = {
            id: currentUserId,
            isNew: true,
          };

          this.setState(
            {
              currentUserId,
              profile: unsetProfile,
              isLoading: true,
              authState: AUTH_AUTHENTICATED,
              loadingMessage: 'Almost done...',
              loadingPercent: 1,
            },
            () => {
              this.identifyAnalyticsUser();
              this.updateDeviceInfo();
              this.syncCompanyAndAcademyDetails();
            }
          );

          this.updateFcmToken();
          this.unsubscribeData();

          this.unsubscribeProfile = api.user.get(
            currentUserId,
            (profile) => {
              this.setState({
                isLoading: false,
                profile: profile || unsetProfile,
              });

              globalState.updateCurrentUser(profile);
              let isOnboarding = false;
              if (!this.onboardingChecked) {
                isOnboarding = !this.checkOnboarding();
              }

              if (!isNil(profile) && !isNil(profile.companyId)) {
                this.unsubscribeUnseenCompanyVideoList =
                  api.video.listByRecipientId(
                    profile.companyId,
                    (videos) => {
                      const unseenCompanyVideos = videos.filter((v) =>
                        isNil(v.seenAt)
                      );
                      const prevUnseenCompanyVideos = cloneDeep(
                        this.state.unseenCompanyVideos
                      );
                      this.setState({ unseenCompanyVideos }, () => {
                        if (
                          !isOnboarding &&
                          this.state.activeRoute !== ROUTE_INBOX
                        ) {
                          if (unseenVideosInit) {
                            this.notifyUnseenReceivedVideo(
                              unseenCompanyVideos,
                              prevUnseenCompanyVideos
                            );
                          }
                          unseenVideosInit = true;
                        }
                      });
                    },
                    { subscribe: true }
                  );
              }

              if (!isNil(profile) && !isNil(profile.companyId)) {
                this.unsubscribeCompany = api.user.get(
                  profile.companyId,
                  (company) => {
                    this.setState({ company }, this.subscribeToMessagingTopic);
                  },
                  { subscribe: true }
                );
              } else {
                this.setState(
                  {
                    company: null,
                  },
                  this.subscribeToMessagingTopic
                );
              }
            },
            { subscribe: true }
          );

          this.unsubscribeListedCompanies = api.user.listCompanies(
            (companies) => {
              this.setState({ companies }, this.subscribeToMessagingTopic);
            },
            { subscribe: true }
          );

          this.unsubscribeUnseenFriendVideoList = api.video.listByRecipientId(
            currentUserId,
            (videos) => {
              const unseenFriendVideos = videos.filter((v) => isNil(v.seenAt));
              const prevUnseenFriendVideos = cloneDeep(
                this.state.unseenFriendVideos
              );
              this.setState({ unseenFriendVideos }, () => {
                this.notifyUnseenReceivedVideo(
                  unseenFriendVideos,
                  prevUnseenFriendVideos
                );
              });
            },
            { subscribe: true }
          );

          this.unsubscribeUnreviewedVideoList = api.video.listBySenderId(
            currentUserId,
            (videos) => {
              const unreviewedVideos = videos.filter(
                (v) => !isNil(v.repliedAt) && isNil(v.reviewedAt)
              );
              this.setState({ unreviewedVideos });
            },
            { subscribe: true }
          );
        })
        .catch((error) => {
          this.setState({ authState: AUTH_UNAUTHORIZED });
        });
    } else {
      this.setState({
        currentUserId: null,
        isLoading: false,
        authState: AUTH_UNAUTHENTICATED,
      });
    }
  };

  handleAuthenticated = () => {
    const currentUserId = getCurrentUserId();

    if (!isNil(currentUserId)) {
      this.setState({ authState: AUTH_UNAUTHENTICATED });
    } else {
      this.setState(
        {
          loadingMessage: !isNil(currentUserId) ? 'Almost done...' : '',
          loadingPercent: !isNil(currentUserId) ? 1 : 0,
        },
        () => {
          setTimeout(() => {
            this.setState(
              {
                currentUserId,
                authState: AUTH_AUTHENTICATED,
              },
              () => {
                this.identifyAnalyticsUser();
                this.updateDeviceInfo();
                this.syncCompanyAndAcademyDetails();
                if (!this.onboardingChecked) {
                  this.checkOnboarding();
                }

                try {
                  const { activeElement } = document;
                  if (!isNil(activeElement)) {
                    activeElement.blur();
                  }
                } catch (e) {}
              }
            );
          }, 500);
        }
      );
    }
  };

  handleWindowResize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    this.recalculateLandscapeTransform();
  };

  handleInboxClick = () => {
    this.goToMainScreenById(ROUTE_INBOX);
  };

  handleOutboxClick = () => {
    this.goToMainScreenById(ROUTE_OUTBOX);
  };

  handleContactsClick = () => {
    this.goToMainScreenById(ROUTE_CONTACTS);
  };

  handleProfileClick = () => {
    this.goToMainScreenById(ROUTE_PROFILE);
  };

  handleSettingsClick = () => {
    this.goToMainScreenById(ROUTE_SETTINGS);
  };

  handleVideoItemClick = (item) => {
    this.goToVideoScreen(item);
  };

  handleVideoSendClick = (friend) => {
    if (!isNil(friend) && !isNil(friend.id)) {
      this.goToRoute(ROUTE_SEND, `/send?id=${friend.id}`);
    } else {
      this.goToRoute(ROUTE_SEND, '/send');
    }
  };

  handleVideoSendClose = () => {
    const { activeMainScreenId } = this.state;
    this.goToMainScreenById(activeMainScreenId || ROUTE_INBOX);
  };

  handleVideoClose = (selectedVideo) => {
    const { activeMainScreenId, currentUserId } = this.state;
    let targetMainScreenId;

    if (!isNil(selectedVideo)) {
      if (selectedVideo.senderId === currentUserId) {
        targetMainScreenId = ROUTE_OUTBOX;
      } else {
        targetMainScreenId = ROUTE_INBOX;
      }
    }

    this.setState({ selectedVideoId: null });
    this.goToMainScreenById(
      targetMainScreenId || activeMainScreenId || ROUTE_INBOX
    );
  };

  handleVideoDelete = ({ isCurrentUserSender }) => {
    this.setState({ selectedVideoId: null });
    this.goToMainScreenById(isCurrentUserSender ? ROUTE_OUTBOX : ROUTE_INBOX);
  };

  handleVideoSent = () => {
    this.goToMainScreenById(ROUTE_OUTBOX);
  };

  handleVideoReplied = () => {
    this.setState({ selectedVideoId: null });
    this.goToMainScreenById(ROUTE_INBOX);
  };

  handleContactAddClick = () => {
    this.goToRoute(ROUTE_REQUEST, '/request');
  };

  handleFriendRequestClose = () => {
    this.goToMainScreenById(ROUTE_CONTACTS);
  };

  handleOnboardingClose = () => {
    window.localStorage.setItem('isOnboarded', 'true');
    this.goToMainScreenById(ROUTE_INBOX);
  };

  handleMainMenuItemClick = (menuItemId) => {
    switch (menuItemId) {
      case 'staff':
        this.goToRoute(ROUTE_STAFF, '/staff');
        break;

      case 'students':
        this.goToRoute(ROUTE_STUDENTS, '/students');
        break;

      default:
        break;
    }
  };

  handleSignOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.props.globalState.logoutCurrentUser();
        this.goToRoute(ROUTE_LOGIN, '/login');
      });
  };

  handleMenuClick = () => {
    this.setState(({ isMainMenuVisible }) => ({
      isMainMenuVisible: !isMainMenuVisible,
    }));
  };

  handleMenuClose = () => {
    this.setState({ isMainMenuVisible: false });
  };

  handleInstallPromptClose = () => {
    this.setState({ showInstallPrompt: false });
  };

  handleInstall = () => {
    if (!isNil(this.deferredPrompt)) {
      try {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the install prompt');
          } else {
            console.log('User dismissed the install prompt');
          }
        });
      } catch (e) {}
    } else {
      this.goToRoute(ROUTE_HS_GUIDE, '/hsguide');
    }
  };

  handleHomeScreenGuideClose = () => {
    const { activeMainScreenId, authState } = this.state;
    if (authState === AUTH_UNAUTHENTICATED) {
      this.goToRoute(ROUTE_LOGIN, '/login');
    } else {
      this.goToMainScreenById(activeMainScreenId || ROUTE_INBOX);
    }
  };

  handleStaffScreenClose = () => {
    const { activeMainScreenId, authState } = this.state;
    if (authState === AUTH_UNAUTHENTICATED) {
      this.goToRoute(ROUTE_LOGIN, '/login');
    } else {
      this.goToMainScreenById(activeMainScreenId || ROUTE_INBOX);
    }
  };

  handleStudentsScreenClose = () => {
    const { activeMainScreenId, authState } = this.state;
    if (authState === AUTH_UNAUTHENTICATED) {
      this.goToRoute(ROUTE_LOGIN, '/login');
    } else {
      this.goToMainScreenById(activeMainScreenId || ROUTE_INBOX);
    }
  };

  handleStudentEdit = (studentDetails) => {
    this.setState({ studentDetails }, () => {
      this.goToRoute(ROUTE_STUDENT_DETAILS, '/studentd');
    });
  };

  handleStudentsCreate = (newStudents) => {
    const { company } = this.state;
    const { api } = this.props;
    if (!isNil(company)) {
      const students = company.students || [];
      const existingStudents = students.find(
        (s) => normalizeMobile(s.mobile) === normalizeMobile(newStudents.mobile)
      );
      if (!isNil(existingStudents)) {
        Object.keys(existingStudents)
          .concat(Object.keys(newStudents))
          .forEach((key) => {
            existingStudents[key] = newStudents[key];
          });
      } else {
        students.push({
          rid: uuid(),
          ...newStudents,
        });
      }
      company.students = students;
      api.user
        .update({
          id: company.id,
          students,
        })
        .then(() => {
          this.handleStudentDetailsClose();
        });
    }
  };

  handleStudentsUpdate = (updatedStudents) => {
    const { company } = this.state;
    const { api } = this.props;
    if (!isNil(company)) {
      const students = company.students || [];
      const existingStudents = students.find(
        (s) => s.rid === updatedStudents.rid
      );
      if (!isNil(existingStudents)) {
        Object.keys(existingStudents)
          .concat(Object.keys(updatedStudents))
          .forEach((key) => {
            existingStudents[key] = updatedStudents[key];
          });
        company.students = students;
        api.user
          .update({
            id: company.id,
            students,
          })
          .then(() => {
            this.handleStudentDetailsClose();
          });
      }
    }
  };

  handleStudentDetailsClose = () => {
    const { activeMainScreenId } = this.state;
    this.setState({ studentDetails: null });
    this.goToMainScreenById(activeMainScreenId || ROUTE_CONTACTS);
  };

  handleLandscapeToggle = (value) => {
    this.setState({ isLandscape: value }, () => {
      this.recalculateLandscapeTransform();
      if (value) {
        document.body.classList.add('landscape');
      } else {
        document.body.classList.remove('landscape');
      }
    });
  };

  renderAlertModal(globalState) {
    const { alert, hideAlert } = globalState;

    return (
      <AlertModal
        visible={alert.visible}
        type={alert.type}
        labels={{
          title: alert.title,
          cancel: alert.cancel || AlertModal.defaultProps.labels.cancel,
          ok: alert.ok || AlertModal.defaultProps.labels.ok,
        }}
        isOkVisible={alert.isOkVisible}
        isCancelVisible={alert.isCancelVisible}
        onClose={async () => {
          if (alert.onClose) {
            await alert.onClose(alert);
          } else if (alert.onCancel) {
            await alert.onCancel(alert);
          }
          hideAlert();
        }}
        onCancel={async () => {
          if (alert.onCancel) {
            await alert.onCancel(alert);
          }
          hideAlert();
        }}
        onOk={async () => {
          if (alert.onOk) {
            await alert.onOk(alert);
          }
          hideAlert();
        }}
      >
        {alert.message}
      </AlertModal>
    );
  }

  render() {
    const {
      currentUserId,
      authState,
      profile,
      company,
      companies,
      isLoading,
      loadingPercent,
      loadingMessage,
      activeRoute,
      activeMainScreenId,
      selectedVideoId,
      unseenFriendVideos,
      unseenCompanyVideos,
      unreviewedVideos,
      deferredInstallPrompt,
      isOnMaintenance,
      isMainMenuVisible,
      studentDetails,
      isLandscape,
    } = this.state;

    const { frontRoutes, globalState, useInstallPrompt } = this.props;
    const showInstallPrompt =
      !Capacitor.isNative && this.state.showInstallPrompt && useInstallPrompt;
    const isAppBehind = (frontRoutes || []).find(
      (route) => route.id === activeRoute
    );
    const hasFriendRequests = !isEmpty(
      get(globalState, 'currentUser.friendRequestsReceived')
    );
    const unseenVideos = uniqueConcat(unseenFriendVideos, unseenCompanyVideos);
    const hasUnseenVideos = !isEmpty(unseenVideos);
    const hasUnreviewedVideos = !isEmpty(unreviewedVideos);
    const theme = get(globalState, 'settings.theme') || 'dark';
    const { appVersion, getConfig } = this.props.globalState;
    const config = getConfig() || {};
    const { latestAppVersion } = config;
    const hasCompany = !isNil(company);

    return (
      <div
        className={cx('AppWrapper useTheme', {
          landscape: isLandscape,
          native: Capacitor.isNative,
          [`theme-${theme}`]: true,
        })}
      >
        <div
          className={cx('App', {
            behind: !isNil(isAppBehind),
            showInstallPrompt,
          })}
        >
          {showInstallPrompt && (
            <InstallPrompt
              canInstall={!isNil(deferredInstallPrompt)}
              onClose={this.handleInstallPromptClose}
              onInstall={this.handleInstall}
            />
          )}
          {!isNil(globalState.currentUser) && (
            <section>
              {(activeMainScreenId === ROUTE_INBOX ||
                activeMainScreenId === ROUTE_OUTBOX) && (
                <CombinedInboxScreen
                  currentUser={globalState.currentUser}
                  onMenuClick={this.handleMenuClick}
                  onAddClick={this.handleVideoSendClick}
                  onItemClick={this.handleVideoItemClick}
                  onItemDelete={this.handleVideoDelete}
                />
              )}
              {activeMainScreenId === ROUTE_CONTACTS && !hasCompany && (
                <ContactsScreen
                  currentUser={globalState.currentUser}
                  company={company}
                  companies={companies.filter((company) =>
                    (profile.academyIds || []).includes(company.id)
                  )}
                  onMenuClick={this.handleMenuClick}
                  onItemClick={this.handleVideoItemClick}
                  onAddClick={this.handleContactAddClick}
                  onVideoSendClick={this.handleVideoSendClick}
                />
              )}
              {activeMainScreenId === ROUTE_CONTACTS && hasCompany && (
                <StudentsScreen
                  company={company}
                  showAsMainScreen
                  onMenuClick={this.handleMenuClick}
                  onStudentEdit={this.handleStudentEdit}
                  onStudentEditClose={this.handleStudentEditClose}
                />
              )}
              {activeMainScreenId === ROUTE_PROFILE && (
                <ProfileScreen
                  data={{ ...profile, company }}
                  onMenuClick={this.handleMenuClick}
                />
              )}
              {activeMainScreenId === ROUTE_SETTINGS && (
                <SettingsScreen onMenuClick={this.handleMenuClick} />
              )}
            </section>
          )}
          <footer>
            <nav>
              <ul>
                <li
                  className={cx({
                    active:
                      activeMainScreenId === ROUTE_INBOX ||
                      activeMainScreenId === ROUTE_OUTBOX,
                  })}
                >
                  <button
                    type="button"
                    onClick={this.handleInboxClick}
                    className={cx({
                      attention: hasUnseenVideos || hasUnreviewedVideos,
                    })}
                  >
                    <i
                      className={cx('fa-inbox-in', {
                        fal:
                          activeMainScreenId !== ROUTE_INBOX &&
                          activeMainScreenId !== ROUTE_OUTBOX,
                        fas:
                          activeMainScreenId === ROUTE_INBOX ||
                          activeMainScreenId === ROUTE_OUTBOX,
                      })}
                    />
                    <span className="text">
                      <Trans>Inbox</Trans>
                    </span>
                  </button>
                </li>
                <li
                  className={cx({
                    active: activeMainScreenId === ROUTE_CONTACTS,
                  })}
                >
                  <button
                    type="button"
                    onClick={this.handleContactsClick}
                    className={cx({ attention: hasFriendRequests })}
                  >
                    <i
                      className={cx('fa-address-book', {
                        fal: activeMainScreenId !== ROUTE_CONTACTS,
                        fas: activeMainScreenId === ROUTE_CONTACTS,
                      })}
                    />
                    <span className="text">
                      <Trans>Contacts</Trans>
                    </span>
                  </button>
                </li>
                <li className="heroAction">
                  <button type="button" onClick={this.handleVideoSendClick}>
                    <i className="fal fa-video-plus color-default" />
                    <span className="text color-default">
                      <Trans>Send</Trans>
                    </span>
                  </button>
                </li>
                <li
                  className={cx({
                    active: activeMainScreenId === ROUTE_PROFILE,
                  })}
                >
                  <button type="button" onClick={this.handleProfileClick}>
                    <i
                      className={cx('fa-user-circle', {
                        fal: activeMainScreenId !== ROUTE_PROFILE,
                        fas: activeMainScreenId === ROUTE_PROFILE,
                      })}
                    />
                    <span className="text">
                      <Trans>Profile</Trans>
                    </span>
                  </button>
                </li>
                <li
                  className={cx({
                    active: activeMainScreenId === ROUTE_SETTINGS,
                  })}
                >
                  <button
                    type="button"
                    onClick={this.handleSettingsClick}
                    className={cx({
                      attention:
                        Capacitor.isNative && appVersion !== latestAppVersion,
                    })}
                  >
                    <i
                      className={cx('fa-cog', {
                        fal: activeMainScreenId !== ROUTE_SETTINGS,
                        fas: activeMainScreenId === ROUTE_SETTINGS,
                      })}
                    />
                    <span className="text">
                      <Trans>Settings</Trans>
                    </span>
                  </button>
                </li>
              </ul>
            </nav>
          </footer>
        </div>

        <MainMenu
          visible={isMainMenuVisible}
          currentUser={globalState.currentUser}
          company={company}
          onMenuItemClick={this.handleMainMenuItemClick}
          onSignOut={this.handleSignOut}
          onClose={this.handleMenuClose}
        />

        <VideoScreen
          id={selectedVideoId}
          currentUser={globalState.currentUser}
          isLandscape={isLandscape}
          onClose={this.handleVideoClose}
          onDelete={this.handleVideoDelete}
          onReplied={this.handleVideoReplied}
          onLandscapeToggle={this.handleLandscapeToggle}
        />

        <SendVideoScreen
          visible={activeRoute === ROUTE_SEND}
          company={company}
          currentUser={globalState.currentUser}
          companies={companies.filter((company) =>
            (profile.academyIds || []).includes(company.id)
          )}
          onClose={this.handleVideoSendClose}
          onSent={this.handleVideoSent}
        />

        <FriendRequestScreen
          visible={activeRoute === ROUTE_REQUEST}
          currentUser={globalState.currentUser}
          onClose={this.handleFriendRequestClose}
        />

        <OnboardingScreen
          visible={activeRoute === ROUTE_ONBOARDING}
          currentUserId={currentUserId}
          profile={profile}
          onClose={this.handleOnboardingClose}
        />

        {this.renderAlertModal(globalState)}

        <LoginScreen
          visible={authState === AUTH_UNAUTHENTICATED}
          onAuthenticated={this.handleAuthenticated}
          onInstallClick={this.handleInstall}
        />

        <SplashScreen
          visible={isLoading || authState === AUTH_AUTHENTICATING}
          loadingPercent={loadingPercent}
          loadingMessage={loadingMessage}
        />

        <HomeScreenGuide
          visible={activeRoute === ROUTE_HS_GUIDE}
          onClose={this.handleHomeScreenGuideClose}
        />

        <StaffScreen
          visible={activeRoute === ROUTE_STAFF}
          company={company}
          onClose={this.handleStaffScreenClose}
        />

        {/* <StudentsScreen
          visible={activeRoute === ROUTE_STUDENTS}
          company={company}
          onClose={this.handleStudentsScreenClose}
        /> */}

        <StudentDetailsScreen
          visible={!isNil(studentDetails)}
          data={studentDetails}
          onCreate={this.handleStudentsCreate}
          onUpdate={this.handleStudentsUpdate}
          onClose={this.handleStudentDetailsClose}
        />

        <LandscapeBlocker />

        {!isOnMaintenance && (
          <BlockerMessageScreen visible={authState === AUTH_UNAUTHORIZED}>
            <h1>
              <Trans>We're still in BETA!</Trans>
            </h1>
            <p>
              Vidbax is still in closed beta mode at the moment, which means
              we're still keeping a close eye on how we can ensure our users get
              the best experience we can offer. For this reason, using Vidbax
              service is on invite-only basis.
            </p>
            <p>
              If you are keen to find out more about what Vidbax is all about,
              please let us know by sending us an email at hello@vidbax.com.
            </p>
            <p>We're hoping to serve you sometime soon in the future!</p>
            <p>
              Best regards,
              <br />
              Vidbax Team
            </p>
          </BlockerMessageScreen>
        )}

        {isOnMaintenance && (
          <BlockerMessageScreen visible={true}>
            <h1>
              <Trans>We're on maintenance!</Trans>
            </h1>
            <p>
              Vidbax is currently down for maintenance. Our team is currently
              working really hard to resolve some issues and get it back up as
              soon as possible.
            </p>
            <p>
              Please stay tuned and try re-opening the app again a bit later!
            </p>
            <p>
              Best regards,
              <br />
              Vidbax Team
            </p>
          </BlockerMessageScreen>
        )}

        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    );
  }
}

export default withTranslation()(withGlobalState(withApi(App)));
