import React from 'react';
import cx from 'classnames';
import { get, isNil, isEmpty } from 'lodash';
import shortid from 'shortid';
import { withTranslation, Trans } from 'react-i18next';
import ReactAudioPlayer from 'react-audio-player';
import TextareaAutosize from 'react-autosize-textarea';
import AudioRecorder from 'audio-recorder-polyfill';
import mpegEncoder from 'audio-recorder-polyfill/mpeg-encoder';
import Switch from 'rc-switch';

import './style.scss';

import {
  formatFilesize,
  formatDuration,
  formatTime,
  formatDecimal,
} from '../../utils';
import { withGlobalState } from '../../services/GlobalState';
import {
  getVideoMetadata,
  getAudioMetadata,
  getImageMetadata,
  cropAndResizeImage,
  capDimension,
  dataURLtoBlob,
} from '../../utils/media';
import {
  TYPE_IMAGE,
  TYPE_TEXT,
  TYPE_VIDEO,
  TYPE_DRAWING,
  AUDIO_SUPPORTED_TYPES,
  getAnnotationType,
} from '../../utils/annotation';
import VideoPlayer from '../VideoPlayer';
import DrawingScreen from '../DrawingScreen';
import PositionalTextScreen from '../PositionalTextScreen';
import AnimatedPlayButton from '../AnimatedPlayButton';
import TargetIndicator from '../PositionalTextScreen/TargetIndicator';

const sortByTime = (a, b) => a.time - b.time;

function timeToPixel(time, duration, width) {
  return (time / duration) * width;
}

function pixelToTime(x, width, duration) {
  return (x / width) * duration;
}

AudioRecorder.encoder = mpegEncoder;
AudioRecorder.prototype.mimeType = 'audio/mpeg';
window.MediaRecorder = AudioRecorder;

const { MediaRecorder } = window;

class SimpleVideoEditor extends React.Component {
  static defaultProps = {
    annotationDisableGapFactor: 0.015,
    annotationAutoPlayGapFactor: 0.001,
    videoSkipDuration: 2,
  };

  state = {
    videoUrl: this.props.videoUrl,
    annotations: this.props.annotations || [],
    isAnnotationTextInputVisible: false,
    isAnnotationTextCommitted: false,
    isDrawing: false,
    isPlayerPlaying: false,
    zoom: 1,
  };

  playedAnnotationHelper = {};

  constructor(props) {
    super(props);

    this.textareaRef = React.createRef();
  }

  componentWillUnmount() {
    if (this.hidePlayingControlsTimeout) {
      clearTimeout(this.hidePlayingControlsTimeout);
    }
    this.destroy();
    window.removeEventListener('resize', this.handleWindowResize);
  }

  componentDidMount() {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);

    const { onInit } = this.props;
    if (onInit) {
      onInit({
        destroy: this.destroy,
      });
    }
  }

  getAnnotationAt(
    time,
    annotationDisableGapFactor = this.props.annotationDisableGapFactor
  ) {
    const { annotations } = this.state;
    const duration = this.player.duration();
    const timeGap = 0.5; //annotationDisableGapFactor * duration;
    const minTime = time - timeGap;
    const maxTime = time + timeGap;
    return annotations.find((a) => a.time >= minTime && a.time <= maxTime);
  }

  updatePlayedAnnotationHelper = (currentTime) => {
    const annotations = ((this.state || {}).annotations || []).sort(sortByTime);
    annotations.forEach((annotation) => {
      this.playedAnnotationHelper[annotation.time] =
        annotation.time <= currentTime;
    });
  };

  getLastUnplayedAnnotationHelper = (currentTime) => {
    const annotations = ((this.state || {}).annotations || []).sort(sortByTime);
    let annotation = null;
    let unplayedAnnotation = null;
    let i = -1;
    let len = annotations.length;
    let time = 0;

    while (++i < len) {
      annotation = annotations[i];
      time = annotation.time;
      if (time > currentTime) {
        break;
      }
      if (!this.playedAnnotationHelper[time]) {
        unplayedAnnotation = annotation;
      }
    }

    return unplayedAnnotation;
  };

  updateTimeline = () => {
    if (this.isDestroyed) {
      return;
    }

    const { isPlayerPlaying, videoDuration } = this.state;

    if (isPlayerPlaying && this.player) {
      const currentTime = this.player.currentTime();

      if (!videoDuration) {
        const videoDuration = this.player.duration();
        this.setState({ videoDuration });
      }

      if (this.timelineRef) {
        this.updateTimelineToTime(currentTime);
      }

      const { selectedAnnotation } = this.state;
      const annotation = this.getLastUnplayedAnnotationHelper(currentTime);
      if (
        annotation &&
        (!selectedAnnotation || annotation.time !== selectedAnnotation.time) &&
        (!this.lastSelectedAnnotation ||
          annotation.time !== this.lastSelectedAnnotation.time)
      ) {
        this.handleAnnotationClick(annotation);
        this.lastSelectedAnnotation = annotation;
      }

      this.updatePlayedAnnotationHelper(currentTime);
    }

    window.requestAnimationFrame(this.updateTimeline);
  };

  updateTimelineToTime(time) {
    const { videoDuration } = this.state;
    if (
      !this.timelineRef ||
      !this.player ||
      !this.timelineWidth ||
      !videoDuration
    ) {
      return;
    }

    this.currentTime = time;
    this.timelineX = timeToPixel(time, videoDuration, this.timelineWidth);
    if (this.timelineX < 0) this.timelineX = 0;
    if (this.timelineX >= this.timelineWidth)
      this.timelineX = this.timelineWidth;
    this.scrollTimeline(this.timelineX);
  }

  dispatchChange = () => {
    const { onChange } = this.props;
    const { annotations } = this.state;
    if (onChange) {
      onChange({ annotations });
    }
  };

  dispatchAnnotationTextInputVisibilityChange = () => {
    const { onAnnotationTextInputVisibilityChange } = this.props;
    const { isAnnotationTextInputVisible } = this.state;
    if (onAnnotationTextInputVisibilityChange) {
      onAnnotationTextInputVisibilityChange(isAnnotationTextInputVisible);
    }
  };

  destroyAudio() {
    if (this.recorder) {
      this.recorder.removeEventListener(
        'dataavailable',
        this.handleRecordComplete
      );
    }
    this.recorder = null;
  }

  resetFileInputs() {
    if (this.scrubberVideoFileInputRef) {
      this.scrubberVideoFileInputRef.value = '';
    }
    if (this.scrubberAudioFileInputRef) {
      this.scrubberAudioFileInputRef.value = '';
    }
    if (this.scrubberImageFileInputRef) {
      this.scrubberImageFileInputRef.value = '';
    }

    this.updateTimelineToTime(this.currentTime);
  }

  selectAnnotation(selectedAnnotation) {
    if (isNil(selectedAnnotation)) {
      this.lastSelectedAnnotation = null;
      return;
    }
    this.lastSelectedAnnotation = selectedAnnotation;
    this.setState({ selectedAnnotation, isTextareaFocus: false });
    if (this.textareaRef && this.textareaRef.current) {
      this.textareaRef.current.blur();
    }
    const { time } = selectedAnnotation;
    if (this.player) {
      this.handleVideoPlayerPause();
      this.player.currentTime(time);
    }
    this.updateTimelineToTime(time);
  }

  getPreviousAnnotation() {
    let { currentTime } = this;
    if (!currentTime && this.player) {
      currentTime = this.player.currentTime();
    }
    const { annotations } = this.state;
    const len = annotations.length;
    if (!len) return null;

    let i = -1;
    let annotation;
    let foundAnnotation;
    while (++i < len) {
      annotation = annotations[i];
      if (annotation.time >= currentTime) {
        break;
      }
      foundAnnotation = annotation;
    }
    return foundAnnotation;
  }

  getNextAnnotation() {
    let { currentTime } = this;
    if (!currentTime && this.player) {
      currentTime = this.player.currentTime();
    }
    const { annotations } = this.state;
    const len = annotations.length;
    if (!len) return null;

    let i = len;
    let annotation;
    let foundAnnotation;
    while (--i >= 0) {
      annotation = annotations[i];
      if (annotation.time <= currentTime) {
        break;
      }
      foundAnnotation = annotation;
    }
    return foundAnnotation;
  }

  destroy = () => {
    this.destroyAudio();
    this.isDestroyed = true;
    try {
      if (this.player) {
        this.player.pause();
      }
    } catch (error) {}
  };

  handleWindowResize = () => {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });

    if (!isNil(this.$videoPlayerContainer)) {
      this.videoPlayerContainerRect =
        this.$videoPlayerContainer.getBoundingClientRect();
      this.videoPlayerContainerWidth = this.videoPlayerContainerRect.width;
      this.videoPlayerContainerHeight = this.videoPlayerContainerRect.height;
    }
  };

  handleVideoPlayerReady = (player, videoRef) => {
    this.player = player;
    this.videoRef = videoRef;
    const videoDuration = this.player.duration();
    this.setState({ player, videoDuration });
  };

  handleHidePlayingControls = () => {
    try {
      this.setState({ isPlayingControlsHidden: false });
      if (this.hidePlayingControlsTimeout) {
        clearTimeout(this.hidePlayingControlsTimeout);
      }
      this.hidePlayingControlsTimeout = setTimeout(() => {
        this.setState({ isPlayingControlsHidden: true });
      }, 2000);
    } catch (error) {}
  };

  handleVideoPlayerPlay = () => {
    if (this.player) {
      this.player.currentTime(this.currentTime);
      this.updatePlayedAnnotationHelper(this.player.currentTime());
      this.player.play();
    }
    this.setState(
      {
        isPlayerPlaying: true,
        selectedAnnotation: null,
        isAnnotationTextInputVisible: false,
        isAnnotationTextCommitted: false,
        isTextareaFocus: false,
      },
      this.dispatchAnnotationTextInputVisibilityChange
    );
    this.updateTimeline();
    this.handleHidePlayingControls();
  };

  handleVideoPlayerPause = () => {
    if (this.player) this.player.pause();
    this.currentTime = this.player.currentTime();
    this.setState({ isPlayerPlaying: false }, this.handleHidePlayingControls);
  };

  handleVideoPlayerSkipBackward = () => {
    const { videoSkipDuration } = this.props;
    const time = Math.max(0, this.currentTime - videoSkipDuration);
    this.updatePlayedAnnotationHelper(time);
    if (this.player) {
      this.player.currentTime(time);
    }
    this.updateTimelineToTime(time);
    this.handleHidePlayingControls();
  };

  handleVideoPlayerSkipForward = () => {
    const { videoSkipDuration } = this.props;
    const time = Math.min(
      this.state.videoDuration,
      this.currentTime + videoSkipDuration
    );
    this.updatePlayedAnnotationHelper(time);
    if (this.player) {
      this.player.currentTime(time);
    }
    this.updateTimelineToTime(time);
    this.handleHidePlayingControls();
  };

  handleAnnotationClick = (selectedAnnotation) => {
    this.handleVideoPlayerPause();
    if (this.player) {
      this.player.currentTime(selectedAnnotation.time);
    }
    this.updateTimelineToTime(selectedAnnotation.time);
    this.setState({ selectedAnnotation });
  };

  handleAnnotationEditClick = (annotationToEdit) => {
    this.selectAnnotation(annotationToEdit.originalAnnotation);

    const { type, isSubtitleMode } = annotationToEdit;

    this.setState({
      isAnnotationInfosVisible: false,
      annotationToEdit,
    });

    switch (type) {
      case TYPE_TEXT:
        if (isSubtitleMode) {
          this.handleAnnotateSubtitleClick(annotationToEdit);
        } else {
          this.handleAnnotateTextClick(annotationToEdit);
        }
        break;

      case TYPE_DRAWING:
        this.handleAnnotateDrawingClick(annotationToEdit);
        break;

      default:
        break;
    }
  };

  handleAnnotationDeleteClick = (
    annotationToDelete,
    keepSelectedAnnotation
  ) => {
    const { t } = this.props;
    const { showAlert } = this.props.globalState;

    if (!isNil(annotationToDelete)) {
      showAlert({
        type: 'info',
        title: t('Delete Feedback'),
        message: t('Do you want to delete this feedback?'),
        cancel: t('No, cancel'),
        ok: t('Yes, delete it!'),
        onOk: () =>
          this.handleAnnotationDelete(
            annotationToDelete,
            keepSelectedAnnotation
          ),
      });
    }
  };

  handleAnnotationDelete = (annotationToDelete, keepSelectedAnnotation) => {
    if (!isNil(annotationToDelete)) {
      this.setState(
        (state) => {
          const { annotations, selectedAnnotation } = state;
          return {
            annotations: annotations.filter(
              (a) => a.time !== annotationToDelete.time
            ),
            selectedAnnotation: keepSelectedAnnotation
              ? selectedAnnotation
              : null,
          };
        },
        () => {
          setTimeout(() => {
            const { t } = this.props;
            const { showAlert } = this.props.globalState;
            showAlert({
              type: 'success',
              title: t('Feedback Deleted'),
              message: t('Your feedback at {{time}} has been deleted!', {
                time: formatTime(annotationToDelete.time),
              }),
              ok: t('Got it!'),
              isCancelVisible: false,
            });
          }, 0);
        }
      );
    }
  };

  handleAnnotateTextClick = (annotationToEdit) => {
    this.handleVideoPlayerPause();
    let annotationTextInputValue = '';
    let annotationTextInputX;
    let annotationTextInputY;

    if (
      !isNil(annotationToEdit) &&
      !isNil(annotationToEdit.positionalMessage)
    ) {
      annotationTextInputValue = annotationToEdit.positionalMessage.text;
      annotationTextInputX = annotationToEdit.positionalMessage.x;
      annotationTextInputY = annotationToEdit.positionalMessage.y;
    }

    this.setState(
      {
        isDrawing: false,
        isAnnotationTextInputVisible: true,
        isAnnotationTextCommitted: false,
        isSubtitleMode: false,
        annotationTextInputValue,
        annotationTextInputX,
        annotationTextInputY,
        annotationToEdit,
      },
      this.dispatchAnnotationTextInputVisibilityChange
    );
  };

  handleAnnotateSubtitleClick = (annotationToEdit) => {
    this.handleVideoPlayerPause();
    const annotationTextInputValue = !isNil(annotationToEdit)
      ? annotationToEdit.message
      : '';
    const annotationTextInputX = null;
    const annotationTextInputY = null;
    this.setState(
      {
        isDrawing: false,
        isAnnotationTextInputVisible: true,
        isAnnotationTextCommitted: false,
        isSubtitleMode: true,
        annotationTextInputValue,
        annotationTextInputX,
        annotationTextInputY,
        annotationToEdit,
      },
      this.dispatchAnnotationTextInputVisibilityChange
    );
  };

  handleAnnotateDrawingClick = (annotationToEdit) => {
    this.handleVideoPlayerPause();
    this.setState(
      {
        isDrawing: true,
        isAnnotationTextInputVisible: false,
        annotationToEdit,
      },
      this.dispatchAnnotationTextInputVisibilityChange
    );
  };

  handleAnnotateAudioClick = (annotationToEdit) => {
    this.handleVideoPlayerPause();
    this.setState(
      {
        isDrawing: false,
        isAnnotationTextInputVisible: false,
        annotationToEdit,
      },
      this.dispatchAnnotationTextInputVisibilityChange
    );
    this.handleRecordStart();
  };

  handleAnnotateAudioInputChange = (event) => {
    const { files } = event.target;
    if (!isEmpty(files)) {
      const audioFile = files[0];
      const audioUrl = URL.createObjectURL(audioFile);
      const currentAnnotation = this.getAnnotationAt(this.currentTime);
      const currentAnnotationType = getAnnotationType(
        (currentAnnotation || {}).type
      );

      if (
        !isNil(currentAnnotationType) &&
        AUDIO_SUPPORTED_TYPES.includes(currentAnnotationType)
      ) {
        getAudioMetadata(audioFile).then(({ duration }) => {
          const { size, type } = audioFile;
          let annotation;
          this.setState(
            (state) => {
              const { annotations } = state;
              const annotationToUpdate = annotations.find(
                (a) => a.id === currentAnnotation.id
              );
              annotation = annotationToUpdate;
              if (!isNil(annotationToUpdate)) {
                annotationToUpdate.audio = {
                  size,
                  type,
                  duration,
                  src: audioUrl,
                  file: files[0],
                };
              }
              return { annotations: annotations.sort(sortByTime) };
            },
            () => {
              this.dispatchChange();
              this.resetFileInputs();
              this.selectAnnotation(annotation);

              setTimeout(() => {
                const { t } = this.props;
                const { showAlert } = this.props.globalState;
                showAlert({
                  type: 'success',
                  title: t('Audio Feedback Added'),
                  message: t(
                    'An audio feedback successfully added at {{time}}!',
                    { time: formatTime(annotation.time) }
                  ),
                  ok: t('Got it!'),
                  isCancelVisible: false,
                });
              }, 0);
            }
          );
        });
      }
    }
  };

  handleAnnotateVideoInputChange = (event) => {
    const { files } = event.target;
    if (!isEmpty(files)) {
      const videoFile = files[0];
      const videoUrl = URL.createObjectURL(videoFile);

      getVideoMetadata(videoFile).then(({ width, height, duration }) => {
        const { size, type } = videoFile;
        let annotation;
        this.setState(
          (state) => {
            const { annotations } = state;
            if (!annotations.find((a) => a.time === this.currentTime)) {
              annotation = {
                id: shortid.generate(),
                time: this.currentTime,
                size,
                type,
                width,
                height,
                duration,
                file: files[0],
                src: videoUrl,
              };
              annotations.push(annotation);
            }
            return { annotations: annotations.sort(sortByTime) };
          },
          () => {
            this.dispatchChange();
            this.resetFileInputs();
            this.selectAnnotation(annotation);

            setTimeout(() => {
              const { t } = this.props;
              const { showAlert } = this.props.globalState;
              showAlert({
                type: 'success',
                title: t('Video Feedback Added'),
                message: t('A video feedback successfully added at {{time}}!', {
                  time: formatTime(annotation.time),
                }),
                ok: t('Got it!'),
                isCancelVisible: false,
              });
            }, 0);
          }
        );
      });
    }
  };

  handleAnnotateImageInputChange = (event) => {
    const { files } = event.target;
    if (!isEmpty(files)) {
      const rawImageFile = files[0];

      cropAndResizeImage(rawImageFile, 1280)
        .then((imageFile) => {
          return getImageMetadata(imageFile).then(({ width, height }) => ({
            imageFile,
            width,
            height,
          }));
        })
        .then(({ imageFile, width, height }) => {
          const imageUrl = URL.createObjectURL(imageFile);
          const { size, type } = imageFile;
          let annotation;
          this.setState(
            (state) => {
              const { annotations } = state;
              if (!annotations.find((a) => a.time === this.currentTime)) {
                annotation = {
                  id: shortid.generate(),
                  time: this.currentTime,
                  size,
                  type,
                  width,
                  height,
                  file: imageFile,
                  src: imageUrl,
                };
                annotations.push(annotation);
              }
              return { annotations: annotations.sort(sortByTime) };
            },
            () => {
              this.dispatchChange();
              this.resetFileInputs();
              this.selectAnnotation(annotation);

              setTimeout(() => {
                const { t } = this.props;
                const { showAlert } = this.props.globalState;
                showAlert({
                  type: 'success',
                  title: t('Photo Feedback Added'),
                  message: t(
                    'A photo feedback successfully added at {{time}}!',
                    { time: formatTime(annotation.time) }
                  ),
                  ok: t('Got it!'),
                  isCancelVisible: false,
                });
              }, 0);
            }
          );
        });
    }
  };

  handleTimelineScroll = () => {
    const { isPlayerPlaying, videoDuration, selectedAnnotation } = this.state;
    if (!isPlayerPlaying && this.timelineContainerRef) {
      const { scrollLeft } = this.timelineContainerRef;
      this.currentTime = pixelToTime(
        scrollLeft,
        this.timelineWidth,
        videoDuration
      );
      this.currentTimelineScrollLeft = scrollLeft;
      this.player.currentTime(this.currentTime);

      const { annotationAutoPlayGapFactor } = this.props;
      const annotation = this.getAnnotationAt(
        this.currentTime,
        annotationAutoPlayGapFactor
      );
      if (
        !isNil(annotation) &&
        (selectedAnnotation || {}).id !== annotation.id
      ) {
        this.lastSelectedAnnotation = annotation;
        this.handleAnnotationClick(annotation, true);
      } else if (isNil(annotation)) {
        this.lastSelectedAnnotation = null;
        if (!isNil(selectedAnnotation)) {
          this.setState({ selectedAnnotation: null });
        }
      }
    }
  };

  handleAnnotateAudioInputChange = (event) => {
    const { files } = event.target;
    if (!isEmpty(files)) {
      const audioFile = files[0];
      const audioUrl = URL.createObjectURL(audioFile);
      const currentAnnotation = this.getAnnotationAt(this.currentTime);
      const currentAnnotationType = getAnnotationType(
        (currentAnnotation || {}).type
      );

      if (
        !isNil(currentAnnotationType) &&
        AUDIO_SUPPORTED_TYPES.includes(currentAnnotationType)
      ) {
        getAudioMetadata(audioFile).then(({ duration }) => {
          const { size, type } = audioFile;
          let annotation;
          this.setState(
            (state) => {
              const { annotations } = state;
              const annotationToUpdate = annotations.find(
                (a) => a.id === currentAnnotation && currentAnnotation.id
              );
              annotation = annotationToUpdate;
              if (!isNil(annotationToUpdate)) {
                annotationToUpdate.audio = {
                  size,
                  type,
                  duration,
                  src: audioUrl,
                  file: files[0],
                };
              }
              return { annotations: annotations.sort(sortByTime) };
            },
            () => {
              this.dispatchChange();
              this.resetFileInputs();
              this.selectAnnotation(annotation);
            }
          );
        });
      }
    }
  };

  handleDrawingScreenClose = () => {
    this.setState({
      isDrawing: false,
      isTextareaFocus: false,
      isAnnotationTextInputVisible: false,
      annotationToEdit: null,
    });
  };

  handleDrawingScreenSave = ({ data, width, height, dataUrl }) => {
    let annotation;
    const currentAnnotation = this.getAnnotationAt(this.currentTime);

    this.setState(
      (state) => {
        const { annotations } = state;
        if (!isNil(currentAnnotation)) {
          const annotationToUpdate = annotations.find(
            (a) => a.id === currentAnnotation.id
          );
          annotation = annotationToUpdate;
          annotationToUpdate.width = width;
          annotationToUpdate.height = height;
          annotationToUpdate.data = data;
          annotationToUpdate.file = dataURLtoBlob(dataUrl);
        } else if (!annotations.find((a) => a.time === this.currentTime)) {
          annotation = {
            id: shortid.generate(),
            time: this.currentTime,
            type: TYPE_DRAWING,
            width,
            height,
            data,
            file: dataURLtoBlob(dataUrl),
          };
          annotations.push(annotation);
        }
        return {
          annotations: annotations.sort(sortByTime),
          isDrawing: false,
          isTextareaFocus: false,
          isAnnotationTextInputVisible: false,
          annotationToEdit: null,
        };
      },
      () => {
        this.dispatchChange();
        this.resetFileInputs();
        this.selectAnnotation(annotation);
        this.dispatchAnnotationTextInputVisibilityChange();
      }
    );
  };

  handleAnnotationTextInputChange = (event) => {
    this.setState({ annotationTextInputValue: event.target.value });
  };

  handleAnnotationTextInputCancel = () => {
    this.setState(
      {
        isAnnotationTextInputVisible: false,
        annotationTextInputValue: '',
        isTextareaFocus: false,
        annotationToEdit: null,
      },
      this.dispatchAnnotationTextInputVisibilityChange
    );
  };

  handleAnnotationTextInputFocus = () => {
    this.handleVideoPlayerPause();
    this.setState({ isTextareaFocus: true });
  };

  handleAnnotationTextInputBlur = () => {
    const { isSubtitleMode, annotationTextInputValue, selectedAnnotation } =
      this.state;
    if (isSubtitleMode) {
      setTimeout(() => {
        if (!this.ignoreTextareaFocus) {
          this.setState({ isTextareaFocus: false });
        }
        this.ignoreTextareaFocus = false;
      }, 100);
    } else {
      if (!!annotationTextInputValue && isNil(selectedAnnotation)) {
        if (this.textareaRef && this.textareaRef.current) {
          this.textareaRef.current.focus();
        }
      }
    }
  };

  handleAnnotationTextSubToggle = () => {
    this.setState(
      (state) => ({
        isSubtitleMode: !state.isSubtitleMode,
        isTextareaFocus: true,
      }),
      () => {
        this.ignoreTextareaFocus = true;
        if (this.textareaRef && this.textareaRef.current) {
          this.textareaRef.current.focus();
        }
      }
    );
  };

  handleAnnotationTextInputCommitted = () => {
    this.setState({ isAnnotationTextCommitted: true });
  };

  handleAnnotationTextInputSave = () => {
    let annotation;
    const currentAnnotation = this.getAnnotationAt(this.currentTime);

    this.setState(
      (state) => {
        const { annotations, annotationTextInputValue, isSubtitleMode } = state;
        if (!isNil(currentAnnotation)) {
          const annotationToUpdate = annotations.find(
            (a) => a.id === currentAnnotation.id
          );
          annotation = annotationToUpdate;
          if (!isNil(annotationToUpdate)) {
            if (isSubtitleMode) {
              annotationToUpdate.message = annotationTextInputValue;
            } else {
              if (isEmpty(annotationToUpdate.messages)) {
                annotationToUpdate.messages = [];
              }
              const messageIndexToEdit = get(
                state,
                'annotationToEdit.messageIndex'
              );
              let messageToEdit;
              if (
                !isNil(messageIndexToEdit) &&
                messageIndexToEdit >= 0 &&
                messageIndexToEdit < annotationToUpdate.messages.length
              ) {
                messageToEdit = annotationToUpdate.messages[messageIndexToEdit];
              }
              if (!isNil(messageToEdit)) {
                messageToEdit.x = this.annotationTextX;
                messageToEdit.y = this.annotationTextY;
                messageToEdit.text = annotationTextInputValue;
              } else {
                annotationToUpdate.messages.push({
                  x: this.annotationTextX,
                  y: this.annotationTextY,
                  text: annotationTextInputValue,
                });
              }
            }
          }
        } else if (!annotations.find((a) => a.time === this.currentTime)) {
          annotation = {
            id: shortid.generate(),
            time: this.currentTime,
            type: TYPE_TEXT,
          };
          if (isSubtitleMode) {
            annotation.message = annotationTextInputValue;
          } else {
            if (isEmpty(annotation.messages)) {
              annotation.messages = [];
            }
            annotation.messages.push({
              x: this.annotationTextX,
              y: this.annotationTextY,
              text: annotationTextInputValue,
            });
          }
          annotations.push(annotation);
        }
        return {
          annotations: annotations.sort(sortByTime),
          isAnnotationTextInputVisible: false,
          isAnnotationTextCommitted: false,
          isTextareaFocus: false,
          annotationTextInputValue: '',
          annotationToEdit: null,
        };
      },
      () => {
        this.dispatchChange();
        this.resetFileInputs();
        this.selectAnnotation(annotation);
        this.dispatchAnnotationTextInputVisibilityChange();
      }
    );
  };

  handlePositionalTextChange = ({ x, y, moved }) => {
    this.annotationTextX = x;
    this.annotationTextY = y;

    if (this.state.isAnnotationTextMoved !== moved) {
      this.setState({ isAnnotationTextMoved: moved });
    }
  };

  scrollTimeline = (x) => {
    this.timelineContainerRef.scrollLeft = x;
  };

  handleTimelineStepBackward = () => {
    const { annotations } = this.state;
    if (annotations.length > 0) {
      this.selectAnnotation(this.getPreviousAnnotation());
    }
  };

  handleTimelineStepForward = () => {
    const { annotations } = this.state;
    if (annotations.length > 0) {
      this.selectAnnotation(this.getNextAnnotation());
    }
  };

  handleRecordComplete = (event) => {
    const { data: audioData } = event;
    this.setState({ audioData }, () => {
      this.destroyAudio();
      this.handleAnnotateAudioInputChange({
        target: {
          files: [audioData],
        },
      });
    });
  };

  handleRecordStart = () => {
    this.destroyAudio();

    try {
      window.navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          this.recorder = new MediaRecorder(stream);
          this.recorder.addEventListener(
            'dataavailable',
            this.handleRecordComplete
          );
          this.recorder.start();
          this.setState({ isRecordingAudio: true });
        })
        .catch((error) => {
          console.warn(error);
        });
    } catch (error) {
      console.warn(error);
    }
  };

  handleRecordStop = () => {
    this.setState({ isRecordingAudio: false }, () => {
      if (this.recorder) {
        this.recorder.stop();
        this.recorder.stream.getTracks().forEach((i) => i.stop());
      }
    });
  };

  handleAnnotationInfosToggle = () => {
    this.setState((state) => ({
      isAnnotationInfosVisible: !state.isAnnotationInfosVisible,
    }));
  };

  renderTimeline() {
    const { videoDuration, zoom, isPlayerPlaying } = this.state;

    if (isNil(videoDuration)) {
      return null;
    }

    const timeline = [];
    const interval = zoom * 0.25;
    for (let i = 0; i < videoDuration; i += interval) {
      timeline.push(i);
    }

    const timeWidth = 4 * zoom;
    const timelineWidth = (timeWidth / interval) * videoDuration;
    this.timelineWidth = timelineWidth * 10;

    if (timelineWidth <= 0) {
      return null;
    }

    return (
      <React.Fragment>
        <div
          className={cx('timelineContainer', { isPlayerPlaying })}
          ref={(ref) => (this.timelineContainerRef = ref)}
          onScroll={this.handleTimelineScroll}
        >
          <div className="paddedTimeline">
            <div
              className="timeline"
              ref={(ref) => (this.timelineRef = ref)}
              style={{ width: `${timelineWidth}rem` }}
            >
              <div className="lines">
                {timeline.map((time) => (
                  <span
                    className="time"
                    style={{ width: `${timeWidth}rem` }}
                    key={time}
                  >
                    {time % zoom === 0 ? formatTime(time) : null}
                  </span>
                ))}
              </div>
              <div className="annotations">{this.renderAnnotations()}</div>
            </div>
          </div>
        </div>
        <div className="currentTimeIndicator" />
        <button
          type="button"
          className="btn btn-icon-only stepBackwardButton"
          onClick={this.handleTimelineStepBackward}
        >
          <i className="fas fa-step-backward" />
        </button>
        <button
          type="button"
          className="btn btn-icon-only stepForwardButton"
          onClick={this.handleTimelineStepForward}
        >
          <i className="fas fa-step-forward" />
        </button>
      </React.Fragment>
    );
  }

  renderAnnotationInfos() {
    const {
      selectedAnnotation,
      isAnnotationInfosVisible,
      annotations: originalAnnotations,
    } = this.state;
    const annotations = [];
    originalAnnotations.forEach((annotation) => {
      const { type } = annotation;
      if (type === TYPE_VIDEO || type === TYPE_IMAGE) {
        annotations.push({
          ...annotation,
          originalAnnotation: annotation,
        });
      } else {
        if (annotation.message) {
          annotations.push({
            ...annotation,
            originalAnnotation: annotation,
            isSubtitleMode: true,
            type: TYPE_TEXT,
          });
        }
        if (!isEmpty(annotation.messages)) {
          annotation.messages.forEach((positionalMessage, messageIndex) => {
            annotations.push({
              ...annotation,
              originalAnnotation: annotation,
              type: TYPE_TEXT,
              message: positionalMessage.text,
              messageIndex,
              positionalMessage,
            });
          });
        }
        if (annotation.data) {
          annotations.push({
            ...annotation,
            originalAnnotation: annotation,
            type: TYPE_DRAWING,
          });
        }
      }
    });

    return (
      <div
        className={cx('annotationInfos', { visible: isAnnotationInfosVisible })}
      >
        <button
          className="annotationInfosToggleButton"
          type="button"
          onClick={this.handleAnnotationInfosToggle}
        >
          <div className="label">
            <span className="text">
              <Trans>Annotations</Trans>
            </span>
            <span className="badge">{formatDecimal(annotations.length)}</span>
          </div>
          <div className="icon">
            <span className="text">
              {isAnnotationInfosVisible ? (
                <Trans>Close</Trans>
              ) : (
                <Trans>Edit</Trans>
              )}
            </span>
            <i className="fa fa-chevron-up" />
          </div>
        </button>
        <ul>
          {isEmpty(annotations) && (
            <li className="empty">
              <Trans>You haven't added any feedback yet.</Trans>
            </li>
          )}
          {!isEmpty(annotations) &&
            annotations.map((annotation, index) => {
              const { message, time, size, width, height, duration } =
                annotation;
              const type = getAnnotationType(annotation.type);
              return (
                <li
                  key={`${annotation.id}-${index}`}
                  className={cx({
                    selected:
                      !isNil(selectedAnnotation) &&
                      selectedAnnotation.time === time,
                  })}
                  onClick={() => this.selectAnnotation(annotation)}
                >
                  {type === TYPE_VIDEO && (
                    <div className="annotationThumbnail">
                      <i className="fas fa-video-plus" />
                    </div>
                  )}
                  {type === TYPE_IMAGE && (
                    <div className="annotationThumbnail">
                      <i className="fas fa-image" />
                    </div>
                  )}
                  {type === TYPE_TEXT && (
                    <div className="annotationThumbnail">
                      <i className="fas fa-text" />
                    </div>
                  )}
                  {type === TYPE_DRAWING && (
                    <div className="annotationThumbnail">
                      <i className="fas fa-paint-brush-alt" />
                    </div>
                  )}
                  <div className="annotationInfo">
                    <h2>
                      <span className="index">
                        <i className="fas fa-map-marker" />
                        <span className="text">{index + 1}</span>
                      </span>
                      <span className="time">{formatTime(time)}</span>
                    </h2>
                    {type === TYPE_VIDEO && (
                      <dl className="color-dimmed">
                        <dt className="duration">
                          <Trans>Length</Trans>
                        </dt>
                        <dd className="duration">{formatDuration(duration)}</dd>
                        <dt className="size">
                          <Trans>Size</Trans>
                        </dt>
                        <dd className="size">{formatFilesize(size)}</dd>
                        <dt className="dimension">
                          <Trans>Dimension</Trans>
                        </dt>
                        <dd className="dimension">
                          {formatDecimal(width)} x {formatDecimal(height)}
                        </dd>
                      </dl>
                    )}
                    {type === TYPE_IMAGE && (
                      <dl className="color-dimmed">
                        <dt className="size">
                          <Trans>Size</Trans>
                        </dt>
                        <dd className="size">{formatFilesize(size)}</dd>
                        <dt className="dimension">
                          <Trans>Dimension</Trans>
                        </dt>
                        <dd className="dimension">
                          {formatDecimal(width)} x {formatDecimal(height)}
                        </dd>
                      </dl>
                    )}
                    {type === TYPE_TEXT && <p className="message">{message}</p>}
                  </div>
                  {[TYPE_TEXT, TYPE_DRAWING].includes(type) && (
                    <button
                      type="button"
                      className="editAnnotationButton"
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        this.handleAnnotationEditClick(annotation);
                      }}
                    >
                      <i className="fas fa-pencil" />
                      <span className="sr-only">
                        <Trans>Edit Annotation</Trans>
                      </span>
                    </button>
                  )}
                  <button
                    type="button"
                    className="deleteAnnotationButton"
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      this.handleAnnotationDeleteClick(
                        annotation,
                        isNil(selectedAnnotation) ||
                          selectedAnnotation.time !== annotation.time
                      );
                    }}
                  >
                    <i className="fal fa-trash-alt" />
                    <span className="sr-only">
                      <Trans>Delete Annotation</Trans>
                    </span>
                  </button>
                </li>
              );
            })}
        </ul>
      </div>
    );
  }

  renderAnnotations() {
    const { annotations, selectedAnnotation, videoDuration } = this.state;

    return (annotations || []).map((annotation) => {
      const { time } = annotation;
      return (
        <div
          key={time}
          className={cx('annotation', {
            selected:
              !isNil(selectedAnnotation) && selectedAnnotation.time === time,
          })}
          style={{
            transform: `translateX(${timeToPixel(
              time,
              videoDuration,
              this.timelineWidth
            )}px)`,
          }}
        />
      );
    });
  }

  renderAnnotationViewer() {
    const { selectedAnnotation, annotationToEdit } = this.state;
    if (isNil(selectedAnnotation) || !isNil(annotationToEdit)) {
      return null;
    }

    const selectedAnnotationType = getAnnotationType(
      (selectedAnnotation || {}).type
    );
    let naturalVideoWidth = 0;
    let naturalVideoHeight = 0;
    let videoWidth = this.windowWidth;
    let videoHeight = this.windowHeight;

    if (this.player) {
      naturalVideoWidth = this.player.videoWidth();
      naturalVideoHeight = this.player.videoHeight();
    }
    if (!isNil(naturalVideoWidth) && !isNil(naturalVideoHeight)) {
      const widthScale = this.videoPlayerContainerWidth / naturalVideoWidth;
      const heightScale = this.videoPlayerContainerHeight / naturalVideoHeight;
      const scale = Math.min(widthScale, heightScale);
      videoWidth = naturalVideoWidth * scale;
      videoHeight = naturalVideoHeight * scale;
    }
    if (isNaN(videoWidth)) videoWidth = 0;
    if (isNaN(videoHeight)) videoHeight = 0;

    return (
      <div className="annotationViewer">
        {selectedAnnotationType === TYPE_VIDEO && (
          <div className="videoAnnotationViewer">
            <div
              className="videoContainer"
              style={{
                width: capDimension(
                  selectedAnnotation.width,
                  selectedAnnotation.height,
                  this.windowWidth * 0.6
                ).width,
                height:
                  capDimension(
                    selectedAnnotation.width,
                    selectedAnnotation.height,
                    this.windowWidth * 0.6
                  ).height + 40,
              }}
            >
              <VideoPlayer
                key={selectedAnnotation.src}
                className="videoPlayer"
                type="mp4"
                controls
                src={selectedAnnotation.src}
              />
            </div>
          </div>
        )}
        {selectedAnnotationType === TYPE_IMAGE && (
          <div className="imageAnnotationViewer">
            <div
              className="imageContainer"
              style={{
                width: capDimension(
                  selectedAnnotation.width,
                  selectedAnnotation.height,
                  this.windowWidth * 0.6
                ).width,
                height: capDimension(
                  selectedAnnotation.width,
                  selectedAnnotation.height,
                  this.windowWidth * 0.6
                ).height,
              }}
            >
              <div
                className="image"
                style={{ backgroundImage: `url(${selectedAnnotation.src})` }}
              />
            </div>
          </div>
        )}
        {!isNil(selectedAnnotation.data) && (
          <div className="drawingAnnotationViewer">
            <DrawingScreen
              readOnly
              naturalWidth={naturalVideoWidth}
              naturalHeight={naturalVideoHeight}
              canvasWidth={selectedAnnotation.width}
              canvasHeight={selectedAnnotation.height}
              data={selectedAnnotation.data}
            />
          </div>
        )}
        {!isNil(selectedAnnotation.message) && (
          <div className="textAnnotationViewer">
            <div className="text">
              <p>{selectedAnnotation.message}</p>
            </div>
          </div>
        )}
        {!isEmpty(selectedAnnotation.messages) && (
          <div className="positionalTextAnnotationViewer positionalTextSupport">
            <div
              className="positionalTextContainer"
              style={{
                width: videoWidth,
                height: videoHeight,
              }}
            >
              {selectedAnnotation.messages.map((message, index) => (
                <div
                  key={`${selectedAnnotation.time}-${index}`}
                  className={cx('positionalText', {
                    leftAlign: message.x <= 0.5,
                    rightAlign: message.x > 0.5,
                    topAlign: message.y <= 0.5,
                    bottomAlign: message.y > 0.5,
                  })}
                  style={{
                    transform: `translate(${Math.round(
                      message.x * videoWidth
                    )}px, ${Math.round(message.y * videoHeight)}px)`,
                  }}
                >
                  <TargetIndicator />
                  <div className="textContainer">
                    <p>{message.text}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {!isNil(selectedAnnotation.audio) &&
          !isNil(selectedAnnotation.audio.src) && (
            <ReactAudioPlayer
              src={selectedAnnotation.audio.src}
              autoPlay
              controls={false}
            />
          )}
      </div>
    );
  }

  renderAudioRecorder() {
    return (
      <div className="audioRecorder">
        <p>
          <Trans>Recording audio</Trans>
        </p>
        <button
          type="button"
          className="btn btn-danger"
          onClick={this.handleRecordStop}
        >
          <Trans>Finish recording</Trans>
        </button>
      </div>
    );
  }

  render() {
    const { t, videoSkipDuration } = this.props;
    const {
      videoUrl,
      videoDuration,
      selectedAnnotation,
      annotationTextInputValue,
      annotationTextInputX,
      annotationTextInputY,
      isAnnotationTextCommitted,
      isDrawing,
      isPlayerPlaying,
      isRecordingAudio,
      isSubtitleMode,
      isAnnotationTextInputVisible,
      isPlayingControlsHidden,
    } = this.state;

    const selectedAnnotationType = getAnnotationType(
      (selectedAnnotation || {}).type
    );
    let naturalVideoWidth = 0;
    let naturalVideoHeight = 0;

    if (this.player) {
      naturalVideoWidth = this.player.videoWidth();
      naturalVideoHeight = this.player.videoHeight();
    }

    const skipBackwardButton = (
      <button
        type="button"
        className="skipBackwardButton"
        onClick={this.handleVideoPlayerSkipBackward}
      >
        <i className="fal fa-undo" />
        <span className="text">{videoSkipDuration}</span>
      </button>
    );

    const skipForwardButton = (
      <button
        type="button"
        className="skipForwardButton"
        onClick={this.handleVideoPlayerSkipForward}
      >
        <i className="fal fa-redo" />
        <span className="text">{videoSkipDuration}</span>
      </button>
    );

    return (
      <div
        className={cx('SimpleVideoEditor', {
          editing: !isNil(videoUrl),
          drawing: isDrawing,
          writingText: isAnnotationTextInputVisible,
          subtitleMode: isSubtitleMode,
          recordingAudio: isRecordingAudio,
          hasDuration: !Number.isNaN(Number.parseInt(videoDuration)),
        })}
      >
        <div
          className="videoPlayerContainer"
          ref={(ref) => (this.$videoPlayerContainer = ref)}
        >
          {!isNil(videoUrl) && (
            <React.Fragment>
              <VideoPlayer
                className="videoPlayer"
                type="mp4"
                controls={false}
                src={videoUrl}
                onReady={this.handleVideoPlayerReady}
                onEnd={this.handleVideoPlayerPause}
              />
              {!isPlayerPlaying && (
                <div className="pausedControls">
                  {skipBackwardButton}
                  <AnimatedPlayButton
                    className="playButton"
                    onClick={this.handleVideoPlayerPlay}
                  />
                  {skipForwardButton}
                </div>
              )}
              {isPlayerPlaying && (
                <div
                  className={cx('playingControls', {
                    hidden: isPlayingControlsHidden,
                  })}
                >
                  <div
                    className="pauseBlocker"
                    onClick={this.handleVideoPlayerPause}
                  />
                  {skipBackwardButton}
                  <button
                    type="button"
                    className={cx('pauseButton', {
                      hidden: !isNil(selectedAnnotation),
                    })}
                    onClick={this.handleVideoPlayerPause}
                  >
                    <div className="iconContainer">
                      <i className="fas fa-pause" />
                    </div>
                  </button>
                  {skipForwardButton}
                </div>
              )}
            </React.Fragment>
          )}
        </div>
        {this.renderAnnotationViewer()}
        {this.renderAudioRecorder()}
        <div className="noDurationInstruction">
          <Trans>Tap the play button in order to start editing!</Trans>
        </div>
        <div className="controlsContainer">{this.renderTimeline()}</div>
        <div
          className={cx('toolsContainer', {
            [`${selectedAnnotationType}Annotation`]: !isNil(selectedAnnotation),
            annotationSelected: !isNil(selectedAnnotation),
            audioSupported: AUDIO_SUPPORTED_TYPES.includes(
              selectedAnnotationType
            ),
            hasAudio:
              !isNil(selectedAnnotation) && !isNil(selectedAnnotation.audio),
            hasDrawing:
              !isNil(selectedAnnotation) && !isNil(selectedAnnotation.data),
            hasText: !isNil(selectedAnnotation) && !!selectedAnnotation.message,
            hasPositionalText:
              !isNil(selectedAnnotation) &&
              !isEmpty(selectedAnnotation.messages),
          })}
        >
          <div className="tools">
            <button
              type="button"
              className="btn btn-icon-only annotateTextButton"
              onClick={this.handleAnnotateTextClick}
            >
              <i className="fas fa-text" />
              <span className="text">
                <Trans>Text</Trans>
              </span>
            </button>
            {/* <button 
              type="button"
              className="btn btn-icon-only annotateSubtitleButton"
              onClick={this.handleAnnotateSubtitleClick}
            >
              <i className="fas fa-closed-captioning" />
              <span className="text"><Trans>Caption</Trans></span>
            </button> */}
            <button
              type="button"
              className="btn btn-icon-only annotateDrawingButton"
              onClick={this.handleAnnotateDrawingClick}
            >
              <i className="fas fa-paint-brush-alt" />
              <span className="text">
                <Trans>Drawing</Trans>
              </span>
            </button>
            <div
              type="button"
              className="btn btn-icon-only annotateImageButton"
            >
              <input
                ref={(ref) => (this.scrubberImageFileInputRef = ref)}
                type="file"
                accept="image/*"
                onClick={this.handleVideoPlayerPause}
                onChange={this.handleAnnotateImageInputChange}
              />
              <i className="fas fa-image" />
              <span className="text">
                <Trans>Photo</Trans>
              </span>
            </div>
            <div
              type="button"
              className="btn btn-icon-only annotateVideoButton"
            >
              <input
                ref={(ref) => (this.scrubberVideoFileInputRef = ref)}
                type="file"
                accept="video/mp4,video/x-m4v,video/*"
                onClick={this.handleVideoPlayerPause}
                onChange={this.handleAnnotateVideoInputChange}
              />
              <i className="fas fa-video-plus" />
              <span className="text">
                <Trans>Video</Trans>
              </span>
            </div>
            {/* <button
              type="button"
              className="btn btn-icon-only annotateAudioButton"
              onClick={this.handleAnnotateAudioClick}
            >
              <i className="fas fa-microphone-alt" />
            </button> */}
            {/* <button
              type="button"
              className="btn btn-icon-only annotateDeleteButton"
              onClick={!isNil(selectedAnnotation) ? () => this.handleAnnotationDelete(selectedAnnotation) : null}
            >
              <i className="fas fa-trash-alt color-danger" />
            </button> */}
          </div>
        </div>
        {isAnnotationTextInputVisible && (
          <div
            className={cx('textareaContainer', {
              positional: true,
              isCommitted: isAnnotationTextCommitted,
            })}
          >
            <div className="legend">
              <i className="fas fa-text" />
              <span className="text">
                <Trans>Text</Trans>
              </span>
            </div>
            {!isAnnotationTextCommitted && (
              <React.Fragment>
                <TextareaAutosize
                  ref={this.textareaRef}
                  value={annotationTextInputValue}
                  placeholder={t('Write your feedback...')}
                  maxRows={4}
                  maxLength={512}
                  onChange={this.handleAnnotationTextInputChange}
                  onClick={this.handleAnnotationTextInputFocus}
                  onFocus={this.handleAnnotationTextInputFocus}
                  onBlur={this.handleAnnotationTextInputBlur}
                />
                <div className="textareaActions">
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={this.handleAnnotationTextInputCancel}
                  >
                    <Trans>Cancel</Trans>
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={!(annotationTextInputValue || '').trim()}
                    onClick={this.handleAnnotationTextInputCommitted}
                  >
                    <Trans>Done</Trans>
                  </button>
                </div>
              </React.Fragment>
            )}
            {isAnnotationTextCommitted && (
              <React.Fragment>
                <div className="positionalSwitch">
                  <label htmlFor="isPositional">
                    <Trans>Point at an area</Trans>
                  </label>
                  <Switch
                    name="isPositional"
                    onChange={(value) =>
                      this.setState({ isSubtitleMode: !value })
                    }
                    checked={!isSubtitleMode}
                  />
                </div>
                <div className="textareaActions">
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={this.handleAnnotationTextInputCancel}
                  >
                    <Trans>Cancel</Trans>
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={!(annotationTextInputValue || '').trim()}
                    onClick={this.handleAnnotationTextInputSave}
                  >
                    <Trans>Done</Trans>
                  </button>
                </div>
              </React.Fragment>
            )}
          </div>
        )}
        {isDrawing && (
          <DrawingScreen
            naturalWidth={naturalVideoWidth}
            naturalHeight={naturalVideoHeight}
            onClose={this.handleDrawingScreenClose}
            onSave={this.handleDrawingScreenSave}
          />
        )}
        {isAnnotationTextInputVisible && isSubtitleMode && (
          <div className="annotationSubtitleTextViewer">
            <p>{annotationTextInputValue}</p>
          </div>
        )}
        {isAnnotationTextInputVisible && !isSubtitleMode && (
          <div className="annotationPositionalTextViewer">
            <PositionalTextScreen
              text={annotationTextInputValue}
              initialX={annotationTextInputX}
              initialY={annotationTextInputY}
              naturalWidth={naturalVideoWidth}
              naturalHeight={naturalVideoHeight}
              onChange={this.handlePositionalTextChange}
            />
          </div>
        )}
        {!isDrawing &&
          !isAnnotationTextInputVisible &&
          this.renderAnnotationInfos()}
      </div>
    );
  }
}

export default withTranslation()(withGlobalState(SimpleVideoEditor));
