import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

import './style.scss';

class InstallPrompt extends React.Component {
  render() {
    const { canInstall, onClose, onInstall } = this.props;

    return (
      <div className="InstallPrompt">
      <button type="button" className="btn btn-icon-only closeButton" onClick={onClose}>
        <i className="fal fa-times" />
        <span className="sr-only"><Trans>Close</Trans></span>
      </button>
      <div className="info">
        <h2>Vidbax</h2>
        <p><Trans>Better experience using our free app.</Trans></p>
      </div>
      <button type="button" className="btn btn-primary btn-rounded installButton" onClick={onInstall}>
        {canInstall ? <Trans>Install</Trans> : <Trans>Show me</Trans>}
      </button>
    </div>
    );
  }
}

export default withTranslation()(InstallPrompt);