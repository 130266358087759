import React, { createContext } from 'react';
import firebase from 'firebase';

import { userModel } from './user';
import { videoModel } from './video';
import { chatModel } from './chat';

const firebaseProjectId = 'lusva-4ac5b';
// const authDomain = `${firebaseProjectId}.firebaseapp.com`;
const authDomain = 'app.vidbax.com';
const firebaseConfig = {
  apiKey: 'AIzaSyC8Q7w7__l4OiExpgcKbYAW-FDYgLV95cA',
  authDomain,
  databaseURL: `https://${firebaseProjectId}.firebaseio.com`,
  projectId: firebaseProjectId,
  storageBucket: `${firebaseProjectId}.appspot.com`,
  messagingSenderId: '390890506917',
  appId: '1:390890506917:web:4484b52998c72409a75b4c',
  measurementId: 'G-D2D2RPQ4YB'
};

const messagingPublicKey = 'BJZJH3c3PHAcSej_Bwz9JqIxPEMOr4Eh0R1swg9FS4PKD_PTa6jUV4ry5qQDHpEjhU6TaCVu8NRLkvjgSbtsb6I';

const ApiContext = createContext({
  user: userModel('users'),
  video: videoModel('videos'),
  chat: chatModel(),
});

let messaging;

export function initApi() {
  const app = firebase.apps.length > 0 ? firebase.apps[0] : firebase.initializeApp(firebaseConfig);
  // firebase.functions().useFunctionsEmulator('http://localhost:5001');

  if (firebase.messaging.isSupported()) {
    messaging = app.messaging();
    messaging.usePublicVapidKey(messagingPublicKey);
  }
  
  return app;
}

export function initMessaging() {
  return messaging;
}

export function getCurrentUserId() {
  return (firebase.auth().currentUser || {}).uid;
}

export function getCurrentUserMobile() {
  return (firebase.auth().currentUser || {}).phoneNumber;
}

export class ApiProvider extends React.Component {
  static instance;

  state = {
    user: userModel('users'),
    video: videoModel('videos'),
    chat: chatModel(),
  };

  componentDidMount() {
    if (!ApiProvider.instance) {
      ApiProvider.instance = true;
    }
  }

  render() {
    return (
      <ApiContext.Provider value={this.state}>
        {this.props.children}
      </ApiContext.Provider>
    );
  }
}

export const ApiConsumer = ApiContext.Consumer;
export const withApi = (Component) => {
  class ComponentWithApi extends React.Component {
    render() {
      return (
        <ApiContext.Consumer>
          {(api) => <Component api={api} {...this.props} />}
        </ApiContext.Consumer>
      );
    }
  }

  return ComponentWithApi;
};
