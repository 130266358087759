import React from 'react';
import cx from 'classnames';
import { isNil, isEmpty } from 'lodash';
import { withTranslation, Trans } from 'react-i18next';

import './style.scss';
import { sampleVideos } from '../../services/Api/sampleData';

import { withApi, getCurrentUserId } from '../../services/Api';
import { withGlobalState } from '../../services/GlobalState';
import { formatDecimal, uniqueConcat } from '../../utils';
import { sortVideosByAttention } from '../../utils/video';
import LoadingIndicator from '../LoadingIndicator';
import VideoList from '../VideoList';

class CombinedInboxScreen extends React.Component {
  state = {
    receivedFriendVideos: [],
    sentVideos: [],
  };

  componentDidMount() {
    const { api, globalState } = this.props;
    const currentUserId = getCurrentUserId();
    const currentUser = globalState.currentUser || {};

    this.setState({
      isLoadingReceivedFriendVideos: true,
      isLoadingReceivedCompanyVideos: !isNil(currentUser.companyId),
      isLoadingSentVideos: true,
    });

    this.unsubscribeReceivedFriendVideoList = api.video.listByRecipientId(
      currentUserId,
      (videos) => {
        if (!isEmpty(videos)) {
          const userIds = {};
          videos.forEach(video => {
            if (!isNil(video.senderId)) {
              userIds[video.senderId] = true;
            }
            if (!isNil(video.recipientId)) {
              userIds[video.recipientId] = true;
            }
          });
          
          api.user.getByIds(Object.keys(userIds), users => {
            users.forEach(user => {
              globalState.setUser(user);
            });

            videos.forEach(video => {
              if (!isNil(video.senderId)) {
                video.sender = globalState.getUserById(video.senderId);
              }
              if (!isNil(video.recipientId)) {
                video.recipient = globalState.getUserById(video.recipientId);
              }
            });

            this.setState({
              isLoadingReceivedFriendVideos: false,
              receivedFriendVideos: videos,
            });
          });
        } else {
          this.setState({
            isLoadingReceivedFriendVideos: false,
            receivedFriendVideos: [],
          });
        }
      },
      { subscribe: true }
    );

    if (!isNil(currentUser.companyId)) {
      this.unsubscribeReceivedCompanyVideoList = api.video.listByRecipientId(
        currentUser.companyId,
        (videos) => {
          if (!isEmpty(videos)) {
            const userIds = {};
            videos.forEach(video => {
              if (!isNil(video.senderId)) {
                userIds[video.senderId] = true;
              }
              if (!isNil(video.recipientId)) {
                userIds[video.recipientId] = true;
              }
            });
            
            api.user.getByIds(Object.keys(userIds), users => {
              users.forEach(user => {
                globalState.setUser(user);
              });

              videos.forEach(video => {
                if (!isNil(video.senderId)) {
                  video.sender = globalState.getUserById(video.senderId);
                }
                if (!isNil(video.recipientId)) {
                  video.recipient = globalState.getUserById(video.recipientId);
                }
              });

              this.setState({
                isLoadingReceivedCompanyVideos: false,
                receivedCompanyVideos: videos,
              });
            });
          } else {
            this.setState({
              isLoadingReceivedCompanyVideos: false,
              receivedCompanyVideos: [],
            });
          }
        },
        { subscribe: true }
      );
    }

    this.unsubscribeSentVideoList = api.video.listBySenderId(
      currentUserId,
      (videos) => {
        if (!isEmpty(videos)) {
          const userIds = {};
          videos.forEach(video => {
            if (!isNil(video.senderId)) {
              userIds[video.senderId] = true;
            }
            if (!isNil(video.recipientId)) {
              userIds[video.recipientId] = true;
            }
          });
          
          api.user.getByIds(Object.keys(userIds), users => {
            users.forEach(user => {
              globalState.setUser(user);
            });

            videos.forEach(video => {
              if (!isNil(video.senderId)) {
                video.sender = globalState.getUserById(video.senderId);
              }
              if (!isNil(video.recipientId)) {
                video.recipient = globalState.getUserById(video.recipientId);
              }
            });

            this.setState({
              isLoadingSentVideos: false,
              sentVideos: videos,
            });
          });
        } else {
          this.setState({
            isLoadingSentVideos: false,
            sentVideos: [],
          });
        }
      },
      { subscribe: true }
    );
  }

  componentWillUnmount() {
    if (this.unsubscribeReceivedFriendVideoList) {
      this.unsubscribeReceivedFriendVideoList();
    }
    if (this.unsubscribeReceivedCompanyVideoList) {
      this.unsubscribeReceivedCompanyVideoList();
    }
    if (this.unsubscribeSentVideoList) {
      this.unsubscribeSentVideoList();
    }
  }

  handleItemDeleteClick = (videoToDelete) => {
    const { t } = this.props;
    const { showAlert } = this.props.globalState;
    showAlert({
      videoToDelete,
      type: 'warning',
      title: t('Delete Video'),
      message: t('Are you sure you want to delete this video?'),
      cancel: t('No'),
      ok: t('Yes'),
      onOk: this.handleItemDelete,
    });
  };

  handleItemDelete = ({ videoToDelete }) => {
    const { api, onDelete: onItemDelete } = this.props;
    const currentUser = this.props.currentUser || {};
    const { id, senderId } = videoToDelete;
    const isCurrentUserSender = currentUser.id === senderId;

    this.setState({ isDeleting: true });
    api.video.remove(id).then(() => {
      this.setState({ isDeleting: false }, () => {
        if (onItemDelete) {
          onItemDelete({ id, isCurrentUserSender });
        }
      });
    });
  };

  render() {
    const {
      globalState,
      currentUser,
      onItemClick,
      onAddClick,
      onMenuClick,
    } = this.props;
    const {
      receivedFriendVideos,
      receivedCompanyVideos,
      sentVideos,
      isLoadingReceivedFriendVideos,
      isLoadingReceivedCompanyVideos,
      isLoadingSentVideos,
    } = this.state;

    const { settings } = globalState;
    const isLoading = isLoadingReceivedFriendVideos || isLoadingReceivedCompanyVideos || isLoadingSentVideos;
    const data = uniqueConcat(receivedFriendVideos, receivedCompanyVideos).concat(sentVideos).sort(sortVideosByAttention);
    const unseenVideos = uniqueConcat((receivedCompanyVideos || []).filter(v => isNil(v.seenAt)), (receivedFriendVideos || []).filter(v => isNil(v.seenAt)));
    const unreviewedVideos = sentVideos.filter(v => !isNil(v.repliedAt) && isNil(v.reviewedAt));
    const videosNeedAttentionLen = unseenVideos.length + unreviewedVideos.length;

    return (
      <div className={cx('Screen CombinedInboxScreen')}>
        <header>
          <nav>
            <ul>
              <li>
                <button type="button" className="btn btn-icon-only" onClick={onMenuClick}>
                  <i className="fal fa-bars color-primary icon-big" />
                  <div className="sr-only"><Trans>Menu</Trans></div>
                </button>
              </li>
              <li><Trans>Inbox</Trans></li>
              <li>
                <button type="button" className="btn btn-icon-only" onClick={onAddClick}>
                  <i className="fal fa-plus color-primary icon-big" />
                  <div className="sr-only"><Trans>Add</Trans></div>
                </button>
              </li>
            </ul>
          </nav>
        </header>
        <section>
          {isLoading && <LoadingIndicator light />}
          {!isLoading && !isEmpty(data) && (
            <div className="videoListContainer">
              <h2><Trans>Videos</Trans></h2>
              <span className="badge">{formatDecimal(videosNeedAttentionLen)}</span>
              <VideoList
                currentUser={currentUser}
                showSenderOrRecipient={null}
                data={data}
                onItemClick={onItemClick}
                onItemDelete={this.handleItemDeleteClick}
              />
            </div>
          )}
          {!isLoading && isEmpty(data) && (
            <div className="emptyList">
              <h2><Trans>You haven't got any videos yet.</Trans></h2>
              <p><Trans>You'll be able to view and reply videos that others sent to you here.</Trans></p>
            </div>
          )}
          {!isLoading && (settings.sampleVideos || isEmpty(data)) && (
            <div className="videoListContainer">
              <h2><Trans>Examples</Trans></h2>
              <VideoList
                currentUser={currentUser}
                showSenderOrRecipient="sender"
                data={sampleVideos}
                onItemClick={onItemClick}
                onItemDelete={this.handleItemDeleteClick}
              />
            </div>
          )}
        </section>
      </div>
    );
  }
}

export default withTranslation()(withGlobalState(withApi(CombinedInboxScreen)));