import React from 'react';
import cx from 'classnames';
import { isNil, isEmpty, cloneDeep } from 'lodash';
import { withTranslation, Trans } from 'react-i18next';

import './style.scss';

import { withApi } from '../../services/Api';
import { withGlobalState } from '../../services/GlobalState';
import { formatDecimal } from '../../utils';
import LoadingIndicator from '../LoadingIndicator';
import StudentList from '../StudentList';
import StudentDetailsScreen from '../StudentDetailsScreen';

class StudentsScreen extends React.Component {
  state = {
    isLoading: false,
    isDeleting: false,
    students: [],
    usersById: {},
    studentDetails: null,
  };

  isInit = false;

  componentDidMount() {
    this.checkFetchStudents({});
  }
  
  componentDidUpdate(prevProps) {
    this.checkFetchStudents(prevProps);
  }

  getStudentIds(students) {
    return students.filter(s => !isNil(s.id)).map(s => s.id);
  }

  checkFetchStudents(prevProps) {
    const company = this.props.company || {};
    const studentsIds = this.getStudentIds(company.students || []);
    const prevStudentsIds = this.getStudentIds((prevProps.company || {}).students || []);

    let diffIds = [];
    if (studentsIds.sort().join(',') !== prevStudentsIds.sort().join(',')) {
      diffIds = studentsIds;
    }

    if (!isEmpty(diffIds)) {
      if (!this.isInit) {
        this.setState({ isLoading: true });
      }
      this.fetchStudents(diffIds);
    } else {
      if (!isNil(this.props.company) && this.state.isLoading) {
        if (!this.isInit && studentsIds.length === 0) {
          this.setState({ isLoading: false });
        }
        this.isInit = true;
      }
    }
  }

  fetchStudents(ids) {
    const { api } = this.props;
    const company = this.props.company || {};
    const students = company.students || [];
    const allStudents = ids || this.getStudentIds(students);

    if (!isEmpty(allStudents)) {
      api.user.getByIds(allStudents, (result) => {
        const { usersById } = this.state;
        result.forEach(s => {
          usersById[s.id] = s;
        });
        this.isInit = true;
        this.setState({
          usersById,
          isLoading: false,
        });
      }, { refreshCache: true });
    }
  }

  handleAddClick = () => {
    const studentDetails = cloneDeep(StudentDetailsScreen.defaultProps.data);
    this.setState({ studentDetails });
    
    const { onStudentEdit } = this.props;
    if (onStudentEdit) {
      onStudentEdit(studentDetails);
    }
  };

  handleStudentEditClick = (details) => {
    const studentDetails = cloneDeep(details);
    this.setState({ studentDetails });
    
    const { onStudentEdit } = this.props;
    if (onStudentEdit) {
      onStudentEdit(studentDetails);
    }
  };

  handleStudentDeleteClick = (studentToDelete) => {
    const { t } = this.props;
    const { showAlert } = this.props.globalState;
    showAlert({
      studentToDelete,
      type: 'warning',
      title: t('Delete Student'),
      message: t('Are you sure you want to delete this student?'),
      cancel: t('No'),
      ok: t('Yes'),
      onOk: this.handleItemDelete,
    });
  };

  handleItemDelete = ({ studentToDelete }) => {
    const rid = (studentToDelete || {}).rid;
    const { api, company } = this.props;
    if (!isNil(company)) {
      if (!isNil(rid)) {
        const students = (company.students || []).filter(s => s.rid !== rid);
        company.students = students;
        api.user.update({
          id: company.id,
          students,
        }).then(() => {
          this.setState({ studentDetails: null });
        });
      }
    }
  };

  render() {
    const { isLoading, isDeleting, studentDetails, usersById } = this.state;
    const { showAsMainScreen, visible, onMenuClick, onClose } = this.props;
    const company = this.props.company || {};
    let students = cloneDeep(company.students || []);
    students.filter(s => !isNil(s.id)).forEach(s => {
      students[s.id] = usersById[s.id];
    });

    return (
      <div className={cx('Screen StudentsScreen', {
          showAsMainScreen,
          visible: showAsMainScreen || visible,
          behind: !showAsMainScreen && !isNil(studentDetails),
        })}>
        {showAsMainScreen && (
          <header>
            <nav>
              <ul>
                <li>
                  <button type="button" className="btn btn-icon-only" onClick={onMenuClick}>
                    <i className="fal fa-bars color-primary icon-big" />
                    <div className="sr-only"><Trans>Menu</Trans></div>
                  </button>
                </li>
                <li><Trans>Contacts</Trans></li>
                <li>
                  <button type="button" className="btn btn-icon-only" onClick={this.handleAddClick}>
                    <i className="fal fa-plus color-primary icon-big" />
                    <div className="sr-only"><Trans>Add</Trans></div>
                  </button>
                </li>
              </ul>
            </nav>
          </header>
        )}
        {!showAsMainScreen && (
          <header>
            <nav>
              <ul>
                <li>
                  <button type="button" className="btn btn-icon-only" onClick={onClose}>
                    <i className="fal fa-times color-default icon-big" />
                    <div className="sr-only"><Trans>Close</Trans></div>
                  </button>
                </li>
                <li><Trans>Students</Trans></li>
                <li>
                  <button type="button" className="btn btn-icon-only" onClick={this.handleAddClick}>
                    <i className="fal fa-plus color-primary icon-big" />
                    <div className="sr-only"><Trans>Add</Trans></div>
                  </button>
                </li>
              </ul>
            </nav>
          </header>
        )}
        <section>
          {(isLoading || isDeleting) && <LoadingIndicator light />}
          {!isLoading && !isDeleting && !isEmpty(students) && (
            <div className="studentListContainer">
              <h2><Trans>Students</Trans></h2>
              <span className="badge">{formatDecimal(students.length)}</span>
              <StudentList
                data={students}
                usersById={usersById}
                onItemEdit={this.handleStudentEditClick}
                onItemDelete={this.handleStudentDeleteClick}
              />
            </div>
          )}
          {!isLoading && !isDeleting && isEmpty(students) && (
            <div className="studentListContainer emptyList">
              <h2><Trans>You haven't got anyone on your students list yet.</Trans></h2>
              <p><Trans>Add a student by tapping<br />the <i className="fal fa-plus text-larger color-primary" /> button at the top-right corner.</Trans></p>
            </div>
          )}
        </section>
      </div>
    );
  }
}

export default withTranslation()(withGlobalState(withApi(StudentsScreen)));