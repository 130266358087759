import firebase from 'firebase';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { serialize, deserialize } from '../../utils/db';

import { sampleVideosById } from './sampleData';

const MODEL = 'videos';

export function search(q, callback, params = { model: MODEL }) {
  const db = firebase.firestore();
  const { subscribe } = params;
  const model = params.model || MODEL;

  const query = q || db.collection(model);

  if (subscribe) {
    return query.onSnapshot(
      snapshot => {
        const results = snapshot.docs.map(doc => {
          return deserialize(doc.exists ? doc.data() : null);
        });
        callback(results.filter(i => i.id));
      },
      error => {
        console.warn('[ERROR]', error);
        callback([]);
      },
    );
  } else {
    return query
      .get()
      .then(snapshot => {
        const results = snapshot.docs.map(doc => {
          return deserialize(doc.exists ? doc.data() : null);
        });
        callback(results.filter(i => i.id));
      })
      .catch(error => {
        console.warn('[ERROR]', error);
        callback([]);
      });
  }
}

export function list(callback, params = { model: MODEL }) {
  return search(null, callback, params);
}

export function listBySenderId(id, callback, params = { model: MODEL }) {
  const db = firebase.firestore();
  const model = params.model || MODEL;
  return search(db.collection(model).where('senderId', '==', id), callback, params);
}

export function listByRecipientId(id, callback, params = { model: MODEL }) {
  const db = firebase.firestore();
  const model = params.model || MODEL;
  return search(db.collection(model).where('recipientId', '==', id), callback, params);
}

export function get(id, callback, params = { model: MODEL }) {
  const { subscribe } = params;

  if (id.startsWith('sample-')) {
    if (subscribe) {
      setTimeout(() => {
        callback(sampleVideosById[id]);
      }, 100);
      return () => {};
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        callback(sampleVideosById[id]);
        resolve();
      }, 100);
    })
  }

  const db = firebase.firestore();
  const model = params.model || MODEL;

  if (subscribe) {
    return db
      .collection(model)
      .doc(id)
      .onSnapshot(
        doc => {
          const result = deserialize(doc.exists ? doc.data() : null);
          callback(result);
        },
        error => {
          console.warn('[ERROR]', error);
          callback(null);
        },
      );
  } else {
    return db
      .collection(model)
      .doc(id)
      .get()
      .then(doc => {
        const result = deserialize(doc.exists ? doc.data() : null);
        callback(result);
      })
      .catch(error => {
        console.warn('[ERROR]', error);
        callback(null);
      });
  }
}

export function create(entity, id = uuid(), params = { model: MODEL }) {
  const db = firebase.firestore();
  const model = params.model || MODEL;
  const data = {
    ...entity,
    id,
    createdAt: moment(),
  };

  const ref = db
    .collection(model)
    .doc(id);

  return ref.set(serialize(data));
}

export function update(
  entity,
  params = { model: MODEL }
) {
  const db = firebase.firestore();
  const { model } = params;

  const data = {
    ...entity,
    updatedAt: moment(),
  };

  delete data.recipient;
  delete data.sender;

  const ref = db
    .collection(model)
    .doc(entity.id);

  return ref.update(serialize(data));
}

export function deleteFolderContents(path) {
  return new Promise((resolve, reject) => {
    const ref = firebase.storage().ref(path);
    const deleteTasks = [];
    ref
      .listAll()
      .then(dir => {
        dir.items.forEach(fileRef => {
          deleteTasks.push(deleteFile(ref.fullPath, fileRef.name));
        });
        dir.prefixes.forEach(folderRef => {
          deleteTasks.push(deleteFolderContents(folderRef.fullPath));
        })
        Promise.all(deleteTasks).finally(resolve);
      })
      .catch(reject);
  });
}

export function deleteFile(pathToFile, fileName) {
  const ref = firebase.storage().ref(pathToFile);
  const childRef = ref.child(fileName);
  return childRef.delete();
}

export function remove(id, params = { model: MODEL }) {
  const db = firebase.firestore();
  const model = params.model || MODEL;

  return new Promise((resolve, reject) => {
    deleteFolderContents(`videos/${id}`)
      .finally(() => {
        db
          .collection(model)
          .doc(id)
          .delete()
          .then(resolve)
          .catch(reject);
      });
  });
}

export function upload(file, filename, progressCallback, path = 'videos/', customMetadata = null) {
  const { type } = file;
  const storage = firebase.storage();
  const storageRef = storage.ref();
  const finalMetadata = {
    contentType: type,
    customMetadata: {
      ...(customMetadata || {}),
      ownerId: (firebase.auth().currentUser || {}).uid,
    },
  };

  var uploadTask = storageRef.child(`${path}${filename}`).put(file, finalMetadata);

  return new Promise((resolve, reject) => {
    uploadTask.on('state_changed', (snapshot) => {
      const progress = snapshot.bytesTransferred / snapshot.totalBytes;
      progressCallback && progressCallback({
        progress,
        status: snapshot.state,
      });
    }, reject, () => {
      uploadTask.snapshot.ref.getDownloadURL().then(resolve);
    });
  });
}

export function videoModel(model) {
  return {
    create: (entity, id, params) => create(entity, id, { ...params, model }),
    update: (entity, params) => update(entity, { ...params, model }),
    get: (id, callback, params) => get(id, callback, (params = { ...params, model })),
    search: (q, callback, params) => search(q, callback, { ...params, model }),
    list: (callback, params) => list(callback, { ...params, model }),
    listBySenderId: (id, callback, params) => listBySenderId(id, callback, { ...params, model }),
    listByRecipientId: (id, callback, params) => listByRecipientId(id, callback, { ...params, model }),
    upload: (file, filename, progressCallback, path, metadata) => upload(file, filename, progressCallback, path, metadata),
    remove: (id, params) => remove(id, { ...params, model }),
  };
}

export default {
  create,
  update,
  get,
  search,
  list,
  listBySenderId,
  listByRecipientId,
  upload,
  remove,
};
