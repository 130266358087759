import React from 'react';
import cx from 'classnames';
import ProgressBar from '../ProgressBar';

import './style.scss';

export default class UploadProgress extends React.Component {
  static defaultProps = {
    title: 'Uploading Video',
    message: <p>Please <strong className="color-primary">DO NOT CLOSE</strong> the app until your video finishes uploading.</p>,
  };
  
  render() {
    const { className, children, percent, title, message } = this.props;

    return (
      <div className={cx('UploadProgress', className, { visible: percent > 0 && percent < 1 })}>
        <h2>{title}</h2>
        {children || message}
        <ProgressBar percentage={percent} />
      </div>
    );
  }
}